import React from "react";
import cx from "clsx";
import { withRouter } from "react-router";
import { Modal } from "../../../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../assets/svg";
import { img } from "../../../../../assets/img";
import classes from "./ViewPaymentOptions.module.scss";
import {
  PAYMENT_OPTIONS,
  PAYMENT_VIEW_TYPES,
} from "../../../../../store/checkoutInvoice/consts";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { USER_PAYMENT_SYSTEMS } from "../../../../../consts/api";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useRmdReserveValidate } from "../../hooks/useRmdReserveValidate";
import { uiNotification } from "../../../../../services/UINotificationService";
import { useAvailablePaymentMethods } from "../../hooks/useAvailablePaymentMethods";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { AvailableBalance } from "../../shared/AvailableBalance/AvailableBalance";
import { formatCurrency } from "../../../../../utilities/general";
import { useJoyaReserveValidate } from "../../hooks/useJoyaReserveValidate";

function ViewPaymentOptions() {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const {
    invoice,
    isStartStage,
    hasRmdRedemptions,
    hasJoyaRedemptions,
    currency,
  } = useInvoice();
  const { data: user, isLoading: isUserLoading } = useCurrentUserQuery();

  const { isFetching: isRmdValidating } = useRmdReserveValidate(invoice.id, {
    enabled: isStartStage && hasRmdRedemptions,
    onError: (error) => {
      onClose();
      uiNotification.error(error.response.data.message);
    },
  });

  const { isFetching: isJoyaValidating } = useJoyaReserveValidate(invoice.id, {
    enabled: isStartStage && hasJoyaRedemptions,
    onError: (error) => {
      onClose();
      uiNotification.error(error.response.data.message);
    },
  });

  const { paymentViewClose } = useInvoiceActions();
  const { data: methods, isFetching: isFetchingMethodsAvailable } =
    useAvailablePaymentMethods(invoice.id);

  const changeViewType = (type) => {
    dispatch(
      checkoutInvoice.actions.paymentViewChange({
        paymentView: type,
      }),
    );
  };

  const cardOnFile = invoice?.patient?.cardOnFiles?.[0]?.number || null;

  const options = [
    {
      key: PAYMENT_OPTIONS.card,
      img: svg.ccPrimary,
      isAvailable: methods?.card.available,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.card),
      rightAdornment:
        user?.account?.paymentSystem !== USER_PAYMENT_SYSTEMS.stripe
          ? cardOnFile
          : null,
    },
    {
      key: PAYMENT_OPTIONS.cash,
      img: svg.cashPrimary,
      isAvailable: methods?.cash.available,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.cash),
      rightAdornment: null,
    },
    {
      key: PAYMENT_OPTIONS.arWallet,
      img: svg.arWalletPrimary,
      isAvailable: methods?.wallet.available,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.arWallet),
      rightAdornment: null,
      balance: methods?.wallet?.balance,
    },
    {
      key: PAYMENT_OPTIONS.cherry,
      img: svg.cherryPay,
      isAvailable: methods?.cherry.available,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.cherry),
      rightAdornment: null,
      balance: methods?.cherry?.balance,
    },
    {
      key: PAYMENT_OPTIONS.klarna,
      img: img.klarna,
      isAvailable: methods?.klarna.available,
      isHidden: user?.account?.paymentSystem !== USER_PAYMENT_SYSTEMS.stripe,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.klarna),
      rightAdornment: null,
    },
    {
      key: PAYMENT_OPTIONS.careCredit,
      img: img.careCredit,
      isAvailable: methods?.careCredit.available,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.careCredit),
      rightAdornment: null,
    },
    {
      key: PAYMENT_OPTIONS.greenSky,
      img: img.greenSky,
      isAvailable: methods?.greenSky.available,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.greenSky),
      rightAdornment: null,
    },
    {
      key: PAYMENT_OPTIONS.check,
      img: svg.checkPayPrimary,
      isAvailable: methods?.check.available,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.check),
      rightAdornment: null,
    },
    {
      key: PAYMENT_OPTIONS.giftCard,
      img: svg.giftCardPrimary,
      isAvailable: methods?.giftCard.available,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.giftCard),
      rightAdornment: null,
    },
    {
      key: PAYMENT_OPTIONS.text2pay,
      img: svg.text2Pay,
      isAvailable: methods?.text2pay.available,
      isHidden: user?.account?.paymentSystem !== USER_PAYMENT_SYSTEMS.stripe,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.text2pay),
      rightAdornment: null,
    },
  ].filter((i) => !i.isHidden);

  const onClose = () => {
    paymentViewClose();
  };

  const isLoading =
    isUserLoading ||
    isFetchingMethodsAvailable ||
    isRmdValidating ||
    isJoyaValidating;

  return (
    <Modal
      headerNoBorder
      isOpen
      onClose={onClose}
      header={<Modal.Title>{tCommon("label.paymentOptions")}</Modal.Title>}
      contentClassName={classes.root}
      className={classes.modal}
    >
      {isLoading ? (
        <Skeleton count={options.length} height={64} borderRadius="2px" />
      ) : (
        options.map((option) => (
          <button
            key={option.key}
            disabled={!option.isAvailable}
            onClick={option.onClick}
            className={cx(classes.option, {
              [classes.optionDisabled]: !option.isAvailable,
            })}
          >
            <div className={classes.imgBox}>
              <img src={option.img} alt="" />
            </div>
            <div className={classes.optionInfo}>
              <div className={classes.optionLabel}>
                <span>
                  {tSales(`checkoutInvoice.paymentOptions.${option.key}`)}
                </span>
                {Boolean(option.balance) && (
                  <AvailableBalance
                    amount={formatCurrency(option.balance, currency)}
                    className="font-12"
                  />
                )}
              </div>
              <div className={classes.optionRight}>
                {option.isAvailable && option.rightAdornment}
                {option.isAvailable ? (
                  <img src={svg.chevronRightGray} alt="" />
                ) : (
                  tCommon("label.unavailable")
                )}
              </div>
            </div>
          </button>
        ))
      )}
    </Modal>
  );
}

export default withRouter(ViewPaymentOptions);
