import React, { Fragment } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./Table.module.scss";
import { Sortable } from "./widgets/Sortable/Sortable";

const BG_COLOR = {
  white: "white",
};

export function Table({
  className,
  headClassName,
  colClassName,
  headColClassName,
  bodyClassName,
  rowClassName,
  noBorderBottom,
  cols,
  data,
  bgColor,
  keyExtractor,
  onRowClick,
  preventRowClickForAccessors,
  getRowClassName,
  expandedRows,
}) {
  const handleRowClick = (row) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const handleColClick = (e, col) => {
    if (preventRowClickForAccessors.includes(col.accessor) && onRowClick) {
      e.stopPropagation();
    }
  };

  return (
    <table
      className={cx(
        classes.root,
        {
          [classes.bgWhite]: bgColor === BG_COLOR.white,
        },
        className,
      )}
    >
      <thead className={cx(classes.head, headClassName)}>
        <tr>
          {cols.map((col) => (
            <th
              key={col.accessor}
              className={cx(
                classes.headColumn,
                colClassName,
                headColClassName,
                col.className,
                col.headColClassName,
              )}
            >
              {col.data}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={bodyClassName}>
        {data.map((row, index) => {
          const key = keyExtractor(row);
          return (
            <Fragment key={index}>
              <tr
                onClick={() => handleRowClick(row)}
                className={cx(
                  classes.row,
                  {
                    [classes.noBorderBottom]:
                      index === data.length - 1 && noBorderBottom,
                    [classes.rowClickable]: Boolean(onRowClick),
                  },
                  rowClassName,
                  getRowClassName?.(row),
                  row.className,
                )}
              >
                {cols.map((col, index) => (
                  <td
                    key={`${key}-${col.accessor}`}
                    className={cx(classes.column, colClassName, col.className)}
                    onClick={(e) => handleColClick(e, col)}
                  >
                    <>
                      {index === 0 &&
                        row.children &&
                        expandedRows?.includes(row.index) && (
                          <button className={classes.expandButton}>
                            <i className={`fas fa-angle-up toggle-arrow`} />
                          </button>
                        )}
                      {index === 0 &&
                        row.children &&
                        !expandedRows?.includes(row.index) && (
                          <button className={classes.expandButton}>
                            <i className={`fas fa-angle-down toggle-arrow`} />
                          </button>
                        )}
                      {row[col.accessor]}
                    </>
                  </td>
                ))}
              </tr>
              {expandedRows?.includes(row.index) &&
                row.children?.map((child, index) => {
                  return (
                    <tr
                      key={index}
                      className={cx(
                        classes.row,
                        {
                          [classes.noBorderBottom]:
                            index === data.length - 1 && noBorderBottom,
                          [classes.rowClickable]: Boolean(onRowClick),
                        },
                        rowClassName,
                        getRowClassName?.(row),
                        row.className,
                      )}
                    >
                      {cols.map((col, idx) => (
                        <td
                          key={`${key}-${col.accessor}`}
                          className={cx(
                            classes.column,
                            colClassName,
                            col.className,
                          )}
                          onClick={(e) => handleColClick(e, col)}
                        >
                          {idx === 0 && (
                            <span className={classes.expandedColumnData}></span>
                          )}
                          {child[col.accessor]}
                        </td>
                      ))}
                    </tr>
                  );
                })}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

Table.propTypes = {
  className: PropTypes.string,
  headClassName: PropTypes.string,
  colClassName: PropTypes.string,
  headColClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  noBorderBottom: PropTypes.bool,
  cols: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.node.isRequired,
      accessor: PropTypes.string.isRequired,
      className: PropTypes.string,
      headColClassName: PropTypes.string,
    }),
  ),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      className: PropTypes.string,
      // [key: string]: React.Node | number | string,
    }),
  ),
  bgColor: PropTypes.oneOf(Object.values(BG_COLOR)),
  keyExtractor: PropTypes.func,
  onRowClick: PropTypes.func,
  preventRowClickForAccessors: PropTypes.arrayOf(PropTypes.string),
  getRowClassName: PropTypes.func,
};

Table.defaultProps = {
  className: undefined,
  headClassName: undefined,
  colClassName: undefined,
  headerColClassName: undefined,
  bodyClassName: undefined,
  rowClassName: undefined,
  noBorderBottom: false,
  cols: [],
  data: [],
  bgColor: undefined,
  keyExtractor: (row) => row.key,
  onRowClick: undefined,
  preventRowClickForAccessors: [],
  getRowClassName: null,
};

Table.Sortable = Sortable;
