import React, { useState } from "react";
import { usePatientWallet } from "./hooks/usePatientWallet";
import Header from "../../../../../../NewBusinessInsights/components/Header";
import { HTTP_ENDPOINTS } from "../../../../../../../consts/api";
import { Skeleton } from "../../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../../shared/Empty/Empty";
import styles from "../../../../../../NewBusinessInsights/newBusinessInsightsContent.module.scss";
import { Box } from "../../../../../../../shared/Box/Box";
import { Table } from "../../../../../../../shared/Table/Table";
import { apiDateFormat } from "../../../../../../../Utils";

const PatientWalletReport = ({
  selectedRowView,
  setSelectedRowView,
  period,
}) => {
  const params = {
    invoice_id: selectedRowView.invoiceId,
    patient_id: selectedRowView.patientId,
    package_id: selectedRowView.packageId,
    product_name: selectedRowView.productName,
    fromDate: apiDateFormat(period.value.from),
    toDate: apiDateFormat(period.value.to),
    ppp_id: selectedRowView.pppId,
  };

  const { data, isLoading } = usePatientWallet(params);
  const [expandedRows, setExpandedRows] = useState([]);

  const reportHeader = Object.keys(data?.report_header || {}).map((key) => ({
    data: data.report_header[key],
    accessor: key,
  }));

  const reportData =
    data?.report_data?.map((row, index) => ({
      ...row,
      index,
      children: row.redemptions,
    })) || [];

  const tableDataEmpty = !isLoading && !reportData.length;

  const handleExpandRow = (row) => {
    if (!expandedRows.includes(row.index)) {
      setExpandedRows((prev) => [...prev, row.index]);
    } else {
      setExpandedRows((prev) => prev.filter((index) => index !== row.index));
    }
  };

  return (
    <>
      <Header
        title="Short Term Liability"
        withBackBtn
        backFunc={() => setSelectedRowView(null)}
        downloadRoute={HTTP_ENDPOINTS.getOldPatientWallet()}
        requestParams={params}
      />
      <div className="flex gap-16">
        <div className={styles.tableContainer}>
          <Box>
            <h4 className="m-t-15 m-b-20">{selectedRowView.patient}</h4>
            <Table
              bgColor="white"
              cols={reportHeader}
              data={reportData}
              onRowClick={(row) => {
                if (row.children) {
                  handleExpandRow(row);
                }
              }}
              expandedRows={expandedRows}
            />
            {isLoading && (
              <Skeleton count={8} height={40.5} borderRadius="0px" />
            )}
            {tableDataEmpty && <Empty position="center" />}
          </Box>
        </div>
      </div>
    </>
  );
};

export default PatientWalletReport;
