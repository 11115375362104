import * as math from "mathjs";
import { CURRENCY } from "../consts/general";

/**
 * @param {unknown} value
 * @returns {boolean}
 */
export const isNullish = (value) => {
  return value === null || value === undefined;
};

/**
 * @param {number} ms
 * @returns {Promise<void>}
 */
export const delay = async (ms) =>
  new Promise((res) => setTimeout(() => res(), ms));

export class Exception extends Error {
  /**
   * @param {string} message
   * @param {(object | undefined)} extra
   */
  constructor(message, extra = {}) {
    super(message);
    this.name = "Exception";
    this.extra = extra || {};
  }
}

/**
 * @template {Base} T
 * @template {Base} F
 * @param {() => T} fn
 * @param {F} fallback
 * @returns {T | F}
 */
export const unwrapOr = (fn, fallback) => {
  try {
    const res = fn();
    if (res === null || res === undefined) {
      return fallback;
    }
    return res;
  } catch {
    return fallback;
  }
};

/**
 * @param {number} amount
 * @param {string} type
 * @param {{
 *    maxFractionDigits: number | undefined;
 *    minFractionDigits: number | undefined;
 * }} param2
 * @returns {string}
 */
export const formatCurrency = (
  amount,
  type,
  { maxFractionDigits, minFractionDigits } = {},
) => {
  const formatter = new Intl.NumberFormat("en", {
    style: "currency",
    currency: type || CURRENCY.usd,
    maximumFractionDigits: maxFractionDigits,
    minimumFractionDigits: minFractionDigits,
  });
  return formatter.format(amount || 0);
};

/**
 * @template {Base} T
 * @param {T} str
 * @returns {string | T}
 */
export const squashSpaces = (str) => {
  if (typeof str === "string") {
    return str.trim().replace(/\s+/g, " ");
  }
  return str;
};

/**
 * @template {Base} T
 * @param {Array<T>} list
 * @param {number} limit
 * @param {string} remainderPattern
 * @returns {list | string}
 */
export const limitList = (list, limit, remainderPattern = "& :count other") => {
  if (Array.isArray(list) && typeof limit === "number") {
    const join = (l) => l.join(", ");
    if (list.length > limit) {
      const visible = join(list.slice(0, limit));
      const other = list.slice(limit - 1, -1).length;
      return `${visible} ${remainderPattern.replace(":count", other)}`;
    }
    return join(list);
  }
  return list;
};

/**
 * @template {Record<string, unknown>} T
 * @param {T} shape
 * @returns {T}
 */
export const removeNullishFromShape = (shape) => {
  if (typeof shape === "object" && shape !== null) {
    return Object.entries(shape).reduce(
      (r, [k, v]) => (v === undefined || v === null ? r : { ...r, [k]: v }),
      {},
    );
  }
  return {};
};

/**
 * @param {string} fullName
 * @returns {{
 *    firstName: string;
 *    lastName: string;
 * }}
 */
export const splitFullName = (fullName) => {
  if (typeof fullName === "string") {
    const formatted = fullName.trim().replace(/\s+/g, " ");
    if (formatted) {
      const parts = formatted.split(" ");
      return {
        firstName: parts[0],
        lastName: parts.length > 1 ? parts[parts.length - 1] : "",
      };
    }
  }
  return {
    firstName: "",
    lastName: "",
  };
};

/**
 * @param {Array<{ fromTime: string; toTime: string }>} schedules
 * @returns {false | number[]}
 */
export const checkOverlappingTime = (schedules) => {
  if (schedules.length > 1) {
    const timeSchedules = schedules.map((x, originalIndex) => ({
      fromTime: parseInt(x.fromTime.replace(":", "")),
      toTime: parseInt(x.toTime.replace(":", "")),
      originalIndex,
    }));

    timeSchedules.sort((a, b) => a.fromTime - b.fromTime);

    let latestEndTime = -1;

    const allErrorSlots = [];

    let overlappingTimeError = false;

    for (let i = 0; i < timeSchedules.length; i++) {
      const slot = timeSchedules[i];

      if (slot.fromTime < latestEndTime) {
        overlappingTimeError = true; // overlapping time slots found
        allErrorSlots.push(slot.originalIndex);
      }

      latestEndTime = slot.toTime;
    }

    return overlappingTimeError && allErrorSlots;
  }

  return false; // no overlapping time slots found
};

/**
 * @template {Base} T
 * @param {T} url
 * @returns {string | T}
 */
export const getLastUrlEntry = (url) => {
  if (typeof url === "string") {
    return url.split("/").slice(-1)[0];
  }
  return url;
};

/**
 * @param {string} x
 * @returns {boolean}
 */
export const isHttpUrl = (x) =>
  new RegExp(/^https?:\/\/(www\.)?[a-zA-Z0-9-.]+/).test(x);

/**
 * @template {Base} T
 * @param {T} word
 * @returns {string | T}
 */
export const capitalize = (word) => {
  if (typeof word === "string") {
    return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
  }
  return word;
};

/**
 * @template {Base} L
 * @template {Base} F
 * @param {L} list
 * @param {F} fallback
 * @returns {L | F}
 */
export const packedListOr = (list, fallback) => {
  if (Array.isArray(list) && list.length > 0) {
    return list;
  }
  return fallback;
};

/**
 * @template {Base} M
 * @template {Base} F
 * @param {M} map
 * @param {F} fallback
 * @returns {M | F}
 */
export const packedMapOr = (map, fallback) => {
  if (typeof map === "object" && map !== null && Object.keys(map).length > 0) {
    return map;
  }
  return fallback;
};

/**
 * @param {unknown} value
 * @returns {boolean}
 */
export const hasLength = (value) => {
  try {
    return value.hasOwnProperty("length") && value.length > 0;
  } catch {
    return false;
  }
};

/**
 * @template {Base} F
 * @param {unknown} value
 * @param {F} fallback
 * @returns {number | F}
 */
export const parsedIntOr = (value, fallback) => {
  const result = parseInt(value);
  if (Number.isNaN(result)) {
    return fallback;
  }
  return result;
};

/**
 * @template {Base} F
 * @param {unknown} value
 * @param {F} fallback
 * @returns {number | F}
 */
export const numberOr = (value, fallback) => {
  if (typeof value === "number" && !Number.isNaN(value)) {
    return value;
  }
  return fallback;
};

/**
 * @template {Base} K
 * @template {Base} V
 * @param {[K, V]} param0
 * @returns {{
 *    [key:string]: value
 * }}
 */
export const entryToMap = ([key, value]) => ({
  [key]: value,
});

/**
 * @template {Base} T
 * @param {T} value
 * @returns {string | T}
 */
export const addEnumerableSuffix = (value) => {
  if (typeof value === "number") {
    const j = value % 10;
    const k = value % 100;
    if (j === 1 && k !== 11) {
      return value + "st";
    }
    if (j === 2 && k !== 12) {
      return value + "nd";
    }
    if (j === 3 && k !== 13) {
      return value + "rd";
    }
    return value + "th";
  }
  return value;
};

/**
 * @template {Base} F
 * @template {Base} T
 * @param {boolean} conditionValue
 * @param {F} fallback
 * @param {() => T} passFn
 * @returns {T | F}
 */
export const passOr = (conditionValue, fallback, passFn) => {
  if (conditionValue) {
    return passFn();
  }
  return fallback;
};

/**
 * @template {Base} T
 * @param {T} str
 * @param {number} limit
 * @returns {string | T}
 */
export const shortenString = (str, limit = 20) => {
  if (
    typeof str === "string" &&
    typeof limit === "number" &&
    limit > 0 &&
    str.length >= limit
  ) {
    const half = Math.floor(limit / 2);
    return `${str.slice(0, half)}...${str.slice(half * -1)}`;
  }
  return str;
};

/**
 *
 * @param {string} text
 * @returns {string[]}
 */
export const findBadWordsIn = (text) => {
  const badWords = process.env.REACT_APP_BAD_WORDS;

  if (typeof text === "string" && typeof badWords === "string") {
    const format = (t) => t.trim().replace(/\s+/g, " ").toLowerCase();

    const formattedText = format(text);

    const composeRegex = (w) => {
      const word = w.replace(" ", "").split("").join("([^a-zA-Z0-9]+|)");
      return new RegExp("\\b" + word + "\\b", "g");
    };

    const collect = (r, w) => (r.includes(w) ? r : [...r, w]);

    return badWords
      .split(",")
      .map(format)
      .filter((w) => composeRegex(w).test(formattedText))
      .reduce(collect, []);
  }

  return [];
};

/**
 * @param {string} target
 * @param {number?} maxLen
 * @returns {string}
 */
export const truncateString = (target, maxLen = 16) => {
  if (typeof target === "string" && target.length <= maxLen) {
    return target;
  } else {
    return target.slice(0, maxLen) + "...";
  }
};

/**
 * @template {Base} T
 * @param {T} n
 * @returns T | 1 | 0
 */
export function boolToNumber(n) {
  if (typeof n !== "boolean") {
    return n;
  }
  return n ? 1 : 0;
}

/* Formats target number to fixed-point float number without rounding
 *
 * @param {number} target
 * @param {number} fixed
 * @returns {string}
 */
export function toFixed(target, fixed) {
  if ([target, fixed].every((n) => typeof n === "number" && !Number.isNaN(n))) {
    const regExp = new RegExp("^-?\\d+(?:\\.\\d{0," + fixed + "})?", "g");
    const preparedTarget = target.toString().match(regExp)[0];
    const dotIndex = preparedTarget.indexOf(".");

    if (dotIndex === -1) {
      const fixedZeroes = fixed > 0 ? "." + "0".repeat(fixed) : "";
      return preparedTarget + fixedZeroes;
    }

    if (!fixed) {
      return String(parseInt(preparedTarget));
    }

    const remainingFixedSymbols =
      fixed - (preparedTarget.length - dotIndex) + 1;

    return remainingFixedSymbols > 0
      ? preparedTarget + "0".repeat(remainingFixedSymbols)
      : preparedTarget;
  }

  return "";
}

/**
 * Returns a length of numbers after dot
 *
 * @param {number} target
 * @returns {number}
 */
export function getPrecision(target) {
  if (typeof target === "number" && !Number.isNaN(target)) {
    const preparedTarget = target.toString();
    const splitByDot = preparedTarget.split(".").filter(Boolean);

    if (splitByDot.length === 1) {
      return 0;
    }

    return splitByDot[1].length;
  }

  return 0;
}

/**
 * @param {number} step
 * @param {number} edge
 * @returns number[]
 */
export const getRangeUpTo = (step, edge) => {
  if (step <= 0 || edge <= 0 || step >= edge || !step || !edge) {
    return [];
  }

  const result = [];

  for (let i = step; i <= edge; i = math.chain(i).add(step).round(3).done()) {
    result.push(i);
  }

  return result;
};

/** @param {number} n
 * @returns {number[]}
 */
export const arrayFrom = (n) => {
  return Array.from(Array(n).keys());
};

/**
 * @template {unknown} T
 * @param {Array<T>} list
 * @param {(a: T, b: T) => number} fn
 */
export const sort = (list, fn) => {
  const sortedList = [...list];
  sortedList.sort(fn);

  return sortedList;
};

/**
 * @template {unknown} R
 * @template {unknown} F
 * @param {object} target
 * @param {Array<string>} nesting
 * @param {F} fallback
 * @returns {R | F}
 */
export function extractFrom(target, nesting, fallback = undefined) {
  if (
    !Array.isArray(nesting) ||
    nesting.length === 0 ||
    typeof target !== "object" ||
    target === null
  ) {
    return fallback;
  }

  return nesting.reduce((carry, i) => {
    if (typeof carry === "object" && carry !== null && i in carry) {
      return carry[i];
    }

    return fallback;
  }, target);
}

/**
 * @param {unknown} value
 * @returns {boolean}
 */
export const isNotZero = (value) => value !== 0;

/**
 * @param {unknown} values
 * @returns {boolean}
 */
export const isZero = (value) => value === 0;

/**
 * @param {number[]} numbers
 * @returns {number}
 */
export const max = (numbers = []) => {
  return numbers.reduce((r, x) => (x > r ? x : r), numbers[0]);
};

/**
 * @params {Date} birthDate
 * @returns {number}
 */
export const calculateAge = (birthDate) => {
  const birth = new Date(birthDate);
  const today = new Date();
  let age = today.getFullYear() - birth.getFullYear();

  const monthDiff = today.getMonth() - birth.getMonth();
  const dayDiff = today.getDate() - birth.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
};

export const sortAlphabetically = (key) => (a, b) => {
  var a_ = a[key].toLowerCase();
  var b_ = b[key].toLowerCase();
  if (a_ < b_) return -1;
  if (a_ > b_) return 1;
  return 0;
};
