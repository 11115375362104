import { useInvoiceQuery } from "../../../../../api/queries/useInvoiceQuery";
import { tSales } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { unwrapOr } from "../../../../../utilities/general";
import { extractApiError } from "../../../../../utilities/api";
import { INVOICE_STATUSES } from "../../../../../consts/api";
import {
  extractUrlInvoiceId,
  prepareAmount,
} from "../../SalesCheckoutInvoice.utils";

export function useInvoice() {
  const invoiceId = extractUrlInvoiceId();

  const {
    data: invoiceData,
    isLoading,
    isRefetching,
    isError,
    error,
    refetch,
  } = useInvoiceQuery({
    payload: {
      invoiceId,
    },
    options: {
      enabled: !isNaN(invoiceId),
      onError: () => {
        uiNotification.error(tSales("checkoutInvoice.error.fetchInvoice"));
      },
    },
  });

  const beforeTipAmount = unwrapOr(
    () => prepareAmount(invoiceData.totalAmount - invoiceData.tipAmount),
    0,
  );

  const balanceDue = unwrapOr(
    () =>
      prepareAmount(
        invoiceData.totalAmount - invoiceData.paymentTransaction.totalAmount,
      ),
    0,
  );

  const clearentPublicKey = unwrapOr(() => invoiceData.clinic.publicKey, null);

  const amountToPay = balanceDue || invoiceData?.totalAmount;

  const isSuccessStage =
    invoiceData?.paymentTransaction?.payments?.length > 0 &&
    invoiceData?.status === INVOICE_STATUSES.paid;

  const isBalanceDueStage =
    invoiceData?.paymentTransaction?.payments?.length > 0 &&
    invoiceData?.status !== INVOICE_STATUSES.paid;

  const isStartStage = !isSuccessStage && !isBalanceDueStage;

  const tipPercentage = unwrapOr(() =>
    prepareAmount((invoiceData?.tipAmount / invoiceData.subtotalAmount) * 100),
  );

  const isGuest =
    invoiceData?.patient?.fullName.toLowerCase() === "guest patient" ||
    invoiceData?.patient?.fullName.toLowerCase() === "guest checkout";

  const isTipsEnabled = () => {
    if (invoiceData?.isMerger) {
      const tipsEnabled = invoiceData.mergedInvoices.filter((i) => {
        return i.isTipsEnabled;
      });
      return Boolean(tipsEnabled.length);
    }
    return invoiceData.isTipsEnabled;
  };

  return {
    invoice: invoiceData || null,
    clearentPublicKey,
    balanceDue,
    beforeTipAmount,
    tipPercentage,
    isInvoiceLoading: isLoading,
    isInvoiceRefetching: isRefetching,
    isInvoiceError: isError,
    errorMessage: extractApiError(error),
    amountToPay,
    isStartStage,
    isBalanceDueStage,
    isSuccessStage,
    refetchInvoice: refetch,
    isGuest,
    hasRmdRedemptions: invoiceData?.hasRmdRedemptions,
    hasJoyaRedemptions: invoiceData?.hasJoyaRedemptions,
    isTipsEnabled,
    currency: invoiceData?.currency,
    currencySymbol: invoiceData?.currencySymbol,
  };
}
