/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import InboxSidebar from "../InboxSidebar.js";
import Loader from "../../Common/Loader.js";
import {
  listSMS,
  searchPatientByName,
  smsReadUnread,
  exportEmptyData,
} from "../../../Actions/Inbox/inboxAction.js";
import { fetchNotificationsPopupsMenu } from "../../../Actions/Dashboard/dashboardActions";
import {
  formatInboxTime,
  displayName,
  showFormattedDate,
  autoScrolling,
  getTwilioConfig,
  getTwilioFromNumber,
  positionFooterInstantly,
} from "../../../Utils/services.js";
import { shortenString } from "../../../utilities/general.js";
import { LeadArInbox } from "../LeadArInbox/LeadArInbox.js";
import { BlackList } from "../BlackList/BlackList.js";
import { withLeadArAvailabilityStatusQuery } from "../../../api/queries/useLeadArAvailabilityStatusQuery.js";
import { ROUTES } from "../../../consts/routes.js";
import { EfaxOrders } from "../EfaxOrders/EfaxOrders.js";
import { getIsEfaxIntegrationAccessible } from "../../../helpers/integrations.js";
import { withGlobalSettings } from "../../../hocs/withGlobalSettings.js";
import { uiNotification } from "../../../services/UINotificationService.js";
import { inbox } from "../../../store/inbox/index.js";
import { withCurrentUserQuery } from "../../../api/queries/useUserQuery.js";
import { pipe } from "../../../utilities/fp.js";
import { useCurrentAccountQuery } from "../../../api/queries/useAccountQuery.js";
import { withAppTranslation } from "../../../i18n/useAppTranslation.js";

const INBOX_SETUP_URL = "/settings/inbox";

class ListSMS extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    let inboxClinicId = localStorage.getItem("inboxClinicId")
      ? localStorage.getItem("inboxClinicId")
      : -1;
    let inboxClinicName = localStorage.getItem("inboxClinicName")
      ? localStorage.getItem("inboxClinicName")
      : "";
    this.state = {
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      is_edit_card: false,
      showClients: false,
      createSMS: true,
      page: 1,
      pagesize: 15,
      sortorder: "asc",
      term: "",
      mode: this.props?.activeTab || "unread",
      smsList: [],
      clients: [],
      unread_count: 0,
      all_count: 0,
      read_count: 0,
      show_mark_as_read_button: false,
      show_mark_as_unread_button: false,
      changed: undefined,
      twilio_from_number:
        userData && userData.account && userData.account.twilio_from_number
          ? userData.account.twilio_from_number
          : "",

      next_page_url: "",
      clinicList: [],
      inboxClinicId: this.props.match.params.inboxClinicId
        ? this.props.match.params.inboxClinicId
        : inboxClinicId,
      showLoader: false,
      inboxClinicName: inboxClinicName,
      isEfaxEnabled: false,
      isSearching: false,
    };

    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.next_page_url != null &&
        !this.isEfaxOrdersPage()
      ) {
        this.loadMore();
      }
    };
  }

  componentDidMount() {
    const isEfaxEnabled = this.props.globalSettings?.efax_integration;

    if (getTwilioConfig() === false && !isEfaxEnabled) {
      this.props.history.push(INBOX_SETUP_URL);
    } else if (getTwilioConfig() === false && isEfaxEnabled) {
      this.props.history.push(ROUTES.inbox.efaxOrders());
    }

    let formData = {
      params: {
        page: this.state.page,
        pagesize: this.state.pagesize,
        read: this.props?.activeTab === "unread" ? 0 : 1,
      },
    };

    if (this.state.inboxClinicId > 0) {
      formData.params.clinic_id = this.state.inboxClinicId;
    }
    this.setState({ showLoader: true });
    autoScrolling(true);
    document.addEventListener("click", this.handleClick, false);
    this.props.listSMS(formData);
    positionFooterInstantly();
  }

  handleSubmit = (event) => {
    event.preventDefault();
    autoScrolling(true);
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        read: this.state.mode == "unread" ? 0 : 1,
        patient_text: this.state.term,
      },
    };

    if (this.state.inboxClinicId > 0) {
      formData.params.clinic_id = this.state.inboxClinicId;
    }
    this.setState({ showLoader: true, smsList: [], page: 1 });

    this.props.listSMS(formData);
    localStorage.removeItem("inboxClinicId");
    localStorage.removeItem("inboxClinicName");
    this.props.history.push("/inbox/sms/all");
  };

  handleInputChange = ({ target }) => {
    let { value, type, checked, name } = target;

    if (type === "checkbox") {
      value = checked;
    }

    this.setState({ [name]: value }, () => {
      if (this.state.mode == "unread") {
        let checkStatus;
        checkStatus = this.state.smsList.find(
          (y) => this.state["sms-" + y.id] === true,
        );

        if (checkStatus) {
          this.setState({ show_mark_as_read_button: true });
        } else {
          this.setState({ show_mark_as_read_button: false });
        }
      }

      if (this.state.mode == "read") {
        let checkStatus;
        checkStatus = this.state.smsList.find(
          (y) => this.state["sms-" + y.id] === true,
        );
        if (checkStatus) {
          this.setState({ show_mark_as_unread_button: true });
        } else {
          this.setState({ show_mark_as_unread_button: false });
        }
      }
    });
  };

  handleNameChange = ({ target: { value, name } }) => {
    localStorage.setItem("showLoader", "true");

    this.setState({ [name]: value });

    if (value.length > 2) {
      this.props.searchPatientByName({ client: value });
    }
  };

  createSMSOpener = () => {
    this.setState({ createSMS: false });
  };

  sendMessage = () => {};

  loadMore = () => {
    if (!autoScrolling()) {
      localStorage.setItem("sortOnly", false);
      this.setState({
        loadMore: true,
        startFresh: true,
        showLoader: true,
        showLoadingText: true,
      });
      let formData = {
        params: {
          page: this.state.page,
          pagesize: this.state.pagesize,
          sortorder: this.state.sortorder,
          read:
            this.state.mode === "unread" || this.state.mode === "all" ? 0 : 1,
        },
      };

      if (this.state.term) {
        formData.params.term = this.state.term;
        formData.params.patient_text = this.state.term;
      }

      if (this.state.inboxClinicId > 0) {
        formData.params.clinic_id = this.state.inboxClinicId;
      }

      autoScrolling(true);
      this.props.listSMS(formData);
    }
  };

  filterSMS = (mode) => {
    this.props.setActiveTab(mode);
    autoScrolling(true);
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        read: mode === "read" ? 1 : 0,
      },
    };

    if (this.state.term) {
      formData.params.term = this.state.term;
      formData.params.patient_text = this.state.term;
    }
    if (this.state.inboxClinicId > 0) {
      formData.params.clinic_id = this.state.inboxClinicId;
    }
    this.setState({
      showLoader: true,
      mode: mode,
      smsList: [],
      next_page_url: "",
      page: 1,
    });
    this.props.listSMS(formData);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.clients != undefined &&
      nextProps.clientsTime != prevState.clientsTime
    ) {
      returnState.clientsTime = nextProps.clientsTime;
      returnState.clients = nextProps.clients;
      returnState.showClients = true;
      positionFooterInstantly();
    }

    if (
      nextProps.smsList != undefined &&
      nextProps.smsListTime != prevState.smsListTime
    ) {
      nextProps.exportEmptyData();
      returnState.smsListTime = nextProps.smsListTime;
      returnState.unread_count =
        nextProps.smsList.data.notification_counts.unread_count;
      returnState.clinicList = nextProps.smsList.data.clinics
        ? nextProps.smsList.data.clinics
        : [];
      returnState.read_count =
        nextProps.smsList.data?.notification_counts.read_count || 0;
      returnState.showLoader = false;
      if (prevState.smsList.length == 0) {
        returnState.smsList = nextProps.smsList.data.data;
        if (nextProps.smsList.data.next_page_url != null) {
          returnState.page = prevState.page + 1;
        } else {
          returnState.next_page_url = nextProps.smsList.data.next_page_url;
        }
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        returnState.show_mark_as_read_button = false;
        returnState.show_mark_as_unread_button = false;
        nextProps.smsList.data.data.map((obj) => {
          returnState["sms-" + obj.id] = false;
        });
      } else if (
        !(
          JSON.stringify(prevState?.smsList) ===
          JSON.stringify(nextProps?.smsList?.data?.data)
        ) &&
        prevState.smsList.length != 0
      ) {
        returnState.smsList = [
          ...prevState.smsList,
          ...nextProps.smsList.data.data,
        ];
        returnState.unread_count =
          nextProps.smsList.data.notification_counts.unread_count;
        returnState.read_count =
          nextProps.smsList.data.notification_counts.read_count;
        returnState.page = prevState.page + 1;
        returnState.next_page_url = nextProps.smsList.data.next_page_url;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        nextProps.smsList.data.data.map((obj) => {
          returnState["sms-" + obj.id] = false;
        });
      }

      if (nextProps.changed != undefined) {
        returnState.changed = nextProps.changed;
      }
      autoScrolling(false);
    }
    return returnState;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.changed == undefined && this.state.changed == true) {
      this.props.fetchNotificationsPopupsMenu();
      this.setState({ changed: undefined });
    }
  }

  handleClick = (e) => {
    if (
      this.refAutoSuggestionClients &&
      !this.refAutoSuggestionClients.contains(e.target)
    ) {
      this.setState({ showClients: false });
    }
  };

  selectClient = (obj) => {
    if (obj.phoneNumber == "") {
      uiNotification.error("There is no phone number attached to the patient");
      return;
    }
    this.props.history.push("/inbox/sms/create/" + obj.id);
  };

  openChat = (id) => {
    this.props.history.push("/inbox/sms/edit/" + id);
  };

  markAsReadOrUnread = (readStatus) => {
    autoScrolling(true);
    let selectedSMS = [];
    this.state.smsList.map((obj) => {
      if (this.state["sms-" + obj.id] == true) {
        selectedSMS.push(obj.id);
      }
    });
    let formData = {
      page: 1,
      pagesize: this.state.pagesize,
      read:
        (this.state.mode === "unread" && this.props.inboxFlag) ||
        this.state.mode === "all"
          ? 0
          : 1,
      id: selectedSMS,
      read_status: readStatus,
      params: {},
    };

    if (this.state.term) {
      formData.params.term = this.state.term;
      formData.params.patient_text = this.state.term;
    }
    if (this.state.inboxClinicId > 0) {
      formData.clinic_id = this.state.inboxClinicId;
    }
    this.setState({
      showLoader: true,
      page: 1,
      smsList: [],
      next_page_url: "",
    });
    this.props.smsReadUnread(formData);
  };

  handleClinicSelection = (clinicObj) => {
    let inboxClinicId = this.state.inboxClinicId;
    let inboxClinicName = this.state.inboxClinicName;
    if (inboxClinicId !== clinicObj.id) {
      autoScrolling(true);
      inboxClinicId = clinicObj.id;
      inboxClinicName = clinicObj.clinic_name;
      let formData = {
        params: {
          page: 1,
          pagesize: this.state.pagesize,
          read: this.state.mode === "unread" ? 0 : 1,
        },
      };

      if (inboxClinicId > 0) {
        formData.params.clinic_id = inboxClinicId;
      }
      this.setState({
        showLoader: true,
        smsList: [],
        next_page_url: "",
        page: 1,
        inboxClinicId: inboxClinicId,
        term: "",
        inboxClinicName: inboxClinicName,
      });
      this.props.listSMS(formData);
      localStorage.setItem("inboxClinicId", inboxClinicId);
      localStorage.setItem("inboxClinicName", inboxClinicName);
    }
  };

  isEfaxOrdersPage = () => {
    return (
      window.location.pathname.includes(ROUTES.inbox.efaxOrders()) &&
      getIsEfaxIntegrationAccessible()
    );
  };

  renderSubPage = () => {
    if (this.isEfaxOrdersPage()) {
      return <EfaxOrders />;
    }

    if (window.location.pathname.includes("/inbox/sms/lead-ar")) {
      if (
        this.props.leadArAvailabilityStatusQuery?.data?.isAvailable ||
        false
      ) {
        return <LeadArInbox />;
      }
      return null;
    }

    if (window.location.pathname.includes("/inbox/sms/black-list")) {
      return <BlackList />;
    }

    return null;
  };

  render() {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <InboxSidebar
            clinicList={this.state.clinicList}
            inboxClinicId={this.state.inboxClinicId}
            handleClinicSelection={this.handleClinicSelection}
            isEfaxEnabled={this.state.isEfaxEnabled}
          />
          {this.renderSubPage() || (
            <div className="memberWalletOuter business-section">
              <div className="setting-setion m-b-10">
                <div className="membership-title">
                  {this.state.inboxClinicName &&
                    this.state.inboxClinicId > 0 && (
                      <span className="text-capitalize">
                        {`${this.state.inboxClinicName} - `}
                      </span>
                    )}
                  {this.state.inboxClinicName && this.state.inboxClinicId > 0
                    ? this.state.globalLang.label_messages
                    : "AR Messages"}
                  <form onSubmit={this.handleSubmit} className="searchForm">
                    <span className="searchOuter">
                      <i className="fa fa-search search-icon" />
                      <input
                        className="setting-search-input search-key"
                        placeholder="Search by name"
                        name="term"
                        autoComplete="off"
                        value={this.state.term}
                        onChange={this.handleInputChange}
                      />
                    </span>
                  </form>
                  {getTwilioConfig() === true &&
                    getTwilioFromNumber() != "" && (
                      <div className="memberRightActions">
                        <p className="membership-title-right">
                          Your Number : {getTwilioFromNumber()}
                        </p>
                      </div>
                    )}
                </div>
              </div>
              <div className="setting-setion m-b-15">
                {this.state.createSMS && (
                  <div className="membership-title">
                    {this.props.inboxFlag && (
                      <a
                        className={
                          this.state.mode == "unread"
                            ? "newTabs"
                            : "newTabs disabled-tab"
                        }
                        onClick={this.filterSMS.bind(this, "unread")}
                      >
                        {this.state.globalLang.label_unread} (
                        {this.state.unread_count})
                      </a>
                    )}
                    <a
                      className={
                        this.state.mode == "read"
                          ? "newTabs"
                          : "newTabs disabled-tab"
                      }
                      onClick={this.filterSMS.bind(this, "read")}
                    >
                      Read ({this.state.read_count})
                    </a>
                    <div className="memberRightActions">
                      <button
                        onClick={this.createSMSOpener}
                        className="new-blue-btn pull-right m-l-10"
                      >
                        {this.state.globalLang.label_create_message}
                      </button>

                      {this.state.show_mark_as_read_button &&
                        this.props.inboxFlag &&
                        this.state.mode !== "read" && (
                          <button
                            onClick={() => this.markAsReadOrUnread(1)}
                            className="new-blue-btn pull-right m-l-10"
                          >
                            Mark as Read
                          </button>
                        )}
                      {this.state.show_mark_as_unread_button &&
                        this.state.mode == "read" && (
                          <button
                            onClick={() => this.markAsReadOrUnread(0)}
                            className="new-blue-btn pull-right m-l-10"
                          >
                            Mark as Unread
                          </button>
                        )}
                    </div>
                  </div>
                )}
                {!this.state.createSMS && (
                  <div className="membership-title">
                    <div className="col-sm-6 col-xs-12 relative no-padding div-searchClientSMS">
                      <input
                        type="text"
                        className="searchClientSMS"
                        name="searchClientSMS"
                        value={this.state.searchClientSMS || ""}
                        onChange={this.handleNameChange}
                        placeholder={this.props.tInbox("enterPatientName")}
                        autoComplete="off"
                      />
                      <ul
                        className={
                          this.state.clients.length && this.state.showClients
                            ? " search-dropdown ul-searchClientSMS"
                            : "cal-dropdown clinicname-dropdown no-display"
                        }
                        ref={(refAutoSuggestionClients) =>
                          (this.refAutoSuggestionClients =
                            refAutoSuggestionClients)
                        }
                      >
                        {this.state.clients.length &&
                          this.state.clients.map((obj, idx) => {
                            return (
                              <li
                                key={"client-" + idx}
                                data-id={obj.id}
                                onClick={this.selectClient.bind(this, obj)}
                              >
                                <a>{obj && displayName(obj)}</a>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                    <button
                      onClick={() =>
                        this.setState({ createSMS: true, searchClientSMS: "" })
                      }
                      className="new-white-btn pull-right m-l-10"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={this.sendMessage}
                      className="new-blue-btn pull-right m-l-10"
                    >
                      {this.state.globalLang.label_send_message}
                    </button>
                  </div>
                )}
                <div className="table-responsive">
                  <table className="table-updated setting-table no-td-border">
                    <thead className="table-updated-thead">
                      <tr>
                        <th className="col-xs-1 table-updated-th"></th>
                        <th className="col-xs-3 table-updated-th">
                          {this.props.tInbox("leadAr.table.patient")}
                        </th>
                        <th className="col-xs-4 table-updated-th">
                          {this.state.globalLang.label_message}
                        </th>
                        <th className="col-xs-2 table-updated-th">
                          {this.state.globalLang.label_date}
                        </th>
                        <th className="col-xs-2 table-updated-th">
                          {this.state.globalLang.label_time}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state?.smsList?.length > 0 ? (
                        this.state.smsList.map((obj, idx) => {
                          let userImage = obj.user_image
                            ? obj.user_image.trim()
                            : "/images/user.png";
                          let redBlockedImage = "/images/red_blocked.png";
                          return (
                            <tr
                              key={"smsList-" + idx}
                              className={
                                obj.read == 0 && this.state.mode == "all"
                                  ? "trBold table-updated-tr"
                                  : " table-updated-tr"
                              }
                              onClick={this.openChat.bind(this, obj.id)}
                            >
                              <td
                                className="col-xs-1 table-updated-td"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <input
                                  type="checkbox"
                                  value={"sms-" + obj.id}
                                  name={"sms-" + obj.id}
                                  checked={
                                    this.state["sms-" + obj.id]
                                      ? "checked"
                                      : false
                                  }
                                  autoComplete="off"
                                  onChange={this.handleInputChange}
                                />
                                {Boolean(obj?.is_fired) && (
                                  <img
                                    alt=""
                                    className="m-l-20 vertical-align-initial"
                                    width={20}
                                    src={redBlockedImage}
                                  />
                                )}
                              </td>
                              <td className="col-xs-3 table-updated-td">
                                <img
                                  alt=""
                                  className="user-profile-img"
                                  src={userImage}
                                />{" "}
                                {obj.firstname || ""} {obj.lastname || ""}
                              </td>
                              <td className="col-xs-4 table-updated-td word-break">
                                {shortenString(obj.message, 250)}
                              </td>
                              <td className="col-xs-2 table-updated-td">
                                {showFormattedDate(
                                  obj.created,
                                  false,
                                  this.props.currentUserQuery?.data
                                    ?.previewDateFormat,
                                )}
                              </td>
                              <td className="col-xs-2 table-updated-td">
                                {formatInboxTime(obj.created)}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="table-updated-tr">
                          <td
                            colSpan="6"
                            className="text-center table-updated-td"
                          >
                            {this.state.globalLang.no_data_found}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function ListSmsWrapper(props) {
  const { data } = useCurrentAccountQuery();

  return (
    <div>
      {data?.globalSettings?.featureFlag ? (
        <ListSMS
          {...props}
          inboxFlag={data?.globalSettings?.featureFlag.inbox}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  let returnState = {};
  returnState.activeTab = inbox.selectors.selectActiveInboxTab(state);

  const languageData = JSON.parse(localStorage.getItem("languageData"));
  if (state.InboxReducer.action === "LIST_SMS") {
    if (state.InboxReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.smsList = state.InboxReducer.data;
      returnState.smsListTime = new Date();
    }
  }
  if (state.InboxReducer.action === "SEARCH_PATIENTS") {
    if (state.InboxReducer.data.status != 200) {
      returnState.showLoader = false;
    } else {
      returnState.clients = state.InboxReducer.data.data;
      returnState.clientsTime = state.InboxReducer.data.data;
    }
  }

  if (state.InboxReducer.action == "SMS_READ_UNREAD") {
    if (state.InboxReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      uiNotification.clear();
      uiNotification.success(
        languageData.global[state.InboxReducer.data.message],
      );
      returnState.smsList = state.InboxReducer.data;
      returnState.changed = true;
      returnState.smsListTime = new Date();
    }
  }
  positionFooterInstantly();
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      listSMS: listSMS,
      searchPatientByName: searchPatientByName,
      smsReadUnread: smsReadUnread,
      fetchNotificationsPopupsMenu: fetchNotificationsPopupsMenu,
      exportEmptyData: exportEmptyData,
      setActiveTab: inbox.actions.setInboxTabType,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  pipe(
    ListSmsWrapper,
    withRouter,
    withGlobalSettings,
    withLeadArAvailabilityStatusQuery,
    withCurrentUserQuery,
    withAppTranslation("inbox"),
  ),
);
