import React from "react";
import { capitalizeFirstLetter, getCurrencySymbol } from "../../../../Utils";
import classes from "./SourceRefundsList.module.scss";

const SourceRefundsList = ({ refundOptions, refundType }) => {
  return (
    <ul className={classes.refundsList}>
      {refundOptions.map((refund, index) => {
        const normalizedPaymentMode = refund.payment_mode.toLowerCase();
        const refundTo =
          normalizedPaymentMode === "gift card"
            ? "Wallet"
            : refund.payment_mode;
        const formattedRefundTo = capitalizeFirstLetter(
          refundType === "default" ? refundTo : refundType,
        );

        return (
          <li key={index}>
            <p>
              {`${getCurrencySymbol()}${refund.total_amount}`}{" "}
              {capitalizeFirstLetter(refund.payment_mode)} payment refunding to{" "}
              {formattedRefundTo}
            </p>
          </li>
        );
      })}
    </ul>
  );
};

export default SourceRefundsList;
