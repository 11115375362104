import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./ModalCharge.module.scss";
import { Button } from "../../../../../shared/Button/Button";
import {
  tCommon,
  useAppTranslation,
} from "../../../../../i18n/useAppTranslation";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { formatCurrency } from "../../../../../utilities/general";
import { useInvoice } from "../../hooks/invoice/useInvoice";

export function Footer({
  onClose,
  isCharging,
  onCharge,
  isChargeDisabled,
  isCancelDisabled,
  chargeLabel,
  className,
  amount,
}) {
  const { tCommon } = useAppTranslation.Common();
  const { currency } = useInvoice();

  return (
    <div className={cx(classes.footer, className)}>
      <Button
        variant="outlined"
        onClick={onClose}
        isDisabled={isCharging || isCancelDisabled}
        className={classes.btn}
      >
        {tCommon("label.cancel")}
      </Button>
      <Button
        onClick={onCharge}
        isDisabled={isCharging || isChargeDisabled}
        className={classes.btn}
        leftAdornment={
          isCharging ? (
            <CircularProgress color="white" size="small" />
          ) : undefined
        }
      >
        <div className={classes.btnContent}>
          <span>{chargeLabel}</span>
          {amount && <span>{formatCurrency(amount, currency)}</span>}
        </div>
      </Button>
    </div>
  );
}

Footer.propTypes = {
  onClose: PropTypes.func.isRequired,
  chargeLabel: PropTypes.string,
  onCharge: PropTypes.func.isRequired,
  isCharging: PropTypes.bool.isRequired,
  isChargeDisabled: PropTypes.bool,
  isCancelDisabled: PropTypes.bool,
  className: PropTypes.string,
};

Footer.defaultProps = {
  chargeLabel: tCommon("label.charge"),
  isChargeDisabled: false,
  isCancelDisabled: false,
  className: undefined,
};
