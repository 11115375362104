/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const ProductNameSpinner = () => (
  <div className="spinOuter">
    {" "}
    <i className="fa fa-spinner fa-spin" />
  </div>
);

export function Info({
  loadingSpinner,
  isEvolusProduct,
  unitTypeArr,
  productName,
  inventoryType,
  productCategory,
  productType,
  productSku,
  costToCompany,
  aspireProduct,
  linkToJeuveau,
  productImage,
  description,
  unitType,
  injectableType,
  surgeryType,
  unitsCount,
  startsAtUnit,
  productRatio,
  productKeywords,
  billForWholeUnit,
  accountCostToCompany,
  deductInventory,
}) {
  return (
    <div>
      <div className="row">
        <div className="settings-subtitle m-b-0 m-t-10 col-xs-12">
          General Information
        </div>
        <div className="col-md-10 p-l-0">
          <div className="col-md-4 col-sm-6 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">
                Product Name <span className="setting-require">*</span>
              </div>
              <div className="setting-input-outer">
                <input
                  className={
                    productName.error
                      ? "newInputField field-error p-r-20"
                      : "newInputField p-r-20"
                  }
                  type="text"
                  name="product_name"
                  placeholder="Product Name"
                  onBlur={productName.onBlur}
                  onChange={productName.onChange}
                  autoComplete="off"
                  value={productName.value}
                  ref={productName.ref}
                />
                {loadingSpinner && <ProductNameSpinner />}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">
                Inventory Type
                <span className="setting-require">*</span>
              </div>
              <select
                className={
                  inventoryType.error
                    ? "newSelectField field-error"
                    : "newSelectField"
                }
                name="inventory_type"
                onChange={inventoryType.onChange}
                value={inventoryType.value}
              >
                <option value="retail">Retail</option>
                <option value="services">Service</option>
                <option value="medical_supplies">Medical Supplies</option>
              </select>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">
                Product Category
                <span className="setting-require">*</span>
              </div>
              <select
                className={
                  productCategory.error
                    ? "newSelectField field-error"
                    : "newSelectField"
                }
                name="product_category"
                onChange={productCategory.onChange}
                value={productCategory.value}
                ref={productCategory.ref}
              >
                <option value={0}>Please Select</option>
                {productCategory.options?.map((obj, idx) => (
                  <option value={obj.id} key={"cat-" + idx}>
                    {obj.category_name}
                  </option>
                ))}
                <option value="addCategory">Create new category</option>
              </select>
            </div>
          </div>
          {inventoryType.value === "services" && (
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">
                  Product Type
                  <span className="setting-require">*</span>
                </div>
                <select
                  className={
                    productType.error
                      ? "newSelectField field-error"
                      : "newSelectField"
                  }
                  name="product_type"
                  onChange={productType.onChange}
                  value={productType.value}
                  ref={productType.ref}
                >
                  <option value={0}>Select</option>
                  {productType.options?.map((obj, idx) => (
                    <option value={obj.id} key={"type-" + idx}>
                      {obj.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          <div className="col-md-4 col-sm-6 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">Product SKU</div>
              <input
                className="newInputField"
                type="text"
                name="product_sku"
                onChange={productSku.onChange}
                placeholder="Product SKU"
                autoComplete="off"
                value={productSku.value}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">
                Cost to Company
                <span className="setting-require">*</span>
              </div>
              <input
                className={
                  costToCompany.error
                    ? "newInputField field-error"
                    : "newInputField"
                }
                type="text"
                name="cost_to_company"
                placeholder="Cost to Company"
                onChange={costToCompany.onChange}
                autoComplete="off"
                value={costToCompany.value}
                ref={costToCompany.ref}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">Aspire Product</div>
              <select
                className="newSelectField"
                name="selected_aspire_group"
                value={aspireProduct.value}
                onChange={aspireProduct.onChange}
              >
                <option value={0}>Please Select</option>
                {aspireProduct.options?.map((productGroup) => (
                  <option
                    key={productGroup.first_product_id}
                    value={productGroup.first_product_id}
                  >
                    {productGroup.group_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">Link to Jeuveau</div>
              <label className="setting-switch pull-left m-r-10">
                <input
                  type="checkbox"
                  id="is_evolus_product"
                  onChange={linkToJeuveau.onChange}
                  name="is_evolus_product"
                  checked={isEvolusProduct ? "checked" : false}
                  className="setting-custom-switch-input"
                />
                <span className="setting-slider green-switch" />
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-2 col-xs-12">
          <div className="main-profile-picture edit-product-upload">
            <div className="no-padding">
              <div className="file-container pull-right">
                {productImage.value && (
                  <a
                    className="delete-file"
                    onClick={productImage.onRemove}
                  ></a>
                )}
                <img alt="" src={productImage.value || ""} />
                <span
                  className={
                    productImage.thumb
                      ? "file-name file-info"
                      : "file-name-hide no-display"
                  }
                >
                  {productImage.thumb}
                </span>
                <span
                  className={
                    productImage.size
                      ? "file-size file-info"
                      : "file-size-hide no-display"
                  }
                >
                  {productImage.size}
                </span>
                <div className="upload">
                  Upload
                  <input
                    type="file"
                    name="product_image"
                    onChange={productImage.onChange}
                    autoComplete="off"
                    className="image_questionnaire"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <div className="newInputFileldOuter m-t-20">
            <div className="newInputLabel">Description</div>
            <textarea
              className="newtextareaField textarea-height-50"
              name="product_description"
              placeholder="Description"
              value={description.value}
              onChange={description.onChange}
            />
          </div>
        </div>
        <div
          className={
            productType.value != 0 && productType.value == "others"
              ? "no-display"
              : "settings-subtitle m-b-0 m-t-10 col-xs-12"
          }
        >
          Unit Settings
        </div>
        <div
          className={
            productType.value != 0 && productType.value != "others"
              ? "col-md-3 col-sm-6 col-xs-12"
              : "col-md-3 col-sm-6 col-xs-12 no-display"
          }
        >
          <div className="newInputFileldOuter">
            <div className="newInputLabel">
              Unit Type
              <span className="setting-require">*</span>
            </div>
            <select
              className={
                unitType.error ? "newSelectField field-error" : "newSelectField"
              }
              name="unit_type"
              onChange={unitType.onChange}
              value={unitType.value}
            >
              <option value={0}>N/A</option>
              {productType.value != 0 &&
                unitTypeArr?.map((obj, idx) => (
                  <option value={obj.id} key={"unit_type-" + idx}>
                    {obj.value}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div
          className={
            ["injectable", "threads", "microblading", "cellfina"].includes(
              productType.value,
            )
              ? "col-md-3 col-sm-6 col-xs-12"
              : "col-md-3 col-sm-6 col-xs-12 no-display"
          }
        >
          <div className="newInputFileldOuter">
            <div className="newInputLabel">
              Injectable Type
              <span className="setting-require">*</span>
            </div>
            <select
              className={
                injectableType.error
                  ? "newSelectField field-error"
                  : "newSelectField"
              }
              name="injectable_type"
              onChange={injectableType.onChange}
              value={injectableType.value}
            >
              <option value={0}>Select</option>
              {injectableType.options.map((obj) => (
                <option value={obj.key} key={"injectableType-" + obj.key}>
                  {obj.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {productType.value === "surgery" && (
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="newInputFileldOuter">
              <div className="newInputLabel">
                Surgery Type
                <span className="setting-require">*</span>
              </div>
              <select
                className={`newSelectField ${
                  surgeryType.error ? "field-error" : ""
                }`}
                name="surgery_type"
                onChange={surgeryType.onChange}
                value={surgeryType.value}
              >
                <option value={0} disabled>
                  Select
                </option>
                {surgeryType.options?.map((obj) => (
                  <option value={obj.key} key={"surgeryType-" + obj.key}>
                    {obj.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div
          className={
            productType.value != 0 && productType.value != "others"
              ? "col-md-3 col-sm-6 col-xs-12"
              : "col-md-3 col-sm-6 col-xs-12 no-display"
          }
        >
          <div className="newInputFileldOuter">
            <div className="newInputLabel">
              Count units By
              <span className="setting-require">*</span>
            </div>
            <select
              className={
                unitsCount.error
                  ? "newSelectField field-error"
                  : "newSelectField"
              }
              name="count_units_by"
              onChange={unitsCount.onChange}
              value={unitsCount.value}
            >
              <option value={0}>N/A</option>
              {unitsCount.options?.map((obj, idx) => (
                <option value={obj} key={"unitsBy-" + idx}>
                  {obj}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          className={
            productType.value != 0 && productType.value != "others"
              ? "col-md-3 col-sm-6 col-xs-12"
              : "col-md-3 col-sm-6 col-xs-12 no-display"
          }
        >
          <div className="newInputFileldOuter">
            <div className="newInputLabel">
              Start at Unit
              <span className="setting-require">*</span>
            </div>
            <select
              className={
                startsAtUnit.error
                  ? "newSelectField field-error"
                  : "newSelectField"
              }
              name="start_at_unit"
              onChange={startsAtUnit.onChange}
              value={startsAtUnit.value}
            >
              <option value={0} disabled>
                Select
              </option>
              {startsAtUnit.options?.map((obj, idx) => (
                <option value={obj} key={"starts-" + idx}>
                  {obj}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-xs-12">
          <div className="row">
            <div
              className={
                productType.value != "others"
                  ? "col-md-6 col-sm-6 col-xs-12"
                  : "col-md-6 col-sm-6 col-xs-12 no-display"
              }
            >
              <div className="newInputFileldOuter m-t-25">
                <div className="switch-accordian-row" id="book">
                  <label className="setting-switch pull-left m-r-10">
                    <input
                      type="checkbox"
                      id="app_booking"
                      onChange={productRatio.onCheck}
                      name="product_ratio_enabled"
                      checked={productRatio.checked ? "checked" : false}
                      className="setting-custom-switch-input"
                    />
                    <span className="setting-slider green-switch" />
                  </label>
                  Product Ratio
                </div>
              </div>
            </div>
            <div
              className={
                productRatio.checked && productType.value != "others"
                  ? "col-md-6 col-sm-6 col-xs-12"
                  : "col-md-6 col-sm-6 col-xs-12 no-display"
              }
            >
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Ratio for this product</div>
                <div
                  className={
                    productRatio.error
                      ? "newInputField field-error"
                      : "newInputField"
                  }
                >
                  1 :&nbsp;
                  <input
                    className="ratioOfProduct"
                    type="text"
                    name="product_ratio"
                    autoComplete="off"
                    value={productRatio.value}
                    onChange={productRatio.onChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            productType.value == "others" ? "col-xs-12" : "col-md-6 col-xs-12"
          }
        >
          <div className="simpleField">
            <div className="simpleLabel">Product Keywords</div>
            <div className="simpleInput auto-height p-t-5">
              <TagsInput
                addOnBlur={true}
                addKeys={[9, 13, 32]}
                onlyUnique={true}
                value={productKeywords.value || []}
                onChange={productKeywords.onChange}
                inputProps={{ placeholder: "Keywords" }}
              />
            </div>
          </div>
        </div>
        <div
          className={
            !["medical_supplies", "retail"].includes(inventoryType.value)
              ? "row m-b-10 p-t-10 no-margin"
              : "no-display"
          }
        >
          <div className="col-sm-3 col-xs-12">Bill for the whole unit</div>
          <div className="col-sm-9 col-xs-12">
            <div className="basic-checkbox-outer m-t-0">
              <input
                id="radiobutton3"
                className="basic-form-checkbox"
                name="billForWholeUnit"
                type="radio"
                value="1"
                onChange={billForWholeUnit.onChange}
                checked={billForWholeUnit.checked == 1}
              />
              <label className="basic-form-text" htmlFor="radiobutton3">
                Yes
              </label>
            </div>
            <div className="basic-checkbox-outer m-t-0">
              <input
                id="radiobutton4"
                className="basic-form-checkbox"
                name="billForWholeUnit"
                type="radio"
                value="0"
                onChange={billForWholeUnit.onChange}
                checked={billForWholeUnit.checked == 0}
              />
              <label className="basic-form-text" htmlFor="radiobutton4">
                No
              </label>
            </div>
          </div>
        </div>
        {inventoryType.value == "medical_supplies" && (
          <div>
            <div className="col-xs-12 m-t-20">
              <div className="check-note">
                <input
                  id="chkCccount_cost_to_company"
                  type="checkbox"
                  className="note-check"
                  name="account_cost_to_company"
                  checked={accountCostToCompany.checked ? "checked" : false}
                  onChange={accountCostToCompany.onChange}
                />
                <div
                  htmlFor="chkCccount_cost_to_company"
                  className="check-note-text"
                >
                  Include this product in Cost to Company report
                </div>
              </div>
            </div>
            <div className="col-xs-12">
              <div className="check-note">
                <input
                  id="chkDeduct_inventory"
                  type="checkbox"
                  className="note-check"
                  name="deduct_inventory"
                  checked={deductInventory.checked ? "checked" : false}
                  onChange={deductInventory.onChange}
                />
                <div htmlFor="chkDeduct_inventory" className="check-note-text">
                  Deduct inventory when it gets used in procedure
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
