import { connect } from "react-redux";
import { productPricingRegular } from "../../../../../../../../store/inventory/productPricingRegular";
import { useInit } from "../../hooks/useInit";

const { selectors } = productPricingRegular;

const mapStateToProps = (state) => ({
  forAllClinics: selectors.selectForAllClinics(state),
  clinics: selectors.selectClinics(state),
  getPerUnitPrice: selectors.selectPerUnitPrice(state),
  isInitialized: selectors.selectInitialized(state),
});

export const Init = connect(mapStateToProps)(({ initData, isInitialized }) => {
  useInit(initData, isInitialized);
  return null;
});
