/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-redeclare */
import React, { Component } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Scrollbars } from "react-custom-scrollbars";
import cx from "clsx";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import InboxSidebar from "../InboxSidebar.js";
import Loader from "../../Common/Loader.js";
import {
  fetchSMSNotificationsPopups,
  createReply,
  exportEmptyData,
} from "../../../Actions/Inbox/inboxAction.js";
import { fetchNotificationsPopupsMenu } from "../../../Actions/Dashboard/dashboardActions";
import {
  numberFormat,
  displayName,
  getCurrencySymbol,
  showFormattedDate,
  getTimeFormat,
} from "../../../Utils/services.js";
import { findBadWordsIn, unwrapOr } from "../../../utilities/general.js";
import classes from "./CreateSMS.module.scss";
import { tCommon } from "../../../i18n/useAppTranslation.js";
import { history } from "../../../history.js";
import { AlertBox } from "../../../shared/AlertBox/AlertBox.js";
import { uiNotification } from "../../../services/UINotificationService.js";
import { withCurrentUserQuery } from "../../../api/queries/useUserQuery.js";

const MESSAGE_MAX_LEN = 1000;

class CreateSMS extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    let inboxClinicId = localStorage.getItem("inboxClinicId")
      ? localStorage.getItem("inboxClinicId")
      : -1;
    this.state = {
      languageData: languageData.clients,
      globalLang: languageData.global,
      timezone: "",
      city: "",
      php_timezone: "",
      country_name: "",
      country_code: "",
      defaultCountry: "us",
      clinic_name: "",
      contact_no: "",
      address: "",
      email_special_instructions: "",
      sms_notifications_phone: "",
      smsPopupData: [],
      defaultImg: "/images/user.png",
      reply: "",
      tax: "",
      time_format: "",
      clinicData: {},
      userId: userData.user.id,
      id: this.props.match.params.id,
      page: 1,
      pagesize: 15,
      sortorder: "asc",
      term: "",
      hasMoreItems: true,
      next_page_url: "",
      loadMore: true,
      startFresh: true,
      showLoader: false,
      scopes: "business_hours",
      user_changed: false,
      timezoneList: [],
      countryList: [],
      notiEmailArr: [],
      multipleEmailClass: "setting-input-box notiEmailInput",
      contactClass: "setting-input-box",
      notiContactClass: "setting-input-box",
      clinicList: [],
      inboxClinicId: inboxClinicId,
      fallbackPatientName: "",
      last_visit: "",
    };
  }
  scrollbarRef = React.createRef();

  componentDidMount() {
    const patientId = this.props.match.params.id;
    const smsId = this.props.match.params.smsId;
    let languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      smsId: smsId != undefined ? smsId : undefined,
      SMS_Notifications_popup:
        languageData.dashboard["SMS_Notifications_popup"],
      SMS_Notifications_popup_IM_History:
        languageData.dashboard["SMS_Notifications_popup_IM_History"],
      edit_clinic_subheader: languageData.settings["edit_clinic_subheader"],
      edit_clinic_Clinic_name: languageData.settings["edit_clinic_Clinic_name"],
      edit_clinic_contact_no: languageData.settings["edit_clinic_contact_no"],
      edit_clinic_time_zone: languageData.settings["edit_clinic_time_zone"],
      edit_clinic_address: languageData.settings["edit_clinic_address"],
      edit_clinic_city_state: languageData.settings["edit_clinic_city_state"],
      edit_clinic_country: languageData.settings["edit_clinic_country"],
      edit_clinic_notif_email: languageData.settings["edit_clinic_notif_email"],
      edit_clinic_sms_notif_phone:
        languageData.settings["edit_clinic_sms_notif_phone"],
      edit_clinic_sms_multiple_emails:
        languageData.settings["edit_clinic_sms_multiple_emails"],
      edit_clinic_tax_settings:
        languageData.settings["edit_clinic_tax_settings"],
      edit_clinic_tax_rate: languageData.settings["edit_clinic_tax_rate"],
      edit_clinic_business_hours:
        languageData.settings["edit_clinic_business_hours"],
      edit_clinic_open_hours: languageData.settings["edit_clinic_open_hours"],
      edit_clinic_close_hours: languageData.settings["edit_clinic_close_hours"],
      edit_clinic_monday: languageData.settings["edit_clinic_monday"],
      edit_clinic_tuesday: languageData.settings["edit_clinic_tuesday"],
      edit_clinic_wednesday: languageData.settings["edit_clinic_wednesday"],
      edit_clinic_thursday: languageData.settings["edit_clinic_thursday"],
      edit_clinic_friday: languageData.settings["edit_clinic_friday"],
      edit_clinic_Saturday: languageData.settings["edit_clinic_Saturday"],
      edit_clinic_sunday: languageData.settings["edit_clinic_sunday"],
      edit_clinic_delete_button:
        languageData.settings["edit_clinic_delete_button"],
      clinic_delete_warning: languageData.settings["clinic_delete_warning"],
      yes_option: languageData.settings["yes_option"],
      no_option: languageData.settings["no_option"],
      delete_confirmation: languageData.global["delete_confirmation"],
      editUsers_CancelBtn: languageData.settings["editUsers_CancelBtn"],
    });
    let formData = {
      params: {
        mode: patientId ? "add" : "edit",
        id: patientId ? patientId : smsId,
      },
    };
    this.setState({ showLoader: true });
    this.props.fetchSMSNotificationsPopups(formData).then((res) => {
      this.setState({
        lastActivity: res?.data?.notification?.patient?.last_activity || "",
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    var objDiv = document.getElementById("main-chat-outer");
    if (objDiv) {
      objDiv.scrollTop =
        objDiv && objDiv.scrollHeight ? objDiv.scrollHeight : 0;
    }
    if (this.state.patientData) {
      var objDiv = document.getElementById("main-chat-outer-2");
      if (objDiv) {
        objDiv.scrollTop =
          objDiv && objDiv.scrollHeight ? objDiv.scrollHeight : 0;
      }
    }
    if (
      prevProps.smsPopupDataTime &&
      prevState.smsPopupData.length &&
      this.scrollbarRef
    ) {
      this.scrollbarRef.current.scrollToBottom();
    }
  }

  static getDerivedStateFromProps(props, state) {
    let returnState = {};
    if (props.showLoaderTime != state.showLoaderTime) {
      returnState.showLoaderTime = props.showLoaderTime;
      returnState.showLoader = false;
    }
    if (
      props.smsPopupData !== undefined &&
      props.smsPopupDataTime != state.smsPopupDataTime
    ) {
      returnState.smsPopupDataTime = props.smsPopupDataTime;
      returnState.clinicList =
        props.smsPopupData &&
        props.smsPopupData.data &&
        props.smsPopupData.data.clinics
          ? props.smsPopupData.data.clinics
          : [];
      returnState.showLoader = false;
      returnState.from_number = state.userChanged
        ? state.from_number
        : props.smsPopupData.data.notification.from_number;
      returnState.firstname = state.userChanged
        ? state.firstname
        : state.firstname == "" || state.firstname == null
        ? ""
        : props.smsPopupData.data.notification.patient.firstname;
      returnState.lastname = state.userChanged
        ? state.lastname
        : state.lastname == "" || state.lastname == null
        ? ""
        : props.smsPopupData.data.notification.patient.lastname;
      returnState.time_format = props.smsPopupData.data.time_format
        ? props.smsPopupData.data.time_format
        : "";
      returnState.smsPopupData = props.smsPopupData.data.all_notifications;
      returnState.patientData =
        props.smsPopupData.data.notification &&
        props.smsPopupData.data.notification.patient
          ? props.smsPopupData.data.notification.patient
          : {};
      const activity =
        props.smsPopupData?.data?.notification?.patient?.last_activity;
      returnState.last_visit = activity || state.last_visit;
      returnState.fallbackPatientName = unwrapOr(() => {
        return props.smsPopupData.data.notification.first_name
          ? `${props.smsPopupData.data.notification.first_name} ${props.smsPopupData.data.notification.last_name}`
          : props.smsPopupData.data.notification.from_number;
      }, null);
      props.exportEmptyData();
      props.fetchNotificationsPopupsMenu();
    }
    return returnState;
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value, userChanged: true });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const patientId = this.props.match.params.id;
    const smsId = this.props.match.params.smsId;

    const badWords = findBadWordsIn(this.state.reply || "");

    if (badWords.length > 0) {
      return uiNotification.error(tCommon("error.badWords"));
    }

    let formData = {
      id: patientId ? 0 : smsId,
      reply: this.state.reply,
      mode: patientId ? "add" : "edit",
    };

    if (patientId) {
      formData.patient_id = patientId;
    }

    this.setState({ showLoader: true });
    this.props.createReply(formData, this.props.match.params.id);
    this.state.reply = "";
    if (typeof this.refs?.reply === "object" && this.refs?.reply !== null) {
      this.refs.reply.value = "";
    }
  };

  validateForm() {
    return this.state.reply.length > 0;
  }

  render() {
    const isDoNotSms = Boolean(this.state.patientData?.do_not_sms);
    this.state.languageData &&
    this.state.languageData.clientprofile_del_client_message
      ? this.state.languageData.clientprofile_del_client_message
      : "";
    let address = [];

    let height = window.innerHeight - 215;

    if (
      this.state.patientData !== undefined &&
      this.state.patientData.address_line_1
    ) {
      address.push(this.state.patientData.address_line_1);
    }

    if (
      this.state.patientData !== undefined &&
      this.state.patientData.address_line_2
    ) {
      address.push(this.state.patientData.address_line_2);
    }

    if (this.state.patientData !== undefined && this.state.patientData.city) {
      address.push(this.state.patientData.city);
    }

    if (this.state.patientData !== undefined && this.state.patientData.state) {
      address.push(this.state.patientData.state);
    }

    if (
      this.state.patientData !== undefined &&
      this.state.patientData.pincode
    ) {
      address.push(this.state.patientData.pincode);
    }

    if (
      this.state.patientData !== undefined &&
      this.state.patientData.country
    ) {
      address.push(this.state.patientData.country);
    }

    const patientName = displayName(this.state.patientData);

    const { patientData } = this.state;
    let membershipBenefits = patientData?.membership_benefits_this_year;

    let membership_saving =
      getCurrencySymbol() + (membershipBenefits || "0.00");

    let membership_type =
      patientData?.is_monthly_membership == 1 ? "Registered" : "Not Registered";

    let timeFormat = getTimeFormat();
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <InboxSidebar
            clinicList={this.state.clinicList}
            inboxClinicId={this.state.inboxClinicId}
          />

          <div className="memberWalletOuter business-section">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                {patientName || this.state.fallbackPatientName}
                <div className="memberRightActions">
                  {this.state?.patientData?.phoneNumber || ""}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-7">
                <div className="setting-setion m-b-15">
                  <div
                    className={cx("main-chat-outer", {
                      "p-b-60": isDoNotSms,
                    })}
                    id="main-chat-outer"
                    style={{ height: `${height}px` }}
                  >
                    <Scrollbars
                      ref={this.scrollbarRef}
                      className="full-width"
                      renderTrackHorizontal={(props) => (
                        <div
                          {...props}
                          style={{ display: "none" }}
                          className="track-horizontal"
                        />
                      )}
                    >
                      {this.state.smsPopupData !== undefined &&
                        this.state.smsPopupData.map((obj, idx) => {
                          let displayDate = "";
                          let todaysDate = new Date();
                          let getTodaysDate = todaysDate.getDate();

                          if (obj.created === getTodaysDate) {
                            displayDate = getTodaysDate;
                          } else {
                            displayDate = obj.created;
                          }

                          let displayMessage = "";
                          if (obj.message_type === "message") {
                            displayMessage = obj.message;
                          } else {
                            displayMessage = "";
                          }

                          let displayRightMessage = "";
                          if (obj.message_type === "reply") {
                            displayRightMessage = obj.message;
                          } else {
                            displayRightMessage = "";
                          }

                          var imgUrl =
                            obj.patient === null
                              ? "/images/user.png"
                              : obj.patient.user_image_url === ""
                              ? " "
                              : obj.patient.user_image_url;
                          var leftdivStyle = {
                            backgroundImage: "url(" + imgUrl + ")",
                            backgroundColor: "#dddddd",
                          };

                          var rimgUrl =
                            obj.user === null
                              ? "/images/user.png"
                              : obj.user.user_image_url === ""
                              ? " "
                              : obj.user.user_image_url;
                          var rightdivStyle = {
                            backgroundImage: "url(" + rimgUrl + ")",
                            backgroundColor: "#dddddd",
                          };

                          let name =
                            obj.patient !== null
                              ? obj.patient.firstname.charAt(0).toUpperCase()
                              : "";
                          let lname =
                            obj.patient !== null
                              ? obj.patient.lastname.charAt(0).toUpperCase()
                              : "";

                          let uname =
                            obj.user !== null
                              ? obj.user.firstname.charAt(0).toUpperCase()
                              : "";
                          let ulname =
                            obj.user !== null
                              ? obj.user.lastname.charAt(0).toUpperCase()
                              : "";

                          const patientFallbackName = obj.first_name
                            ? `${obj.first_name} ${obj.last_name}`
                            : obj.from_number;

                          return (
                            <div key={idx}>
                              <div
                                className={
                                  getTodaysDate !== obj.created
                                    ? "date-divider"
                                    : "no-display"
                                }
                              >
                                <span className="chat-date">
                                  {showFormattedDate(
                                    displayDate,
                                    false,
                                    this.props.currentUserQuery?.data
                                      ?.previewDateFormat,
                                  )}
                                </span>
                              </div>
                              <div
                                className={
                                  obj.message_type === "message"
                                    ? "left-chat-outer chat-div"
                                    : "no-display"
                                }
                                data-notty-id={146}
                              >
                                <div
                                  className="sms-user-picture"
                                  style={leftdivStyle}
                                />
                                {obj.patient !== null &&
                                obj.patient.user_image_url === "" ? (
                                  <div className="sms-user-picture">
                                    {name}
                                    {lname}
                                  </div>
                                ) : (
                                  <div
                                    className="sms-user-picture"
                                    style={leftdivStyle}
                                  />
                                )}

                                <div className="chat-name ">
                                  {obj.patient === null
                                    ? patientFallbackName
                                    : obj.patient.firstname === ""
                                    ? obj.from_number
                                    : obj.patient.firstname
                                        .charAt(0)
                                        .toUpperCase() +
                                      obj.patient.firstname.slice(1) +
                                      " " +
                                      obj.patient.lastname
                                        .charAt(0)
                                        .toUpperCase() +
                                      obj.patient.lastname.slice(1) +
                                      ","}{" "}
                                  <div className="chat-time">
                                    {this.state.time_format == 12
                                      ? showFormattedDate(
                                          obj.created,
                                          JSON.stringify(obj.created),
                                          timeFormat,
                                        )
                                      : showFormattedDate(
                                          obj.created,
                                          JSON.stringify(obj.created),
                                          timeFormat,
                                        )}
                                  </div>
                                </div>
                                <div className="chat-txt-gray">
                                  {displayMessage}
                                </div>
                              </div>

                              <div
                                className={
                                  obj.message_type === "reply"
                                    ? "right-chat-outer chat-div"
                                    : "no-display"
                                }
                                data-notty-id={147}
                              >
                                {obj.user !== null &&
                                obj.user.user_image_url === "" ? (
                                  <div className="sms-user-picture">
                                    {uname}
                                    {ulname}
                                  </div>
                                ) : (
                                  <div
                                    className="sms-user-picture"
                                    style={rightdivStyle}
                                  />
                                )}

                                <div className="chat-name ">
                                  {obj.user === null
                                    ? obj.to_number
                                    : obj.user.firstname === ""
                                    ? obj.to_number
                                    : obj.user.firstname
                                        .charAt(0)
                                        .toUpperCase() +
                                      obj.user.firstname.slice(1) +
                                      " " +
                                      obj.user.lastname
                                        .charAt(0)
                                        .toUpperCase() +
                                      obj.user.lastname.slice(1) +
                                      ","}{" "}
                                  <div className="chat-time">
                                    {this.state.time_format == 12
                                      ? showFormattedDate(
                                          obj.created,
                                          JSON.stringify(obj.created),
                                          timeFormat,
                                        )
                                      : showFormattedDate(
                                          obj.created,
                                          JSON.stringify(obj.created),
                                          timeFormat,
                                        )}
                                  </div>
                                </div>
                                <div className="chat-txt-gray">
                                  {displayRightMessage}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </Scrollbars>

                    {isDoNotSms ? (
                      <div className="type-message p-0">
                        <AlertBox size="small">
                          You cannot send a message because of patient DND
                          settings
                        </AlertBox>
                      </div>
                    ) : (
                      <div className="type-message">
                        <form
                          id="reply"
                          name="reply-form"
                          action="#"
                          method="post"
                          onSubmit={this.handleSubmit}
                          className="h-auto"
                        >
                          <TextareaAutosize
                            maxRows={15}
                            maxLength={MESSAGE_MAX_LEN}
                            className="reply-box"
                            placeholder={
                              this.state.globalLang.label_type_message
                            }
                            value={this.state.reply}
                            onChange={this.handleInputChange}
                            name="reply"
                          />
                          <input
                            value="Send"
                            type="submit"
                            className={
                              !this.validateForm()
                                ? "header-select-btn reply-btn disable"
                                : "header-select-btn reply-btn"
                            }
                            disabled={!this.validateForm()}
                          />
                        </form>
                        <div className={classes.msgLimit}>{`${
                          (this.state.reply || "").length
                        } / ${MESSAGE_MAX_LEN}`}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="patient-left merge-info">
                  <div className="merge-setion">
                    <div className="section-title header-blue">
                      {this.state.patientData?.id ? (
                        <a
                          className={classes.patientName}
                          href={`/clients/profile/${this.state.patientData.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {patientName || this.state.fallbackPatientName}
                        </a>
                      ) : (
                        <span className={classes.patientName}>
                          {patientName || this.state.fallbackPatientName}
                        </span>
                      )}
                    </div>
                    {patientName == "" && (
                      <div className="no-client-found">
                        {this.state.globalLang.client_not_fount}
                      </div>
                    )}

                    {patientName && (
                      <div
                        id="main-chat-outer-2"
                        style={{ height: `${height - 50}px` }}
                      >
                        <Scrollbars
                          style={{ minHeight: `${height - 51}px` }}
                          className="full-width"
                          renderTrackHorizontal={(props) => (
                            <div
                              {...props}
                              style={{ display: "none" }}
                              className="track-horizontal"
                            />
                          )}
                        >
                          <div className="pro-pic-outer clientChatPicOuter">
                            <img
                              alt=""
                              src={
                                this.state.patientData !== undefined &&
                                this.state.patientData.user_image
                                  ? this.state.patientData.user_image
                                  : "/images/user.png"
                              }
                            />
                            <div className="profile-img-info">
                              <h5 className="break-word-content clientChatInfo">
                                <i className="fa fa-envelope"></i>{" "}
                                {this.state.patientData !== undefined &&
                                this.state.patientData.email !== ""
                                  ? this.state.patientData.email
                                  : ""}
                              </h5>
                              <h5 className="break-word-content clientChatInfo m-b-0">
                                <i className="fa fa-map-marker"></i>
                                {this.state.patientData.address_line_1 && (
                                  <div>
                                    {this.state.patientData.address_line_1},{" "}
                                    <br />
                                  </div>
                                )}
                                {this.state.patientData.address_line_2 && (
                                  <div>
                                    {this.state.patientData.address_line_2},{" "}
                                    <br />
                                  </div>
                                )}
                                {this.state.patientData.city && (
                                  <span>{this.state.patientData.city},</span>
                                )}{" "}
                                {this.state.patientData.state && (
                                  <span>{this.state.patientData.state}</span>
                                )}{" "}
                                {this.state.patientData.pincode && (
                                  <span>{this.state.patientData.pincode}</span>
                                )}
                              </h5>
                            </div>
                          </div>

                          <div className="about-form ChatInfoRowContainer">
                            <div className="ChatInfoRow">
                              <label>
                                {this.state.languageData.clientprofile_birthday}{" "}
                                :
                              </label>{" "}
                              <span>
                                {this.state.showLoader === false &&
                                this.state?.patientData?.date_of_birth
                                  ? showFormattedDate(
                                      this.state.patientData.date_of_birth,
                                      false,
                                      this.props.currentUserQuery?.data
                                        ?.previewDateFormat,
                                    )
                                  : ""}
                              </span>
                            </div>
                            <div className="ChatInfoRow">
                              <label>
                                {
                                  this.state.languageData
                                    .clientprofile_emergency_name
                                }{" "}
                                :
                              </label>{" "}
                              <span>
                                {this.state.patientData !== undefined &&
                                this.state.patientData
                                  .emergency_contact_name !== ""
                                  ? this.state.patientData
                                      .emergency_contact_name
                                  : ""}
                              </span>
                            </div>
                            <div className="ChatInfoRow">
                              <label>
                                {
                                  this.state.languageData
                                    .clientprofile_emergency_number
                                }{" "}
                                :
                              </label>{" "}
                              <span>
                                {this.state.patientData !== undefined &&
                                this.state.patientData
                                  .emergency_contact_number !== ""
                                  ? this.state.patientData
                                      .emergency_contact_number
                                  : ""}
                              </span>
                            </div>
                            <div className="ChatInfoRow">
                              <label>
                                {
                                  this.state.languageData
                                    .clientprofile_last_visit
                                }{" "}
                                :
                              </label>{" "}
                              <span>
                                {" "}
                                {this.state.last_visit
                                  ? showFormattedDate(
                                      this.state.last_visit,
                                      false,
                                      this.props.currentUserQuery?.data
                                        ?.previewDateFormat,
                                    )
                                  : "Never"}
                              </span>
                            </div>
                            <div className="ChatInfoRow">
                              <label>
                                {
                                  this.state.languageData
                                    .clientprofile_membership_program
                                }{" "}
                                :
                              </label>{" "}
                              <span>{membership_type} </span>
                            </div>
                            {membership_type == "Registered" && (
                              <div className="ChatInfoRow">
                                <label>
                                  {
                                    this.state.globalLang
                                      .label_membership_savings
                                  }{" "}
                                  :{" "}
                                </label>{" "}
                                <span>{membership_saving}</span>
                              </div>
                            )}
                            <div className="ChatInfoRow">
                              <label>
                                {
                                  this.state.languageData
                                    .clientprofile_sale_relation
                                }{" "}
                                :
                              </label>{" "}
                              <span>
                                {" "}
                                {this.state?.patientData
                                  ?.total_sale_relationship > 0
                                  ? numberFormat(
                                      this.state.patientData
                                        .total_sale_relationship,
                                      "currency",
                                    )
                                  : numberFormat(0, "currency")}
                              </span>
                            </div>
                          </div>
                        </Scrollbars>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};

  uiNotification.clear();
  if (state.InboxReducer.action === "SELECTED_SMS_POPUPS") {
    if (state.InboxReducer.data.status != 200) {
      if (state.InboxReducer.data.message === "patient_is_blacklisted") {
        setTimeout(() => {
          uiNotification.error("Phone number is blacklisted");
        }, 0);
        history.replace("/inbox/sms/all");
      } else {
        uiNotification.error(
          languageData.global[state.InboxReducer.data.message],
        );
        returnState.showLoaderTime = new Date();
      }
    } else {
      returnState.smsPopupData = state.InboxReducer.data;
      returnState.smsPopupDataTime = new Date();
    }
  }
  if (state.InboxReducer.action === "CREATE_REPLY_INBOX") {
    if (state.InboxReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.InboxReducer.data.message],
      );
      // returnState.showLoader = false
      returnState.showLoaderTime = new Date();
    } else {
      returnState.smsPopupData = state.InboxReducer.data;
      returnState.smsPopupDataTime = new Date();
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSMSNotificationsPopups: fetchSMSNotificationsPopups,
      createReply: createReply,
      fetchNotificationsPopupsMenu: fetchNotificationsPopupsMenu,
      exportEmptyData: exportEmptyData,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withCurrentUserQuery(CreateSMS)));
