/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable valid-typeof */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import validator from "validator";
import Sidebar from "../InventorySidebar";
import OrderStockPopup from "./OrderStockPopup.js";
import StockTransferPopup from "./StockTransferPopup.js";
import DetailedLogs from "./DetailProductLogs.js";
import { ModalInventoryLogs } from "./components/ModalInventoryLogs/ModalInventoryLogs.js";
import { getPrecision, getRangeUpTo } from "../../../utilities/general.js";
import {
  fetchInventoryData,
  getProductDefaultData,
  isProductNameAvailable,
  addProduct,
  updateProduct,
  createCategory,
  deleteProduct,
  emptyInventoryReducer,
  addProductStock,
  adjustProductStock,
  makeProductInactivate,
  saveReorderSettings,
  updateStockAlert,
  updateCTC,
  fetchOrderDetails,
  saveOrderInfo,
  viewStockTransfer,
  createStockTransfer,
  updateStockAlertForOthers,
  showDetailedLogs,
} from "../../../Actions/Inventory/inventoryActions.js";
import {
  checkIfPermissionAllowed,
  numberFormat,
  formatBytes,
  isNumber,
  showFormattedDate,
  isPositiveNumber,
  toggleBodyScroll,
  dateFormatPicker,
  displayName,
  decimalPlaces,
  showWithoutRounding,
  roundOffNum,
  autoScrolling,
} from "../../../Utils/services.js";
import { uiNotification } from "../../../services/UINotificationService.js";
import { productPricingRegular } from "../../../store/inventory/productPricingRegular/index.js";
import { productPricingMember } from "../../../store/inventory/productPricingMember/index.js";
import { dispatch } from "../../../store/store.js";
import {
  isPricesChanged as isRegularPricesChanged,
  validateChangedPricesForRequest,
} from "../../../store/inventory/productPricingRegular/utilities.js";
import { history } from "../../../history.js";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal.js";
import { PricingRegular } from "./components/Pricing/PricingRegular/PricingRegular.js";
import { PricingMember } from "./components/Pricing/PricingMember/PricingMember.js";
import { http } from "../../../services/HttpService.js";
import { UsedInPackagesAlert } from "./components/UsedInPackagesAlert/index.js";
import {
  RouterPrompt,
  SKIP_QUERY_KEY,
} from "../../../widgets/RouterPrompt/RouterPrompt.js";
import { withCurrentUserQuery } from "../../../api/queries/useUserQuery.js";
import { API_DATE_FORMAT } from "../../../consts/api.js";
import { isPricesChanged as isMemberPricesChanged } from "../../../store/inventory/productPricingMember/utilities.js";
import { UnsavedChangesMessage } from "./components/Pricing/shared/UnsavedChangesMessage/index.js";
import { pipe } from "../../../utilities/fp.js";
import { withSave as withRegularPricesSave } from "./components/Pricing/PricingRegular/hocs/withSave.js";
import { withSave as withMemberPricesSave } from "./components/Pricing/PricingMember/hocs/withSave.js";
import { Init as InitRegular } from "./components/Pricing/PricingRegular/shared/Init/index.js";
import { Info } from "./components/Info/index.js";

const defaultInventoryPackage = () => {
  return {
    inventoryClinic: 0,
    inventoryDate: new Date(),
    inventoryBatch: "",
    inventoryStock: "",
    inventoryAlert: "",
    inventoryId: "",
  };
};
const defaultInventoryErrorPackage = () => {
  return {
    inventoryClinic: false,
    inventoryDate: false,
    inventoryBatch: false,
    inventoryStock: false,
    inventoryAlert: false,
  };
};

const formatType = "YYYY-MM-DD";
const dateFormat = (date) => {
  return moment(date).format(formatType);
};

const initAddStockFields = () => {
  return {
    clinic_id: 0,
    clinic_idClass: "simpleSelect",
    expiry_date: moment(new Date()).add(1, "years").toDate(),
    expiry_dateClass: "simpleInput",
    batch_id: "",
    batch_idClass: "simpleInput",
    available_stock: "",
    available_stockClass: "simpleInput",
    stock_alert: "",
    stock_alertClass: "simpleInput",
    cost_to_company: "",
    cost_to_companyClass: "simpleInput",
    id: "",
  };
};

const initAdjustStockFields = () => {
  return {
    available_stock: "",
    available_stockClass: "simpleInput",
    id: "",
    cost_to_company: "",
    cost_to_companyClass: "simpleInput",
  };
};

const initRemoveStockFields = () => {
  return {
    available_stock: "",
    available_stockClass: "simpleInput",
    reason: "",
    reasonClass: "simpleSelect",
    reason_other: "",
    reason_otherClass: "simpleInput",
    id: "",
    stock_limit: "0",
  };
};

const initStockListOthers = () => {
  return {
    clinic_id: "",
    clinic_name: "",
    available_stock: "",
    available_stockClass: "tableInput",
    stock_alert: "",
    stock_alertClass: "tableInput",
    id: "",
    cost_to_company: "",
    cost_to_companyClass: "tableInput",
  };
};

const TABS = {
  info: "info",
  pricingRegular: "pricingRegular",
  pricingMember: "pricingMember",
  traceability: "restock",
  tax: "tax",
  purchaseOrder: "suppliers",
};

function productTypeLabelToInventoryType(productTypeLabel) {
  return {
    injectable: "services",
    retail: "retail",
    medical_supplies: "medical_supplies",
  }[productTypeLabel];
}

class EditInventory extends Component {
  constructor(props) {
    super(props);
    let languageData = JSON.parse(localStorage.getItem("languageData"));
    let currencySymbol = localStorage.getItem("currencySymbol");
    const queryParams = queryString.parse(window.location.search);

    this.state = {
      cloneId: parseInt(queryParams.clone),
      isInventoryLogModalOpen: false,
      langData: languageData,
      productId: 0,
      categoryId: 0,
      is_product_active: true,
      addCategoryPop: false,
      is_custom_tax_rule_enabled: false,
      is_supplier_info_enabled: false,
      add_category_status: true,
      is_inventory_enabled: false,
      is_stock_inventory_enabled: false,
      is_custom_tax_rule_enabled_category: false,
      clinics: [],
      suppliers: [],
      categories: [],
      startsAtUnitArr: [],
      inventoryArr: [defaultInventoryPackage()],
      inventoryArrError: [defaultInventoryErrorPackage()],
      keywords: [],
      productDefaultData: {},
      stock_alert: "0",
      available_stock: "0",
      product_sku: "",
      supplier_name: "",
      supplier_name_error: false,
      reference_number: "",
      reference_number_error: false,
      supplier_email_id: "",
      supplier_email_id_error: false,
      supplier_phone_number: "",
      supplier_phone_number_eror: false,
      productNameAvailable: false,
      product_name: "",
      cost_to_company: "",
      product_ratio: "",
      showLoader: false,
      product_ratio_enabled: false,
      productRatioError: false,
      globalLang: languageData.global,
      inventoryLang: languageData.inventory,
      product_type: "others",
      product_category: 0,
      injectable_type: 0,
      product_service: 0,
      injectableTypeArr: [
        {
          key: 1,
          name: "Botulinum Toxin",
        },
        {
          key: 2,
          name: "Filler",
        },
        {
          key: 3,
          name: "Fat",
        },
        {
          key: 7,
          name: "Weight Loss",
        },
        {
          key: 8,
          name: "Vitamin Therapy",
        },
        {
          key: 9,
          name: "Peptide Therapy",
        },
        {
          key: 10,
          name: "Pain Management",
        },
        {
          key: 11,
          name: "Aesthetic Enhancements",
        },
        {
          key: 12,
          name: "Anti-Aging Therapy",
        },
        {
          key: 13,
          name: "Hormone Therapy",
        },
      ],
      surgeryTypeArr: [
        { key: 5, value: "Face" },
        { key: 6, value: "Body" },
      ],
      surgery_type: 0,
      productTypeArr: [
        { id: "injectable", value: "Injectable" },
        { id: "coolsculpting", value: "Body Contouring" },
        { id: "chemicalpeels", value: "Chemical Peels" },
        { id: "microneedling", value: "Microneedling" },
        { id: "threads", value: "Threads" },
        { id: "microblading", value: "Microblading" },
        { id: "cellfina", value: "Cellfina" },
        { id: "laser", value: "Laser" },
        { id: "massage", value: "Massage" },
        { id: "surgery", value: "Surgery" },
        { id: "consultation", value: "Consultation" },
        { id: "facial", value: "Facial" },
        { id: "iv", value: "IV" },
        { id: "waxing", value: "Waxing" },
        { id: "weightloss", value: "Weight Loss" },
        { id: "hairgrowth", value: "Hair Growth" },
        { id: "hormonetherapy", value: "Hormone Therapy" },
      ],
      productService: [
        { id: "Aesthetic Service", value: "Aesthetic Service" },
        { id: "Product Sale", value: "Product Sale" },
        { id: "Other", value: "Other" },
      ],
      injectableUnitType: [
        { id: 1, value: "Units" },
        { id: 2, value: "Speywood" },
        { id: 3, value: "Vial" },
        { id: 4, value: "Syringe" },
        { id: 10, value: "ML" },
        { id: 12, value: "MG" },
      ],
      coolsculptingUnitType: [
        { id: 5, value: "Cycles" },
        { id: 10, value: "ML" },
        { id: 11, value: "Treatment" },
      ],
      chemicalpeelsUnitType: [
        { id: 3, value: "Vial" },
        { id: 4, value: "Syringe" },
        { id: 6, value: "Packets" },
        { id: 10, value: "ML" },
        { id: 11, value: "Treatment" },
      ],
      microneedlingUnitType: [
        { id: 1, value: "Units" },
        { id: 7, value: "Tip" },
        { id: 10, value: "ML" },
      ],
      threadsUnitType: [
        { id: 8, value: "Thread" },
        { id: 10, value: "ML" },
      ],
      microbladingUnitType: [
        { id: 3, value: "Vial" },
        { id: 10, value: "ML" },
        { id: 11, value: "Treatment" },
      ],
      cellfinaUnitType: [
        { id: 9, value: "Kit" },
        { id: 10, value: "ML" },
      ],
      treatmentUnitType: [
        { id: 11, value: "Treatment" },
        { id: 12, value: "MG" },
        { id: 10, value: "ML" },
      ],
      weightAndLossUnitType: [
        { id: 11, value: "Treatment" },
        { id: 12, value: "MG" },
        { id: 10, value: "ML" },
      ],
      unitsByArr: [
        "0.1",
        "0.2",
        "0.3",
        "0.4",
        "0.5",
        "0.6",
        "0.7",
        "0.8",
        "0.9",
        "1",
      ],
      defaultUnitType: [],
      firstClinicName: "",
      is_system_product: 1,

      addCatCategoryName: "",

      loadingSpinner: false,
      inventory_type: "retail",
      activeMode: TABS.info,
      stockList: [],
      stockListOthers: [],
      addStockFields: initAddStockFields(),
      isShowAddStockModal: false,
      adjustStockFelds: initAdjustStockFields(),
      isShowAdjustStockModal: false,
      removeStockFelds: initRemoveStockFields(),
      stockActionIndex: -1,
      updatedStockList: {},
      isUpdateStockList: 0,
      isUpdateStockListOthers: 0,
      removeReasonList: [
        {
          slug: "Damage",
          label: "damage",
        },
        {
          slug: "Sample",
          label: "sample",
        },
        {
          slug: "Shrinkage",
          label: "shrinkage",
        },
        {
          slug: "Expired",
          label: "expired",
        },
        {
          slug: "Other",
          label: "other",
        },
      ],
      billForWholeUnit: 0,
      contactClass: "newInputField",
      isRender: false,
      showLogs: false,
      isProductInactive: false,
      product_reorder_setting: {},

      product_per_clinic: false,
      product_type_db: "",
      inventory_type_db: "",
      is_add_inventory_stock_other: true,
      reorder_setting_per_clinic: [],
      productReorderSetting: null,
      currencySymbol: currencySymbol,

      isManageStock: 0,
      showEditStockModal: false,
      editInventoryID: 0,
      editStockAlert: "",
      editStockAlertClass: "simpleInput",

      paymentTermsClass: "newtextareaField textarea-height-50",
      md_id: "",
      md_idClass: "simpleSelect",
      custom_md: "",
      custom_mdClass: "simpleInput",
      mdList: [],

      showEditCTCModal: false,
      editCTCVal: "",
      editCTCClass: "simpleInput",
      payment_term_type: "last_4_digits_of_cc",
      payment_term_typeClass: "simpleSelect",
      last_four_value: "",
      last_four_valueClass: "simpleInput",
      showBatches: false,
      showOrderStockPopup: false,
      clinicStockList: [],
      clinicExpiredStockList: [],
      modalPage: 1,
      detailedProductLogs: [],
      modal_next_page_url: "",
      isDownload: false,

      aspire_product_groups: [],
      selected_aspire_group: 0,
      is_evolus_product: false,

      priceErrors: null,
      redirectProductId: undefined,
      showPricesConfirm: false,
      usedInPackages: {
        charting: [],
        discount: [],
      },
      showConfirmSaveWithUnsaved: false,
    };

    this.product_type = React.createRef();
    this.product_category = React.createRef();
    this.product_name = React.createRef();
    this.cost_to_company = React.createRef();

    window.onscroll = () => {
      return false;
    };
  }

  componentWillUnmount() {
    dispatch(productPricingRegular.actions.setInitialized(false));
    dispatch(productPricingMember.actions.setInitialized(false));
    dispatch(productPricingMember.actions.setDefaultMembershipAfterInit(true));
  }

  showSpinner = () => {
    this.setState({ loadingSpinner: true });
    localStorage.setItem("loadingSpinner", false);
  };

  showLoaderFunc = () => {
    this.setState({ showLoader: true });
    localStorage.setItem("showLoader", false);
  };

  hideLoaderFunc = () => {
    this.setState({ showLoader: false });
  };

  makeActive = (mode) => {
    let activeMode = mode;
    if (this.state.isManageStock === 1) {
      activeMode = TABS.traceability;
    }
    this.setState({ activeMode: activeMode });
  };

  handleClick = (e) => {
    if (
      this.refDatePickerContainer &&
      !this.refDatePickerContainer.contains(e.target)
    ) {
      this.refDatePicker.setOpen(false);
      this.setState({ showDatePicker: false });
    }
  };

  componentDidMount() {
    toggleBodyScroll(false);
    window.onscroll = () => {
      return false;
    };
    const productId = this.props.match.params.id
      ? this.props.match.params.id
      : 0;
    const isManageStockParam = this.props.match.params.isManageStock
      ? this.props.match.params.isManageStock
      : 0;
    let isRender = false;
    if (!productId) {
      isRender = true;
    }

    const isPricingTab =
      queryString.parse(window.location.search)?.tab === "pricing";

    let activeMode = TABS.info;
    let isManageStock = 0;
    if (
      productId > 0 &&
      checkIfPermissionAllowed("edit-product") === false &&
      checkIfPermissionAllowed("manage-stocks") === true &&
      isManageStockParam == 1
    ) {
      activeMode = TABS.traceability;
      isManageStock = 1;
    }
    this.setState({
      productId: productId,
      isRender: isRender,
      isManageStock: isManageStock,
      activeMode: activeMode,
    });
    this.fetchProduct(true).then(() => {
      if (isPricingTab) {
        this.makeActive(TABS.pricingMember);
      }
    });
  }

  fetchProductForClone = async () => {
    this.showLoaderFunc();
    return this.props
      .getProductDefaultData(this.state.cloneId)
      .then((response) => {
        const { data } = response;
        const productData = data.product || {};

        this.setState({
          cloneInitData: data,
          inventory_type: productTypeLabelToInventoryType(
            productData.product_type_label,
          ),
          is_evolus_product: productData.is_evolus_product,
          product_name: "",
          product_category: productData.category_id,
          product_type: productData.product_type,
          product_sku: productData.product_sku,
          cost_to_company: productData.cost_to_company,
          selected_aspire_group: data.product.aspire_products.shift().id,
          product_image: productData.product_image,
          product_image_src: productData.product_image_url,
          product_description: productData.product_description,
          unit_type: productData.toxin_type,
          injectable_type: productData.type,
          surgery_type: productData.type,
          count_units_by: productData.count_units_by,
          start_at_unit: productData.start_at_unit,
          startsAtUnitArr: productData.count_units_by
            ? getRangeUpTo(Number(productData.count_units_by), 2)
            : [],
          product_ratio_enabled: productData.product_ratio_enabled,
          product_ratio: productData.product_ratio_enabled
            ? productData.ratio
            : "",
          keywords: productData.keywords ? productData.keywords.split(",") : [],
          billForWholeUnit: productData.bill_for_whole_unit
            ? productData.bill_for_whole_unit
            : 0,
          account_cost_to_company:
            productData.account_medical_supplies_in_reports
              ? productData.account_medical_supplies_in_reports
              : 0,
          deduct_inventory: productData.is_autodeduct_enabled
            ? productData.is_autodeduct_enabled
            : 0,
        });
      })
      .finally(() => {
        this.hideLoaderFunc();
      });
  };

  fetchProduct = async () => {
    const productId = this.props.match.params.id
      ? this.props.match.params.id
      : 0;

    this.showLoaderFunc();

    return this.props
      .getProductDefaultData(productId)
      .then((response) => {
        const { data } = response;

        let returnState = {
          initData: data,
        };

        returnState.showReorderPop = false;
        returnState.productDefaultData = data;
        returnState.note = "";

        let allClinics = data.clinics || [];
        let productData = data.product || undefined;

        if (allClinics.length) {
          allClinics.map((obj, idx) => {
            if (idx === 0) {
              returnState.firstClinicName = obj.clinic_name;
            }
            returnState["reorder_automatically_" + obj.id] =
              obj.reorder_automatically;
            returnState["service-stock-alert-" + obj.id] = 0;
            returnState["customTax-" + obj.id] =
              obj.product_clinic_tax && obj.product_clinic_tax.tax_percentage
                ? obj.product_clinic_tax.tax_percentage.toFixed(4)
                : "0.0000";
          });
        }

        let clinic_wise_inventory = [];

        if (data.clinic_wise_inventory?.length > 0) {
          data.clinic_wise_inventory.map((data) => {
            let clinics = {};
            clinics.id = data.id;
            clinics.clinic_name = data.clinic_name;
            clinics.inventory = data.inventory;
            clinics.expired_inventory = data.expired_inventory;
            clinics.total_units = data.total_units;
            clinics.stock_alert = data.clinic_stock_alert
              ? data.clinic_stock_alert.stock_alert
              : 0;
            clinic_wise_inventory.push(clinics);
          });
        }

        let prevInventoryArr = this.state.inventoryArr;

        prevInventoryArr[0]["inventoryClinic"] = 0;
        returnState.inventoryArr = prevInventoryArr;
        returnState.clinics = data.clinics;

        returnState.clinic_wise_inventory =
          clinic_wise_inventory && clinic_wise_inventory.length > 0
            ? clinic_wise_inventory
            : data.clinics;

        returnState.suppliers = data.suppliers || [];

        returnState.categories = data.categories;
        returnState.isRender = true;
        returnState.mdList = data.md_list || [];

        if (data.aspire_product_groups) {
          returnState.aspire_product_groups = data.aspire_product_groups;
        }

        if (data.product?.aspire_products?.length) {
          returnState.selected_aspire_group =
            data.product.aspire_products.shift().id;
        }

        let stockListOthers = [];

        if (productData) {
          returnState.is_evolus_product = productData.is_evolus_product;
          returnState.is_system_product = productData.is_system_product;
          returnState.product_service = productData.product_service
            ? productData.product_service
            : "";
          returnState.is_product_active = productData.is_product_active
            ? true
            : false;
          returnState.product_category = productData.category_id;
          returnState.cost_to_company = productData.cost_to_company;
          returnState.product_sku = productData.product_sku
            ? productData.product_sku
            : "";
          returnState.count_units_by = productData.count_units_by;
          returnState.product_image = productData.product_image;
          returnState.product_image_src = productData.product_image_url;
          returnState.product_description = productData.product_description
            ? productData.product_description
            : "";
          returnState.start_at_unit = productData.start_at_unit;
          returnState.product_type = productData.product_type;
          returnState.is_custom_tax_rule_enabled = productData.is_tax_enabled
            ? true
            : false;
          returnState.is_supplier_info_enabled =
            productData.is_supplier_enabled;
          returnState.keywords = productData.keywords
            ? productData.keywords.split(",")
            : [];
          returnState.product_name = this.state.cloneId
            ? ""
            : productData.product_name;
          returnState.unit_type = productData.toxin_type;
          returnState.injectable_type = productData.type
            ? productData.type
            : "";
          returnState.surgery_type = productData.type ? productData.type : "";
          returnState.account_cost_to_company =
            productData.account_medical_supplies_in_reports
              ? productData.account_medical_supplies_in_reports
              : 0;
          returnState.deduct_inventory = productData.is_autodeduct_enabled
            ? productData.is_autodeduct_enabled
            : 0;
          returnState.billForWholeUnit = productData.bill_for_whole_unit
            ? productData.bill_for_whole_unit
            : 0;

          returnState.inventory_type = productTypeLabelToInventoryType(
            productData.product_type_label,
          );

          returnState.product_ratio_enabled = productData.product_ratio_enabled;

          if (productData.product_ratio_enabled) {
            returnState.product_ratio = productData.ratio;
          }

          if (productData.product_supplier) {
            returnState.supplier_name =
              productData.product_supplier.supplier_name;
            returnState.supplier_email_id =
              productData.product_supplier.supplier_email;
            returnState.supplier_phone_number =
              productData.product_supplier.supplier_phone;
            returnState.reference_number =
              productData.product_supplier.refrence_number;
          }

          returnState.is_stock_inventory_enabled =
            productData.is_stock_inventory_enabled ? true : false;

          returnState.available_stock = productData.available_stock
            ? showWithoutRounding(productData.available_stock)
            : "0";

          returnState.stock_alert = productData.stock_alert
            ? showWithoutRounding(productData.stock_alert)
            : "0";

          returnState.is_inventory_enabled = productData.is_inventory_enabled
            ? true
            : false;

          let stockList = [];

          if (
            (typeof productData.product_inventory === "object" ||
              typeof productData.product_inventory === "array") &&
            productData.product_inventory.length > 0
          ) {
            productData.product_inventory.map((obj) => {
              let clinic_name = allClinics.find((x) => x.id == obj.clinic_id);

              if (clinic_name) {
                clinic_name = clinic_name.clinic_name;
              } else {
                clinic_name = "";
              }

              stockList.push({
                id: obj.id,
                clinic_id: obj.clinic_id,
                clinic_name: clinic_name,
                expiry_date: showFormattedDate(
                  moment(new Date(obj.expiry_date)).format("YYYY-MM-DD"),
                ),
                batch_id: obj.batch_id,
                available_stock: showWithoutRounding(obj.units),
                stock_alert: showWithoutRounding(obj.alert_units),
                cost_to_company: obj.cost_to_company,
              });
            });

            returnState.stockList = stockList;
          }

          if (productData.count_units_by) {
            returnState.startsAtUnitArr = getRangeUpTo(
              Number(productData.count_units_by),
              2,
            );
          }

          returnState.product_per_clinic =
            returnState.is_stock_inventory_enabled
              ? productData.product_per_clinic
                ? true
                : false
              : false;

          returnState.product_type_db =
            returnState.inventory_type === "services" ? "injectable" : "others";

          returnState.inventory_type_db = returnState.inventory_type;

          if (returnState.product_type_db === "others") {
            if (
              productData.product_inventory_other &&
              productData.product_inventory_other.length > 0
            ) {
              returnState.is_add_inventory_stock_other = false;
              allClinics.map((obj) => {
                let inventry = productData.product_inventory_other.find(
                  (x) => x.clinic_id == obj.id,
                );

                let stock = initStockListOthers();
                stock.clinic_id = obj.id;
                stock.clinic_name = obj.clinic_name;

                if (inventry) {
                  stock.id = inventry.id;
                  stock.available_stock = inventry.units
                    ? showWithoutRounding(inventry.units)
                    : "0";
                  stock.stock_alert = inventry.alert_units
                    ? showWithoutRounding(inventry.alert_units)
                    : "0";
                  stock.cost_to_company = inventry.cost_to_company;
                }

                stockListOthers.push(stock);
              });
            }
          }
        }

        if (stockListOthers && stockListOthers.length <= 0) {
          allClinics.map((obj) => {
            let stock = initStockListOthers();
            stock.clinic_id = obj.id;
            stock.clinic_name = obj.clinic_name;
            stockListOthers.push(stock);
          });
        }

        returnState.stockListOthers = stockListOthers;
        returnState.showLoader = false;

        if (
          returnState.inventory_type != "services" &&
          this.state.product_per_clinic === false
        ) {
          returnState.product_reorder_setting =
            data.product_reorder_setting || undefined;
          returnState.reorder_automatically =
            data.product_reorder_setting?.reorder_automatically || 0;
        }

        this.setState(returnState);
        this.hideLoaderFunc();

        if (this.state.cloneId) {
          this.fetchProductForClone();
        }
      })
      .catch(() => {
        uiNotification.error("Unable to fetch product data. Try again later");
        this.hideLoaderFunc();
      });
  };

  componentDidUpdate = () => {
    if (this.state.showLogs || this.state.showDetailedLogs) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      nextProps.emptyInventoryReducer();
      if (localStorage.getItem("showLoader") == "false") {
        returnState.showLoader = false;
        return returnState;
      }
    }

    if (
      nextProps.isProductInactive != undefined &&
      nextProps.isProductInactive == true
    ) {
      nextProps.history.goBack();
    }

    if (
      nextProps.productReorderSetting &&
      nextProps.productReorderSetting !== prevState.productReorderSetting
    ) {
      nextProps.emptyInventoryReducer();
      returnState.showReorderPop = false;
      returnState.showLoader = false;
      returnState.productReorderSetting = nextProps.productReorderSetting;
      if (
        prevState.inventory_type !== "services" &&
        prevState.product_per_clinic === false
      ) {
        returnState.product_reorder_setting = nextProps.productReorderSetting
          .product_reorder_setting
          ? nextProps.productReorderSetting.product_reorder_setting
          : undefined;
      } else {
        returnState.clinics = nextProps.productReorderSetting.clinics
          ? nextProps.productReorderSetting.clinics
          : [];
      }
    }

    if (
      nextProps.productNameAvailability != undefined &&
      (nextProps.productNameAvailability.message == "product_not_found" ||
        nextProps.productNameAvailability.message == "product_found")
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        nextProps.emptyInventoryReducer();
        returnState.productNameError =
          nextProps.productNameAvailability.data.data == "true" ? true : false;
        returnState.productNameAvailable =
          nextProps.productNameAvailability.data.data == "true" ? true : false;
        returnState.loadingSpinner = false;
      }
    }
    if (
      nextProps.categoryData != undefined &&
      nextProps.categoryData.status == 201
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        nextProps.emptyInventoryReducer();
        returnState.showLoader = false;
        returnState.addCategoryPop = false;
        returnState.categories = nextProps.categoryData.data;
        let categoryObj = nextProps.categoryData.data.find(
          (x) => x.category_name == prevState.addCatCategoryName,
        );
        returnState.product_category =
          categoryObj !== undefined && categoryObj.id !== undefined
            ? categoryObj.id
            : 0;
        toggleBodyScroll(false);
      }
    }
    if (
      nextProps.updatedStockList &&
      nextProps.updatedStockList != prevState.updatedStockList
    ) {
      nextProps.emptyInventoryReducer();
      returnState.updatedStockList = nextProps.updatedStockList;
      returnState.showStockTransferPopup = false;
      returnState.showLoader = false;
      let stockList = [];
      if (
        returnState.updatedStockList.product_inventory &&
        returnState.updatedStockList.product_inventory.length > 0
      ) {
        returnState.isUpdateStockList = 1;
        returnState.updatedStockList.product_inventory.map((obj) => {
          let clinic_name = prevState.clinics.find(
            (x) => x.id == obj.clinic_id,
          );
          if (clinic_name) {
            clinic_name = clinic_name.clinic_name;
          } else {
            clinic_name = "";
          }
          stockList.push({
            id: obj.id,
            clinic_id: obj.clinic_id,
            clinic_name: clinic_name,
            expiry_date: showFormattedDate(
              moment(new Date(obj.expiry_date)).format("YYYY-MM-DD"),
            ),
            batch_id: obj.batch_id,
            available_stock: showWithoutRounding(obj.units),
            stock_alert: showWithoutRounding(obj.alert_units),
            cost_to_company: obj.cost_to_company,
          });
        });
      }
      let clinic_wise_inventory = [];
      if (
        returnState.updatedStockList.clinic_wise_inventory &&
        returnState.updatedStockList.clinic_wise_inventory.length > 0
      ) {
        returnState.updatedStockList.clinic_wise_inventory.map((obj) => {
          let clinics = {};
          clinics.id = obj.id;
          clinics.clinic_name = obj.clinic_name;
          clinics.inventory = obj.inventory;
          clinics.expired_inventory = obj.expired_inventory;
          clinics.total_units = obj.total_units;
          clinics.stock_alert = obj.clinic_stock_alert
            ? obj.clinic_stock_alert.stock_alert
            : 0;
          clinic_wise_inventory.push(clinics);
        });
      }

      let stockListOthers = [];
      if (
        returnState.updatedStockList.product_inventory_other &&
        returnState.updatedStockList.product_inventory_other.length > 0
      ) {
        returnState.isUpdateStockListOthers = 1;
        prevState.clinics.map((obj) => {
          let inventry =
            returnState.updatedStockList.product_inventory_other.find(
              (x) => x.clinic_id == obj.id,
            );
          let stock = initStockListOthers();
          stock.clinic_id = obj.id;
          stock.clinic_name = obj.clinic_name;
          if (inventry) {
            stock.id = inventry.id;
            stock.available_stock = inventry.units
              ? showWithoutRounding(inventry.units)
              : "0";
            stock.stock_alert = inventry.alert_units
              ? showWithoutRounding(inventry.alert_units)
              : "0";
            stock.cost_to_company = inventry.cost_to_company;
          }
          stockListOthers.push(stock);
        });
      }

      returnState.stockList = stockList;
      returnState.clinic_wise_inventory = clinic_wise_inventory;
      returnState.stockListOthers = stockListOthers;
      returnState.addStockFields = initAddStockFields();
      returnState.isShowAddStockModal = false;
      returnState.adjustStockFelds = initAdjustStockFields();
      returnState.isShowAdjustStockModal = false;
      returnState.removeStockFelds = initRemoveStockFields();
      returnState.isShowRemoveStockModal = false;
      returnState.stockActionIndex = -1;
      returnState.showEditStockModal = false;
      returnState.editInventoryID = -1;
      returnState.editStockAlert = "";

      returnState.showEditCTCModal = false;
      returnState.editCTCVal = "";
      returnState.editCTCClass = "simpleInput";
      if (
        nextProps.productAdded != undefined &&
        nextProps.productAdded == true &&
        prevState.inventory_type == "services"
      ) {
        let selectedBatches = returnState.clinic_wise_inventory.find(
          (y) => y.id == prevState.addClinicId,
        );
        returnState.clinicStockList = selectedBatches.inventory
          ? selectedBatches.inventory
          : [];
        returnState.total_available_stock = selectedBatches.total_units;
        returnState.clinicExpiredStockList = selectedBatches.expired_inventory
          ? selectedBatches.expired_inventory
          : [];
      }
    }

    if (
      nextProps.stockOrderData != undefined &&
      nextProps.stockOrderDataTime != prevState.stockOrderDataTime
    ) {
      returnState.stockOrderDataTime = nextProps.stockOrderDataTime;
      returnState.stockOrderData = nextProps.stockOrderData;
      returnState.showLoader = false;
      returnState.showOrderStockPopup = true;
    }

    if (
      nextProps.stockAdded != undefined &&
      nextProps.stockAddedDataTime != prevState.stockAddedDataTime
    ) {
      returnState.stockAddedDataTime = nextProps.stockAddedDataTime;
      returnState.showOrderStockPopup = false;
      returnState.showLoader = false;
    }

    if (
      nextProps.stockTransferData != undefined &&
      nextProps.stockTransferDataTime != prevState.stockTransferDataTime
    ) {
      returnState.stockTransferDataTime = nextProps.stockTransferDataTime;
      returnState.stockTransferData = nextProps.stockTransferData;
      returnState.showLoader = false;
      returnState.showStockTransferPopup = true;
    }

    if (
      nextProps.createNewTransfer != undefined &&
      nextProps.createNewTransferDataTime != prevState.createNewTransferDataTime
    ) {
      returnState.createNewTransferDataTime =
        nextProps.createNewTransferDataTime;
      returnState.showStockTransferPopup = false;
      returnState.showLoader = false;
    }

    if (
      nextProps.detailedProductLogs != undefined &&
      nextProps.detailedProductLogsTime != prevState.detailedProductLogsTime
    ) {
      nextProps.emptyInventoryReducer();
      if (nextProps.detailedProductLogs && nextProps.detailedProductLogs.file) {
        returnState.showLoader = false;
        window.open(
          process.env.REACT_APP_API_URL +
            "download-data/" +
            nextProps.detailedProductLogs.file,
          "_blank",
        );
        return returnState;
      }

      if (prevState.detailedProductLogs.length == 0) {
        if (nextProps.detailedProductLogs.next_page_url != null) {
          returnState.modalPage = prevState.modalPage + 1;
          returnState.modal_next_page_url = "";
        } else {
          returnState.modal_next_page_url =
            nextProps.detailedProductLogs.next_page_url;
        }
        returnState.detailedProductLogs = nextProps.detailedProductLogs.data;
      } else {
        if (nextProps.detailedProductLogs.next_page_url != null) {
          returnState.modalPage = prevState.modalPage + 1;
          returnState.modal_next_page_url = "";
        } else {
          returnState.modal_next_page_url =
            nextProps.detailedProductLogs.next_page_url;
        }
        returnState.detailedProductLogs = [
          ...prevState.detailedProductLogs,
          ...nextProps.detailedProductLogs.data,
        ];
      }
      returnState.detailedProductLogsTime = nextProps.detailedProductLogsTime;
      returnState.showDetailedLogs = true;
      returnState.showLoader = false;
      autoScrolling(false);
    }
    return returnState;
  }

  handleTaxOnBlur = (event) => {
    this.setState({
      [event.target.name]: Number(event.target.value).toFixed(4),
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let changedVal = {},
      startsAtUnitArr = [];

    if (
      event.target.name === "available_stock" ||
      event.target.name === "stock_alert" ||
      event.target.name === "reorderQty" ||
      event.target.name === "editStockAlert"
    ) {
      if (decimalPlaces(event.target.value) > 1) {
        return;
      }
    }

    if (event.target.name === "last_4_digits_of_cc") {
      if (value) {
        if (value.length === 5) {
          return;
        }
      }
    }

    if (target.name.startsWith("reorder_automatically_") && !value) {
      let name = target.name;
      let idArr = name.split("_");
      let clinicId = idArr[idArr.length - 1];
      let reorderObj = this.state.clinics.find((e) => e.id == clinicId);
      this.setState({ name: value });
      this.offReorderForClinic(reorderObj);
    }

    if (
      target.name == "same_for_all_clinics" &&
      (target.value == "on" || target.value == true)
    ) {
      let stock = this.state.stockListOthers;
      let available_stock = stock[0].available_stock
        ? stock[0].available_stock
        : "";
      let stock_alert = stock[0].stock_alert ? stock[0].stock_alert : "";
      let cost_to_company = stock[0].cost_to_company
        ? stock[0].cost_to_company
        : "";
      stock &&
        stock.length > 0 &&
        stock.map((data) => {
          data.available_stock = available_stock;
          data.stock_alert = stock_alert;
          data.cost_to_company = cost_to_company;
        });
      this.setState({ stockListOthers: stock });
    } else {
      this.setState({ same_for_all_clinics: false });
    }

    if (target.name == "count_units_by") {
      changedVal.startsAtUnitArr =
        value > 0 ? getRangeUpTo(Number(value), 2) : [];
      changedVal.start_at_unit =
        startsAtUnitArr.length > 0 ? startsAtUnitArr[0] : 0;
    }

    if (target && target.type === "file") {
      const allowedTypes = [
        "image/png",
        "image/PNG",
        "image/jpg",
        "image/JPG",
        "image/jpeg",
        "image/JPEG",
        "image/gif",
        "image/GIF",
      ];

      if (target.files && allowedTypes.indexOf(target.files[0].type) > -1) {
        this.handleFileChosen(target.files[0], target);
      } else {
        uiNotification.error(
          this.state.globalLang.vaidation_this_file_type_is_not_allowed,
        );
      }
    }

    if (target.name == "product_category") {
      if (value == "addCategory") {
        changedVal["addCategoryPop"] = true;
        toggleBodyScroll(true);
      } else {
        changedVal[event.target.name] = value;
      }
    } else {
      changedVal[event.target.name] = value;
    }
    changedVal.userChanged = true;

    if (
      target.name == "inventory_type" &&
      (target.value == "medical_supplies" || target.value == "retail")
    ) {
      this.setState({ product_type: "others", showBatches: false });
    }
    if (target.name == "inventory_type" && target.value == "services") {
      this.setState({ product_type: 0, showBatches: false });
    }
    if (target.name == "billForWholeUnit") {
      changedVal[event.target.name] = value;
    }
    if (target.name === "product_type") {
      this.setState({ injectable_type: 0, surgery_type: 0 });
    }
    this.setState(changedVal);
  };

  handleNameChange = (event) => {
    let target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    if (value.trim() !== "") {
      this.showSpinner();
      this.setState({ product_name: value });
      let formData = {};
      formData.product_name = value;
      formData.product_id = this.state.productId;
      this.props.isProductNameAvailable(formData);
    }
  };

  addCategory = () => {
    this.setState({ catNameError: false });
    if (
      this.state.addCatCategoryName == undefined ||
      this.state.addCatCategoryName.trim() == ""
    ) {
      this.setState({ addCatCategoryNameError: true });
      return false;
    }
    if (this.state.is_custom_tax_rule_enabled_category) {
      var taxError = false;
      let returnState = {};
      for (let x in this.state) {
        if (x.startsWith("addCatClinic-")) {
          let id = x.split("-")[1];
          if (
            this.state[x] === "" ||
            this.state[x] === undefined ||
            this.state[x] === null ||
            !isPositiveNumber(this.state[x], null, null, 5)
          ) {
            returnState["addCatClinicError-" + id] = true;
            taxError = true;
          } else {
            returnState["addCatClinicError-" + id] = false;
          }
        }
      }
      this.setState(returnState);
      if (taxError) {
        return false;
      }
    }
    let formData = {};
    formData.category_name = this.state.addCatCategoryName;
    formData.cat_status = this.state.add_category_status ? 1 : 0;
    formData.is_custom_rule_tax = this.state.is_custom_tax_rule_enabled_category
      ? 1
      : 0;
    let clinic_tax = [];
    for (let x in this.state) {
      if (x.startsWith("addCatClinic-") && this.state[x] !== "") {
        let id = x.split("-")[1];
        clinic_tax.push({ clinic_id: id, tax_percentage: this.state[x] });
      }
    }
    formData.tax_rules = clinic_tax;
    this.showLoaderFunc();
    this.props.createCategory(formData);
  };

  isPricesChanged = () => {
    var result = [];

    if (this.state.productId) {
      const isRegularChanged = isRegularPricesChanged({
        preparedPrices: this.props.getChangedRegularPrices(),
        initialPreparedPrices: this.props.getOriginalRegularPrices(),
        forAllClinics: this.props.forAllClinicsRegular,
      });

      const isMemberChanged = isMemberPricesChanged({
        preparedPrices: this.props.getChangedMemberPrices(),
        initialPreparedPrices: this.props.getOriginalMemberPrices(),
        forAllClinics: this.props.forAllClinicsMember,
      });

      if (isRegularChanged) {
        result.push("regular");
      }

      if (isMemberChanged) {
        result.push("member");
      }
    }

    return result;
  };

  handleSubmit = ({ showPricesConfirm } = {}) => {
    this.setState({
      usedInPackages: {
        charting: [],
        discount: [],
      },
    });

    this.props.emptyInventoryReducer();
    let isManageStock = this.state.isManageStock;

    const productTypeMapping = {
      injectable: "injectableUnitType",
      coolsculpting: "coolsculptingUnitType",
      chemicalpeels: "chemicalpeelsUnitType",
      microneedling: "microneedlingUnitType",
      threads: "threadsUnitType",
      microblading: "microbladingUnitType",
      cellfina: "cellfinaUnitType",
      laser: "treatmentUnitType",
      massage: "treatmentUnitType",
      surgery: "treatmentUnitType",
      consultation: "treatmentUnitType",
      facial: "treatmentUnitType",
      iv: "treatmentUnitType",
      waxing: "treatmentUnitType",
      weightloss: "weightAndLossUnitType",
      hairgrowth: "treatmentUnitType",
      hormonetherapy: "treatmentUnitType",
    };

    const availableUnitTypesIds = this.state[
      productTypeMapping[this.state.product_type]
    ]?.map((unit) => unit.id);

    this.setState({
      productNameError: false,
      productTypeError: false,
      productCategoryError: false,
      costToCompanyError: false,
      unitTypeError: false,
      injectableTypeError: false,
      unitsCountError: false,
      startsAtError: false,
      productRatioError: false,
      available_stock_error: false,
      stock_alert_error: false,
    });

    if (
      this.state.product_name == undefined ||
      this.state.product_name.trim() == "" ||
      this.state.productNameAvailable
    ) {
      this.setState({
        productNameError: true,
        activeMode: isManageStock ? TABS.traceability : TABS.info,
      });
      this.product_name.current.focus();
      return false;
    }

    if (this.state.product_category == 0) {
      this.setState({
        productCategoryError: true,
        activeMode: isManageStock ? TABS.traceability : TABS.info,
      });
      this.product_category.current.focus();
      return false;
    }

    if (
      this.state.product_type == 0 &&
      this.state.inventory_type == "services"
    ) {
      this.setState({
        productTypeError: true,
        activeMode: isManageStock ? TABS.traceability : TABS.info,
      });
      this.product_type.current.focus();
      return false;
    }

    if (
      this.state.cost_to_company == undefined ||
      this.state.cost_to_company === "" ||
      this.state.cost_to_company < 0 ||
      !isNumber(this.state.cost_to_company)
    ) {
      this.setState({
        costToCompanyError: true,
        activeMode: isManageStock ? TABS.traceability : TABS.info,
      });
      this.cost_to_company.current.focus();
      return false;
    }

    if (this.state.product_type != 0 && this.state.product_type != "others") {
      if (
        !this.state.unit_type ||
        !availableUnitTypesIds.includes(parseInt(this.state.unit_type))
      ) {
        this.setState({
          unitTypeError: true,
          activeMode: isManageStock ? TABS.traceability : TABS.info,
        });
        return false;
      }
      if (
        this.state.count_units_by == undefined ||
        this.state.count_units_by == 0
      ) {
        this.setState({
          unitsCountError: true,
          activeMode: isManageStock ? TABS.traceability : TABS.info,
        });
        return false;
      }
      if (
        this.state.start_at_unit == undefined ||
        this.state.start_at_unit == 0
      ) {
        this.setState({
          startsAtError: true,
          activeMode: isManageStock ? TABS.traceability : TABS.info,
        });
        return false;
      }
      if (
        this.state.product_type == "injectable" ||
        this.state.product_type == "threads" ||
        this.state.product_type == "microblading" ||
        this.state.product_type == "cellfina"
      ) {
        if (this.state.injectable_type == 0) {
          this.setState({
            injectableTypeError: true,
            activeMode: isManageStock ? TABS.traceability : TABS.info,
          });
          return false;
        }
      }
      if (this.state.product_type === "surgery" && !this.state.surgery_type) {
        this.setState({ surgeryTypeError: true });
        return;
      }
    }

    if (this.state.product_ratio_enabled) {
      if (!isNumber(this.state.product_ratio)) {
        this.setState({ productRatioError: true });
        return false;
      }
    }

    let changedVal = {},
      error = false;

    if (error) {
      changedVal["activeMode"] = isManageStock
        ? TABS.traceability
        : TABS.pricingRegular;
      this.setState(changedVal);
      return false;
    } else {
      this.setState(changedVal);
    }

    if (
      this.state.is_stock_inventory_enabled == true &&
      this.state.inventory_type !== "services"
    ) {
      if (this.state.product_per_clinic == true) {
        if (
          this.state.productId <= 0 ||
          this.state.is_add_inventory_stock_other === true
        ) {
          let stockError = false;
          let stockListOthers = this.state.stockListOthers;
          stockListOthers.map((obj, index) => {
            if (
              obj.available_stock === undefined ||
              obj.available_stock === "" ||
              !isPositiveNumber(obj.available_stock)
            ) {
              stockListOthers[index].available_stockClass =
                "tableInput field_error";
              stockError = true;
            } else {
              stockListOthers[index].available_stockClass = "tableInput";
            }
            if (
              obj.stock_alert === undefined ||
              obj.stock_alert === "" ||
              !isPositiveNumber(obj.stock_alert)
            ) {
              stockListOthers[index].stock_alertClass =
                "tableInput field_error";
              stockError = true;
            } else {
              stockListOthers[index].stock_alertClass = "tableInput";
            }

            if (
              obj.cost_to_company === undefined ||
              obj.cost_to_company === "" ||
              !isPositiveNumber(obj.cost_to_company)
            ) {
              stockListOthers[index].cost_to_companyClass =
                "tableInput field_error";
              stockError = true;
            } else {
              stockListOthers[index].cost_to_companyClass = "tableInput";
            }
          });
          this.setState({ stockListOthers: stockListOthers });
          if (stockError) {
            return true;
          }
        }
      } else {
        if (
          this.state.available_stock === undefined ||
          this.state.available_stock === "" ||
          !isPositiveNumber(this.state.available_stock, 1)
        ) {
          this.setState({
            available_stock_error: true,
            activeMode: TABS.traceability,
          });
          return false;
        }
        if (
          this.state.stock_alert === undefined ||
          this.state.stock_alert === "" ||
          !isPositiveNumber(this.state.stock_alert)
        ) {
          this.setState({
            stock_alert_error: true,
            activeMode: TABS.traceability,
          });
          return false;
        }
      }
    }

    if (this.state.is_supplier_info_enabled) {
      if (
        this.state.supplier_name == undefined ||
        this.state.supplier_name.trim() == ""
      ) {
        this.setState({
          supplier_name_error: true,
          activeMode: isManageStock ? TABS.traceability : TABS.purchaseOrder,
        });
        return false;
      }
      if (
        this.state.reference_number == undefined ||
        this.state.reference_number.trim() == ""
      ) {
        this.setState({
          reference_number_error: true,
          activeMode: isManageStock ? TABS.traceability : TABS.purchaseOrder,
        });
        return false;
      }
      if (
        this.state.supplier_email_id == undefined ||
        this.state.supplier_email_id.trim() == "" ||
        !validator.isEmail(this.state.supplier_email_id)
      ) {
        this.setState({
          supplier_email_id_error: true,
          activeMode: isManageStock ? TABS.traceability : TABS.purchaseOrder,
        });
        return false;
      }
      if (this.state.contactError) {
        this.setState({
          contactClass: "newInputField field-error",
          activeMode: isManageStock ? TABS.traceability : TABS.purchaseOrder,
        });
        return false;
      }
    }
    if (
      this.state.is_custom_tax_rule_enabled &&
      this.state.inventory_type != "medical_supplies"
    ) {
      var taxError = false;
      let returnState = {};
      for (let x in this.state) {
        if (x.startsWith("customTax-")) {
          let id = x.split("-")[1];
          if (
            this.state[x] === "" ||
            this.state[x] === undefined ||
            this.state[x] === null ||
            !isPositiveNumber(this.state[x], null, null, 5)
          ) {
            returnState["customTaxError-" + id] = true;
            taxError = true;
          } else {
            returnState["customTaxError-" + id] = false;
          }
        }
      }
      if (taxError) {
        returnState["activeMode"] = isManageStock
          ? TABS.traceability
          : TABS.tax;
        this.setState(returnState);
        return false;
      } else {
        this.setState(returnState);
      }
    }
    let formData = {};
    formData.product_type =
      this.state.inventory_type == "medical_supplies" ||
      this.state.inventory_type == "retail"
        ? "others"
        : this.state.product_type;
    formData.productId = this.state.productId;
    formData.product_image = this.state.product_image;
    formData.is_product_active = this.state.is_product_active ? 1 : 0;
    formData.is_evolus_product = this.state.is_evolus_product;
    formData.product_name = this.state.product_name;
    formData.product_category = this.state.product_category;
    formData.cost_to_company = this.state.cost_to_company;
    formData.product_sku = this.state.product_sku;
    formData.product_description = this.state.product_description;
    formData.product_ratio_enabled = this.state.product_ratio_enabled ? 1 : 0;
    formData.keywords = this.state.keywords.length
      ? this.state.keywords.join(", ")
      : "";
    formData.bill_for_whole_unit =
      this.state.inventory_type === "retail"
        ? 1
        : this.state.billForWholeUnit
        ? this.state.billForWholeUnit
        : 0;
    formData.product_reorder_setting = this.state.reorder_setting;
    const selectedProductGroup = this.state.aspire_product_groups.find(
      (aspireProductGroup) => {
        return (
          aspireProductGroup.first_product_id ===
          parseInt(this.state.selected_aspire_group)
        );
      },
    );
    formData.selected_aspire_product_ids = selectedProductGroup
      ? selectedProductGroup.products.map((aspireProduct) => aspireProduct.id)
      : [];
    if (
      formData.productId <= 0 &&
      (this.state.inventory_type === "services" ||
        (this.state.inventory_type !== "services" &&
          this.state.product_per_clinic == true))
    ) {
      formData.product_reorder_setting = this.state.reorder_setting_per_clinic;
    } else if (
      this.state.inventory_type !== "services" &&
      this.state.product_per_clinic == false
    ) {
      formData.product_reorder_setting = [];
    }
    if (this.state.product_ratio_enabled) {
      formData.given_product_ratio = 1;
      formData.product_ratio = this.state.product_ratio;
    }

    if (this.state.product_type !== "others") {
      formData.unit_type = this.state.unit_type;
      formData.count_units_by = this.state.count_units_by;
      formData.start_at_unit = this.state.start_at_unit;
      formData.injectable_type = this.state.injectable_type;
    }

    if (this.state.product_type === "surgery") {
      formData.surgery_type = this.state.surgery_type;
    }

    let clinic_stock_alert = [];
    this.state.clinics.map((obj) => {
      let stock_alert = {};
      stock_alert.clinic_id = obj.id;
      stock_alert.stock_alert = this.state["service-stock-alert-" + obj.id];
      clinic_stock_alert.push(stock_alert);
    });

    formData.clinic_stock_alert = clinic_stock_alert;

    if (
      this.state.product_type !== "others" &&
      this.state.is_inventory_enabled &&
      this.state.productId <= 0
    ) {
      const stockList = JSON.parse(JSON.stringify(this.state.stockList));
      stockList.map((_, idx) => {
        stockList[idx].expiry_date = dateFormat(stockList[idx].expiry_date);
        stockList[idx].stock_alert = stockList[idx].stock_alert
          ? stockList[idx].stock_alert
          : 0;
      });
      formData.stock_inventory = stockList;
    }

    formData.available_stock = showWithoutRounding(this.state.available_stock);
    formData.stock_alert = showWithoutRounding(this.state.stock_alert);
    if (
      this.state.product_type === "others" &&
      this.state.is_stock_inventory_enabled == true &&
      this.state.inventory_type !== "services"
    ) {
      if (
        this.state.product_per_clinic == true &&
        this.state.is_add_inventory_stock_other === true
      ) {
        formData.stock_inventory = this.state.stockListOthers;
        formData.available_stock = this.state.available_stock
          ? showWithoutRounding(this.state.available_stock)
          : 0;
        formData.stock_alert = this.state.stock_alert
          ? showWithoutRounding(this.state.stock_alert)
          : 0;
      }
    }

    if (this.state.is_supplier_info_enabled) {
      formData.supplier_name = this.state.supplier_name;
      formData.reference_number = this.state.reference_number;
      formData.supplier_email_id = this.state.supplier_email_id;
      formData.supplier_phone_number = this.state.supplier_phone_number;
    }

    if (this.state.is_custom_tax_rule_enabled) {
      let clinic_tax = [];
      for (let x in this.state) {
        if (x.startsWith("customTax-")) {
          let id = x.split("-")[1];
          clinic_tax.push({ clinic_id: id, tax: this.state[x] });
        }
      }
      formData.clinic_tax = clinic_tax;
    }
    if (
      !this.state.productId &&
      (this.state.inventory_type == "services" ||
        this.state.inventory_type == "retail")
    ) {
      this.setState({ priceErrors: null });
      const requestChangedPrices = this.props.getPricesForRequest(true);

      const errors = validateChangedPricesForRequest({
        forAllClinics: this.props.pricesForAllClinics,
        requestChangedPrices,
      });

      if (!errors.perUnitError && errors.perClinicErrors.length === 0) {
        formData.price_list = requestChangedPrices;
      } else {
        this.setState({ priceErrors: errors });
        uiNotification.error(
          "Please fill all the required fields in Regular Pricing tab",
        );
        return false;
      }
    }

    if (this.state.inventory_type == "medical_supplies") {
      formData.account_medical_supplies_in_reports =
        this.state.account_cost_to_company == true ? 1 : 0;
      formData.is_autodeduct_enabled =
        this.state.deduct_inventory == true ? 1 : 0;
    }

    formData.inventory_type = this.state.inventory_type;

    formData.is_inventory_enabled =
      this.state.product_type !== "others"
        ? this.state.is_inventory_enabled
          ? 1
          : 0
        : 0;

    formData.is_stock_inventory_enabled =
      this.state.product_type === "others"
        ? this.state.is_stock_inventory_enabled
          ? 1
          : 0
        : 0;

    formData.is_supplier_info_enabled = this.state.is_supplier_info_enabled
      ? 1
      : 0;

    formData.is_custom_tax_rule_enabled = this.state.is_custom_tax_rule_enabled
      ? 1
      : 0;

    if (this.state.inventory_type == "retail") {
      formData.product_service = "Product Sale";
    } else if (this.state.inventory_type == "services") {
      formData.product_service = "Aesthetic Service";
    } else {
      formData.product_service = "Other";
    }

    formData.product_per_clinic = formData.is_stock_inventory_enabled
      ? this.state.product_per_clinic == true
        ? 1
        : 0
      : 0;

    formData.product_type_db =
      this.state.productId > 0
        ? this.state.product_type_db
        : this.state.product_type;

    formData.inventory_type_db =
      this.state.productId > 0
        ? this.state.inventory_type_db
        : this.state.product_type;

    this.setState({ showLoader: true });

    if (this.state.productId <= 0) {
      formData.regular_price = true;
      formData.price_same_for_all_clinic = this.props.pricesForAllClinics;

      if (showPricesConfirm) {
        const isAllZeroes = !!formData?.price_list?.some(
          (x) => x.location_price_per_unit === 0,
        );

        if (isAllZeroes) {
          this.setState({ showPricesConfirm: true });
          return;
        }
      }

      this.props
        .addProduct(formData)
        .then((response) => {
          dispatch(productPricingRegular.actions.setOriginalToChangedPrices());
          uiNotification.success("Product created successfully");

          if (this.state.inventory_type !== "medical_supplies") {
            this.setState({
              redirectProductId: response.data?.product_id,
            });
          } else {
            history.push("/inventory/products/active");
            this.setState({
              showLoader: false,
            });
          }
        })
        .finally(() => {
          this.setState({ showLoader: false });
        });
    } else {
      this.props
        .updateProduct(formData)
        .then(() => {
          dispatch(productPricingRegular.actions.setInitialized(false));
          dispatch(productPricingMember.actions.setInitialized(false));
          dispatch(
            productPricingMember.actions.setDefaultMembershipAfterInit(true),
          );
          uiNotification.success("Product updated successfully");
          history.push(`/inventory/products/active?${SKIP_QUERY_KEY}=true`);
        })
        .catch((error) => {
          if (error.message === "product_tax_must_be_equal_for_each_clinic") {
            this.makeActive(TABS.tax);
          }
        })
        .finally(() => {
          this.setState({ showLoader: false });
        });
    }
  };

  handleTagChange = (value) => {
    this.setState({ keywords: value, userChanged: true });
  };

  handleUpload = () => {
    let uploadtype = "";

    uploadtype = "product_image";
    const data = new FormData();
    data.append("file", this.state.file, this.state.file.name);
    let endpoint = `media/upload?upload_type=${uploadtype}`;

    http
      .post(endpoint, data)
      .then((res) => {
        let name = this.state.target.name;
        this.setState({ [name]: res.data.data.file_name, showLoader: false });
      })
      .catch((error) => {
        uiNotification.error(
          this.state.globalLang[error.response.data.message],
        );
      });
  };

  handleFileRead = () => {
    let name = this.state.target.name + "_thumbnail";
    let src = this.state.target.name + "_src";
    let size = this.state.target.name + "_size";
    let fileSize = formatBytes(this.state.file.size, 1);
    this.setState({
      [name]: this.state.file.name,
      [size]: fileSize,
      [src]: this.state.fileReader.result,
      showLoader: true,
    });

    this.handleUpload(this.state.target.name);
  };

  handleFileChosen = (file, target) => {
    this.state.fileReader = new FileReader();
    this.state.fileReader.onloadend = this.handleFileRead;
    this.state.fileReader.readAsDataURL(file);
    this.state.file = file;
    this.state.target = target;
  };

  showDeleteModal = () => {
    this.setState({ showModal: true });
    toggleBodyScroll(true);
  };

  showInactivateModal = () => {
    this.setState({ showInactivateModal: true });
    toggleBodyScroll(true);
  };

  dismissModal = () => {
    this.setState({ showModal: false });
    toggleBodyScroll(false);
  };

  dismissInactivateModal = () => {
    this.setState({ showInactivateModal: false });
    toggleBodyScroll(false);
  };

  deleteProduct = () => {
    localStorage.setItem("showLoader", false);
    this.setState({
      showLoader: true,
      showModal: false,
      usedInPackages: {
        charting: [],
        discount: [],
      },
    });

    let cId = this.state.productId;

    this.props
      .deleteProduct(cId)
      .then(() => {
        uiNotification.success("Product has been successfully deleted");
        history.push("/inventory/products/active");
      })
      .catch((res) => {
        uiNotification.clear();
        if (res.message === "product_used_in_packages") {
          uiNotification.error("Product cannot be deleted");
          this.setState({
            usedInPackages: {
              charting: Object.values(res.data?.charting_packages || {}),
              discount: Object.values(res.data?.discount_packages || {}),
            },
          });
        } else {
          uiNotification.error(
            this.state.globalLang[res.message] ||
              "Unable to delete product. Try again later",
          );
        }
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });

    toggleBodyScroll(false);
  };

  inactivateProduct = () => {
    localStorage.setItem("showLoader", false);
    this.setState({
      showLoader: true,
      showInactivateModal: false,
      isProductInactive: true,
    });
    this.props.makeProductInactivate(this.state.productId);
    toggleBodyScroll(false);
  };

  removeUploadedFile = () => {
    this.setState({
      product_image_thumbnail: "",
      product_image_src: "",
      product_image_size: "",
      product_image: "",
    });
  };

  showAddStockModal = () => {
    let stockFields = initAddStockFields();
    stockFields.clinic_id = this.state.selectedClinicForBatch.id;
    this.setState({
      addClinicId: this.state.selectedClinicForBatch.id,
      isShowAddStockModal: true,
      addStockFields: stockFields,
      stockActionIndex: -1,
    });
  };

  handleInputChangeAddStockModal = (event) => {
    let addStockFields = this.state.addStockFields;

    if (
      event.target.name === "available_stock" ||
      event.target.name === "stock_alert"
    ) {
      if (decimalPlaces(event.target.value) > 3) {
        return;
      }
    }

    addStockFields[event.target.name] = event.target.value;

    this.setState({ addStockFields: addStockFields });
  };

  handleDatePickerAddStockModal = (date) => {
    let addStockFields = this.state.addStockFields;
    addStockFields.expiry_date = date;
    addStockFields.expiry_dateClass = "simpleInput";
    this.setState({ addStockFields: addStockFields });
  };

  handleSubmitAddStockModal = () => {
    let error = false;
    if (
      this.state.inventory_type === "services" &&
      this.state.is_inventory_enabled == true
    ) {
      let addStockFields = this.state.addStockFields;
      addStockFields.stock_alert = 0;
      let expiry_date = new Date(addStockFields.expiry_date);
      let month = expiry_date.getMonth() + 1;
      let day = expiry_date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      expiry_date = expiry_date.getFullYear() + "-" + month + "-" + day;
      if (
        addStockFields.clinic_id === undefined ||
        addStockFields.clinic_id === null ||
        addStockFields.clinic_id <= 0
      ) {
        addStockFields.clinic_idClass = "simpleSelect field_error";
        error = true;
      } else {
        addStockFields.clinic_idClass = "simpleSelect";
      }
      if (this.state.inventory_type === "services") {
        if (
          addStockFields.expiry_date === undefined ||
          addStockFields.expiry_date === "" ||
          addStockFields.expiry_date === null
        ) {
          addStockFields.expiry_dateClass = "simpleInput field_error";
          error = true;
        } else if (
          this.state.productId < 0 &&
          addStockFields.expiry_date <= new Date()
        ) {
          addStockFields.expiry_dateClass = "simpleInput field_error";
          error = true;
        } else {
          addStockFields.expiry_dateClass = "simpleInput";
        }
        if (addStockFields.batch_id.trim() == "") {
          addStockFields.batch_idClass = "simpleInput field_error";
          error = true;
        } else {
          addStockFields.batch_idClass = "simpleInput";
        }
      }
      if (
        addStockFields.available_stock === undefined ||
        addStockFields.available_stock === "" ||
        !isPositiveNumber(addStockFields.available_stock, 0, null, 4)
      ) {
        addStockFields.available_stockClass = "simpleInput field_error";
        error = true;
      } else {
        addStockFields.available_stockClass = "simpleInput";
      }
      if (
        addStockFields.cost_to_company === undefined ||
        addStockFields.cost_to_company === "" ||
        !isPositiveNumber(addStockFields.cost_to_company, 0)
      ) {
        addStockFields.cost_to_companyClass = "simpleInput field_error";
        error = true;
      } else {
        addStockFields.cost_to_companyClass = "simpleInput";
      }
      this.setState({ addStockFields: addStockFields });
      if (error) {
        return false;
      }

      if (this.state.productId > 0) {
        const formData = JSON.parse(JSON.stringify(addStockFields));
        formData.expiry_date = expiry_date;
        formData.productId = this.state.productId;
        formData.action = "add";
        formData.product_type =
          this.state.inventory_type === "services" ? "injectable" : "others";
        this.setState({ showLoader: true });
        this.props.addProductStock(formData);
      } else {
        let stockList = this.state.stockList;
        let clinics = this.state.clinic_wise_inventory;
        let clinic_name = this.state.clinics.find(
          (x) => x.id == addStockFields.clinic_id,
        );
        let index = clinics.find((x) => x.id == addStockFields.clinic_id);
        if (clinic_name) {
          clinic_name = clinic_name.clinic_name;
        } else {
          clinic_name = "";
        }
        addStockFields.clinic_name = clinic_name;
        addStockFields.expiry_date = expiry_date;
        let total_available_stock = 0;

        stockList.push(addStockFields);
        let inventory =
          index.inventory && index.inventory.length > 0 ? index.inventory : [];
        inventory.push(addStockFields);
        index.inventory = inventory;
        if (index.inventory && index.inventory.length > 0) {
          index.inventory.map((data) => {
            total_available_stock =
              Number(data.available_stock) + total_available_stock;
          });
        }
        index.total_units = total_available_stock;
        this.setState({
          stockList: stockList,
          clinicStockList: index.inventory,
          isShowAddStockModal: false,
          addStockFields: initAddStockFields,
          total_available_stock: total_available_stock,
          clinic_wise_inventory: clinics,
        });
      }
    }
  };

  showAdjustStockModal = (batchData, index) => {
    if (
      (this.state.is_inventory_enabled == true &&
        this.state.inventory_type === "services") ||
      (this.state.is_stock_inventory_enabled == true &&
        this.state.productId > 0 &&
        (this.state.inventory_type === "retail" ||
          this.state.inventory_type === "medical_supplies"))
    ) {
      let adjustStockFelds = initAdjustStockFields();
      adjustStockFelds.id = batchData.id;
      this.setState({
        isShowAdjustStockModal: true,
        adjustStockFelds: adjustStockFelds,
        stockActionIndex: index,
        clinicIdForAddStock: batchData.clinic_id,
      });
    }
  };

  handleInputChangeAdjustStockModal = (event) => {
    let adjustStockFelds = this.state.adjustStockFelds;

    if (event.target.name === "available_stock") {
      if (decimalPlaces(event.target.value) > 3) {
        return;
      }
    }

    adjustStockFelds[event.target.name] = event.target.value;
    this.setState({ adjustStockFelds: adjustStockFelds });
  };

  handleSubmitAdjustStockModal = () => {
    let error = false;
    let adjustStockFelds = this.state.adjustStockFelds;

    if (
      adjustStockFelds.available_stock === undefined ||
      adjustStockFelds.available_stock === "" ||
      !isPositiveNumber(adjustStockFelds.available_stock, 1, null, 4)
    ) {
      adjustStockFelds.available_stockClass = "simpleInput field_error";
      error = true;
    } else {
      adjustStockFelds.available_stockClass = "simpleInput";
    }

    if (
      adjustStockFelds.cost_to_company === undefined ||
      adjustStockFelds.cost_to_company === "" ||
      !isPositiveNumber(adjustStockFelds.cost_to_company, 0)
    ) {
      adjustStockFelds.cost_to_companyClass = "simpleInput field_error";
      error = true;
    } else {
      adjustStockFelds.cost_to_companyClass = "simpleInput";
    }

    this.setState({ adjustStockFelds: adjustStockFelds });
    if (error) {
      return false;
    }

    if (
      this.state.is_inventory_enabled == true &&
      this.state.inventory_type === "services"
    ) {
      if (this.state.productId) {
        const formData = JSON.parse(JSON.stringify(adjustStockFelds));
        formData.productId = this.state.productId;
        formData.action = "adjust";
        formData.product_type = "injectable";
        formData.inventory_type = this.state.inventory_type;
        this.setState({ showLoader: true });
        this.props.adjustProductStock(formData);
      } else {
        let stockList = this.state.stockList;
        stockList[this.state.stockActionIndex]["available_stock"] =
          showWithoutRounding(
            stockList[this.state.stockActionIndex]["available_stock"],
          ) + showWithoutRounding(adjustStockFelds.available_stock);
        this.setState({
          stockList: stockList,
          isShowAdjustStockModal: false,
          adjustStockFelds: adjustStockFelds,
        });
      }
    }

    if (
      this.state.is_stock_inventory_enabled == true &&
      this.state.productId > 0 &&
      (this.state.inventory_type === "retail" ||
        this.state.inventory_type === "medical_supplies")
    ) {
      const formData = JSON.parse(JSON.stringify(adjustStockFelds));
      formData.productId = this.state.productId;
      formData.action = "adjust";
      formData.product_type = "others";
      formData.inventory_type = this.state.inventory_type;
      formData.product_per_clinic = 1;
      formData.clinic_id = this.state.clinicIdForAddStock;
      this.setState({ showLoader: true });
      this.props.adjustProductStock(formData);
    }
  };

  showRemoveStockModal = (batchData, index) => {
    if (
      this.state.is_inventory_enabled == true &&
      this.state.inventory_type === "services"
    ) {
      if (this.state.productId > 0) {
        let removeStockFelds = initRemoveStockFields();
        removeStockFelds.id = batchData.id;
        if (this.state.inventory_type == "services") {
          removeStockFelds.stock_limit = batchData.units;
        } else {
          removeStockFelds.stock_limit = batchData.available_stock;
        }

        this.setState({
          isShowRemoveStockModal: true,
          removeStockFelds: removeStockFelds,
          stockActionIndex: index,
        });
      } else {
        let stockList = this.state.stockList;
        stockList.splice(index, 1);
        this.setState({ stockList: stockList });
      }
    }
    if (
      this.state.is_stock_inventory_enabled == true &&
      this.state.productId > 0 &&
      (this.state.inventory_type === "retail" ||
        this.state.inventory_type === "medical_supplies")
    ) {
      let removeStockFelds = initRemoveStockFields();
      removeStockFelds.id = batchData.id;
      removeStockFelds.stock_limit = batchData.available_stock;
      this.setState({
        isShowRemoveStockModal: true,
        removeStockFelds: removeStockFelds,
        stockActionIndex: index,
      });
    }
  };

  handleInputChangeRemoveStockModal = (event) => {
    let removeStockFelds = this.state.removeStockFelds;

    if (event.target.name === "available_stock") {
      if (
        decimalPlaces(event.target.value) >
        getPrecision(Number(removeStockFelds.stock_limit))
      ) {
        return;
      }
    }

    removeStockFelds[event.target.name] = event.target.value;

    if (event.target.name === "reason" && event.target.value === "others") {
      removeStockFelds.reason_other = "";
    }

    if (event.target.name === "available_stock") {
      if (
        !removeStockFelds.available_stock ||
        Number(event.target.value) > Number(removeStockFelds.stock_limit)
      ) {
        removeStockFelds.available_stockClass = "simpleInput field_error";
      } else {
        removeStockFelds.available_stockClass = "simpleInput";
      }
    }

    this.setState({ removeStockFelds: removeStockFelds });
  };

  handleSubmitRemoveStockModal = () => {
    let error = false;
    if (this.state.productId > 0) {
      let removeStockFelds = this.state.removeStockFelds;

      if (
        removeStockFelds.available_stock === undefined ||
        removeStockFelds.available_stock === "" ||
        Number(removeStockFelds.available_stock) < 0 ||
        Number(removeStockFelds.available_stock) >
          Number(removeStockFelds.stock_limit)
      ) {
        removeStockFelds.available_stockClass = "simpleInput field_error";
        error = true;
      } else {
        removeStockFelds.available_stockClass = "simpleInput";
      }

      if (
        removeStockFelds.reason === undefined ||
        removeStockFelds.reason === null ||
        removeStockFelds.reason <= 0
      ) {
        removeStockFelds.reasonClass = "simpleSelect field_error";
        error = true;
      } else {
        removeStockFelds.reasonClass = "simpleSelect";
        if (removeStockFelds.reason === "others") {
          if (
            removeStockFelds.reason_other === undefined ||
            removeStockFelds.reason_other === "" ||
            removeStockFelds.reason_other === null
          ) {
            removeStockFelds.reason_otherClass = "simpleInput field_error";
            error = true;
          } else {
            removeStockFelds.reason_otherClass = "simpleInput";
          }
        }
      }

      this.setState({ removeStockFelds: removeStockFelds });
      if (error) {
        return false;
      }
      if (
        (this.state.is_inventory_enabled == true &&
          this.state.inventory_type === "services") ||
        (this.state.is_stock_inventory_enabled == true &&
          (this.state.inventory_type === "retail" ||
            this.state.inventory_type === "medical_supplies"))
      ) {
        const formData = JSON.parse(JSON.stringify(removeStockFelds));
        formData.productId = this.state.productId;
        formData.action = "remove";
        if (formData.reason === "others") {
          formData.reason = formData.reason_other;
        }
        formData.product_type =
          this.state.inventory_type === "services" ? "injectable" : "others";
        formData.inventory_type = this.state.inventory_type;
        this.setState({ showLoader: true });
        this.props.adjustProductStock(formData);
      }
    }
  };

  dismissStockModal = () => {
    this.setState({
      addStockFields: initAddStockFields(),
      isShowAddStockModal: false,
      adjustStockFelds: initAdjustStockFields(),
      isShowAdjustStockModal: false,
      removeStockFelds: initRemoveStockFields(),
      isShowRemoveStockModal: false,
      stockActionIndex: -1,
    });
  };

  phoneNumberChanged = (t, _x, _y, number) => {
    if (!number) {
      return;
    }
    if (t) {
      this.setState({
        supplier_phone_number: number.replace(/\s/g, ""),
        contactClass: "newInputField",
        contactError: false,
      });
    } else {
      this.setState({
        contactClass: "newInputField field_error",
        contactError: true,
      });
    }
  };

  showDetailedProductLogs = () => {
    this.setState({ showLoader: true, detailedProductLogs: [], modalPage: 1 });
    let formData = {
      params: {
        pagesize: 20,
        page: 1,
        id: this.state.productId,
      },
    };
    this.props.showDetailedLogs(formData);
  };

  showReorderPopMethod = (obj) => {
    let returnState = {};
    let reorderSettingData = null;
    if (this.state.productId <= 0) {
      if (
        this.state.inventory_type !== "services" &&
        this.state.product_per_clinic === false &&
        this.state.reorder_setting &&
        this.state.reorder_setting.length === 1
      ) {
        reorderSettingData = this.state.reorder_setting[0];
      } else if (
        ((this.state.inventory_type !== "services" &&
          this.state.product_per_clinic === true) ||
          this.state.inventory_type == "services") &&
        this.state.reorder_setting_per_clinic &&
        this.state.reorder_setting_per_clinic.length > 0
      ) {
        reorderSettingData = this.state.reorder_setting_per_clinic.find(
          (x) => x.clinic_id == obj.id,
        );
      }
    }
    if (reorderSettingData) {
      returnState.reorderThreshold = reorderSettingData.threshold;
      returnState.reorderQty = reorderSettingData.quantity;
      returnState.reorderRate = reorderSettingData.rate;
      returnState.reorderTax = reorderSettingData.tax;
      returnState.note = reorderSettingData.note ? reorderSettingData.note : "";
      returnState.reorderSupplier = reorderSettingData.supplier_id;

      returnState.md_id = reorderSettingData.md_id
        ? reorderSettingData.md_id
        : "";
      returnState.custom_md = reorderSettingData.custom_md
        ? reorderSettingData.custom_md
        : "";
      returnState.payment_terms = reorderSettingData.payment_terms
        ? reorderSettingData.payment_terms
        : "";

      returnState.payment_term_type = reorderSettingData.payment_term_type
        ? reorderSettingData.payment_term_type
        : "last_4_digits_of_cc";
      returnState.last_4_digits_of_cc = reorderSettingData.last_4_digits_of_cc
        ? reorderSettingData.last_4_digits_of_cc
        : "";
    } else if (
      obj &&
      (obj.product_reorder_setting_id ||
        (this.state.product_reorder_setting &&
          Object.entries(this.state.product_reorder_setting).length))
    ) {
      returnState.reorderThreshold = obj.threshold;
      returnState.reorderQty = obj.quantity;
      returnState.reorderRate = obj.rate;
      returnState.reorderTax = obj.tax;
      returnState.note = obj.note ? obj.note : "";
      returnState.reorderSupplier = obj.supplier_id;

      returnState.md_id = obj.md_id ? obj.md_id : "";
      returnState.custom_md = obj.custom_md ? obj.custom_md : "";
      returnState.payment_terms = obj.payment_terms ? obj.payment_terms : "";
      returnState.payment_term_type = obj.payment_term_type
        ? obj.payment_term_type
        : "last_4_digits_of_cc";
      returnState.last_4_digits_of_cc = obj.last_4_digits_of_cc
        ? obj.last_4_digits_of_cc
        : "";
      if (
        this.state.inventory_type !== "services" &&
        this.state.product_per_clinic === false
      ) {
        returnState.product_reorder_setting_id =
          this.state.product_reorder_setting &&
          Object.entries(this.state.product_reorder_setting).length > 0
            ? this.state.product_reorder_setting.id
            : 0;
      } else {
        returnState.product_reorder_setting_id = obj.product_reorder_setting_id;
      }
    } else {
      returnState.reorderThreshold = "";
      returnState.reorderQty = "";
      returnState.reorderRate = "";
      returnState.reorderTax = "";
      returnState.note = "";
      returnState.reorderSupplier = 0;

      returnState.md_id = "";
      returnState.custom_md = "";
      returnState.payment_terms = "";
      returnState.payment_term_type = "last_4_digits_of_cc";
      returnState.last_4_digits_of_cc = "";
    }
    returnState.reorderThresholdClass = "newInputField";
    returnState.reorderQtyClass = "newInputField";
    returnState.reorderRateClass = "newInputField";
    returnState.reorderTaxClass = "newInputField";
    returnState.noteClass = "newInputField";
    returnState.reorderSupplierClass = "newSelectField";

    returnState.md_idClass = "simpleSelect";
    returnState.custom_mdClass = "simpleInput";

    returnState.showReorderPop = true;
    returnState.reorderObj = obj;
    this.setState(returnState);
  };

  hideReorderPop = () => {
    this.setState({ showReorderPop: false });
  };

  saveReorderInfo = () => {
    let returnState = {
      reorderThresholdClass: "newInputField",
      reorderQtyClass: "newInputField",
      reorderRateClass: "newInputField",
      reorderTaxClass: "newInputField",
      noteClass: "newInputField",
      reorderSupplierClass: "newSelectField",
      md_idClass: "simpleSelect",
      custom_mdClass: "simpleInput",
      payment_term_typeClass: "simpleSelect",
      last_four_valueClass: "simpleInput",
    };
    let error = false;

    let medicalDirector = this.state.md_id;
    let customMD = this.state.custom_md;
    let paymentTerms = this.state.payment_terms;
    let paymentTermType = this.state.payment_term_type;
    let last4Digits = this.state.last_4_digits_of_cc;

    if (
      this.state.reorderSupplier == undefined ||
      this.state.reorderSupplier <= 0 ||
      this.state.reorderSupplier == ""
    ) {
      returnState.reorderSupplierClass = "newSelectField field-error";
      error = true;
    }
    if (
      this.state.reorderThreshold === undefined ||
      this.state.reorderThreshold === "" ||
      !isPositiveNumber(this.state.reorderThreshold, 1)
    ) {
      returnState.reorderThresholdClass = "newInputField field-error";
      error = true;
    }
    if (
      this.state.reorderQty === undefined ||
      this.state.reorderQty === "" ||
      !isPositiveNumber(this.state.reorderQty, 1)
    ) {
      returnState.reorderQtyClass = "newInputField field-error";
      error = true;
    }
    if (
      this.state.reorderRate === undefined ||
      this.state.reorderRate === "" ||
      !isPositiveNumber(this.state.reorderRate, 1)
    ) {
      returnState.reorderRateClass = "newInputField field-error";
      error = true;
    }
    if (
      this.state.reorderTax === undefined ||
      this.state.reorderTax === "" ||
      !isPositiveNumber(this.state.reorderTax, 0)
    ) {
      returnState.reorderTaxClass = "newInputField field-error";
      error = true;
    }

    if (
      medicalDirector == undefined ||
      medicalDirector == null ||
      medicalDirector == ""
    ) {
      error = true;
      returnState.md_idClass = "simpleSelect field_error";
    }

    if (medicalDirector && medicalDirector === "custom") {
      if (customMD == undefined || customMD == null || customMD == "") {
        error = true;
        returnState.custom_mdClass = "simpleInput field_error";
      }
    }

    if (
      paymentTermType &&
      (paymentTermType === "both" || paymentTermType === "last_4_digits_of_cc")
    ) {
      if (
        last4Digits == undefined ||
        last4Digits == null ||
        last4Digits == ""
      ) {
        error = true;
        returnState.last_four_valueClass = "simpleInput field_error";
      } else {
        if (last4Digits) {
          if (last4Digits.length !== 4) {
            error = true;
            returnState.last_four_valueClass = "simpleInput field_error";
          }
        }
      }
    }

    this.setState(returnState);
    if (error) {
      return false;
    }
    this.setState({ showLoader: true });

    if (this.state.productId) {
      let formData = {
        product_id: this.state.productId,
        supplier_id: this.state.reorderSupplier,
        threshold: this.state.reorderThreshold,
        quantity: this.state.reorderQty,
        rate: this.state.reorderRate,
        tax: this.state.reorderTax,
        note: this.state.note,
        reorder_automatically: 1,
        product_type:
          this.state.inventory_type === "services" ? "injectable" : "others",
        product_per_clinic: this.state.product_per_clinic ? 1 : 0,
      };
      if (this.state.reorderObj && this.state.reorderObj.id) {
        formData.clinic_id = this.state.reorderObj.id;
      }
      if (this.state.product_reorder_setting_id) {
        formData.id = this.state.product_reorder_setting_id;
      }

      formData.md_id = medicalDirector;

      if (medicalDirector && medicalDirector === "custom") {
        formData.custom_md = customMD;
      }

      formData.payment_term_type = paymentTermType;

      if (
        paymentTermType &&
        (paymentTermType === "both" ||
          paymentTermType === "last_4_digits_of_cc")
      ) {
        formData.last_4_digits_of_cc = last4Digits;
      }

      formData.payment_terms = paymentTerms;
      this.props.saveReorderSettings(formData);
    } else {
      if (
        this.state.inventory_type != "services" &&
        this.state.product_per_clinic === false
      ) {
        let reorder_setting = {
          supplier_id: this.state.reorderSupplier,
          threshold: this.state.reorderThreshold,
          quantity: this.state.reorderQty,
          rate: this.state.reorderRate,
          tax: this.state.reorderTax,
          note: this.state.note,
          reorder_automatically: 1,
        };

        reorder_setting.md_id = medicalDirector;

        if (medicalDirector && medicalDirector === "custom") {
          reorder_setting.custom_md = customMD;
        }

        reorder_setting.payment_term_type = paymentTermType;

        if (
          paymentTermType &&
          (paymentTermType === "both" ||
            paymentTermType === "last_4_digits_of_cc")
        ) {
          reorder_setting.last_4_digits_of_cc = last4Digits;
        }

        reorder_setting.payment_terms = paymentTerms;

        this.setState({
          reorder_setting: [reorder_setting],
          showReorderPop: false,
          showLoader: false,
        });
      } else {
        let allReorderSettings = this.state.reorder_setting_per_clinic
          ? JSON.parse(JSON.stringify(this.state.reorder_setting_per_clinic))
          : [];
        let clinicExists = allReorderSettings.length
          ? allReorderSettings.findIndex(
              (e) => e.clinic_id == this.state.reorderObj.id,
            )
          : -1;
        let formData = {
          product_id: this.state.productId,
          supplier_id: this.state.reorderSupplier,
          threshold: this.state.reorderThreshold,
          quantity: this.state.reorderQty,
          rate: this.state.reorderRate,
          tax: this.state.reorderTax,
          note: this.state.note,
          reorder_automatically: 1,
        };

        formData.md_id = medicalDirector;

        if (medicalDirector && medicalDirector === "custom") {
          formData.custom_md = customMD;
        }

        formData.payment_term_type = paymentTermType;

        if (
          paymentTermType &&
          (paymentTermType === "both" ||
            paymentTermType === "last_4_digits_of_cc")
        ) {
          formData.last_4_digits_of_cc = last4Digits;
        }

        formData.payment_terms = paymentTerms;

        if (this.state.reorderObj && this.state.reorderObj.id) {
          formData.clinic_id = this.state.reorderObj.id;
        }
        if (clinicExists > -1) {
          allReorderSettings[clinicExists] = formData;
        } else {
          allReorderSettings.push(formData);
        }
        this.setState({
          reorder_setting_per_clinic: allReorderSettings,
          showReorderPop: false,
          showLoader: false,
        });
      }
    }
  };

  offReorderForClinic = (reorderObj) => {
    if (reorderObj.product_reorder_setting_id) {
      let formData = {
        id: reorderObj.product_reorder_setting_id,
        reorder_automatically: 0,
        product_id: this.state.productId,
      };
      this.setState({ showLoader: true });
      this.props.saveReorderSettings(formData);
    }
  };

  handleInputChangeStockOthers = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const index = target.dataset.index;
    let stockListOthers = this.state.stockListOthers;
    let stock = stockListOthers[index];

    if (target.name === "available_stock") {
      if (decimalPlaces(value) > 1) {
        return;
      }

      stock.available_stock = value;
      if (
        stock.available_stock === undefined ||
        stock.available_stock === "" ||
        !isPositiveNumber(stock.available_stock)
      ) {
        stock.available_stockClass = "tableInput field_error";
      } else {
        stock.available_stockClass = "tableInput";
      }
    } else if (target.name === "stock_alert") {
      if (decimalPlaces(value) > 1) {
        return;
      }

      stock.stock_alert = value;
      if (
        stock.stock_alert === undefined ||
        stock.stock_alert === "" ||
        !isPositiveNumber(stock.stock_alert)
      ) {
        stock.stock_alertClass = "tableInput field_error";
      } else {
        stock.stock_alertClass = "tableInput";
      }
    } else if (target.name === "cost_to_company") {
      stock.cost_to_company = value;
      if (
        stock.cost_to_company === undefined ||
        stock.cost_to_company === "" ||
        !isPositiveNumber(stock.cost_to_company)
      ) {
        stock.cost_to_companyClass = "tableInput field_error";
      } else {
        stock.cost_to_companyClass = "tableInput";
      }
    }
    stockListOthers[index] = stock;
    this.setState({
      stockListOthers: stockListOthers,
      same_for_all_clinics: false,
    });
  };

  showEditStockModal = (batchData) => {
    if (
      (this.state.is_inventory_enabled == true &&
        this.state.inventory_type === "services") ||
      (this.state.is_stock_inventory_enabled == true &&
        this.state.productId > 0 &&
        (this.state.inventory_type === "retail" ||
          this.state.inventory_type === "medical_supplies"))
    ) {
      let inventoryID = batchData.id;
      let stockAlert = batchData.stock_alert;

      this.setState({
        showEditStockModal: true,
        editInventoryID: inventoryID,
        editStockAlert: stockAlert,
        editClinicId: inventoryID,
      });
    }
  };

  dismissEditStockModal = () => {
    this.setState({
      showEditStockModal: false,
      editInventoryID: -1,
      editStockAlert: "",
    });
  };

  handleSubmitStockAlertUpdate = () => {
    let error = false;
    let editStockAlertClass = "simpleInput";

    if (
      this.state.editStockAlert === undefined ||
      this.state.editStockAlert === "" ||
      !isPositiveNumber(this.state.editStockAlert, 0)
    ) {
      editStockAlertClass = "simpleInput field_error";
      error = true;
    } else {
      editStockAlertClass = "simpleInput";
    }

    this.setState({ editStockAlertClass: editStockAlertClass });

    if (error) {
      return;
    } else {
      this.setState({ showLoader: true });

      if (this.state.inventory_type == "services") {
        let formData = {
          stock_alert: this.state.editStockAlert,
          clinic_id: this.state.editClinicId,
          product_id: this.state.productId,
        };

        this.props.updateStockAlert(formData);
      } else {
        let formData = {
          stock_alert: this.state.editStockAlert,
          inventory_id: this.state.editInventoryID,
          product_id: this.state.productId,
        };
        this.props.updateStockAlertForOthers(formData);
      }
    }
  };

  showEditCTCModal = (batchData) => {
    if (
      (this.state.is_inventory_enabled == true &&
        this.state.inventory_type === "services") ||
      (this.state.is_stock_inventory_enabled == true &&
        this.state.productId > 0 &&
        (this.state.inventory_type === "retail" ||
          this.state.inventory_type === "medical_supplies"))
    ) {
      let inventoryID = batchData.id;
      let ctcPUnit = batchData.cost_to_company;

      this.setState({
        showEditCTCModal: true,
        editInventoryID: inventoryID,
        editCTCVal: roundOffNum(ctcPUnit),
      });
    }
  };

  dismissEditCTCModal = () => {
    this.setState({
      showEditCTCModal: false,
      editInventoryID: -1,
      editCTCVal: "",
      editCTCClass: "simpleInput",
    });
  };

  handleSubmitCTCUpdate = () => {
    let error = false;
    let editCTCClass = "simpleInput";

    if (
      this.state.editCTCVal === undefined ||
      this.state.editCTCVal === "" ||
      !isPositiveNumber(this.state.editCTCVal, 0)
    ) {
      editCTCClass = "simpleInput field_error";
      error = true;
    } else {
      editCTCClass = "simpleInput";
    }

    this.setState({ editCTCClass: editCTCClass });

    if (error) {
      return;
    } else {
      this.setState({ showLoader: true });

      let formData = {
        cost_to_company: this.state.editCTCVal,
        inventory_id: this.state.editInventoryID,
        product_id: this.state.productId,
      };

      this.props.updateCTC(formData);
    }
  };

  viewBatches = (batchData) => {
    let clinicStockList =
      batchData.inventory && batchData.inventory.length > 0
        ? batchData.inventory
        : [];
    let clinicExpiredStockList =
      batchData.expired_inventory && batchData.expired_inventory.length > 0
        ? batchData.expired_inventory
        : [];

    let total_available_stock = batchData.total_units
      ? batchData.total_units
      : 0;
    this.setState({
      selectedClinicForBatch: batchData,
      showBatches: true,
      clinicStockList: clinicStockList,
      total_available_stock: total_available_stock,
      addClinicId: batchData.id,
      clinicExpiredStockList: clinicExpiredStockList,
    });
  };

  openOrderStockPopup = (obj) => {
    let id;
    if (obj) {
      id = this.state.inventory_type == "services" ? obj.id : obj.clinic_id;
    } else {
      id = this.state.addClinicId;
    }
    this.setState({ showLoader: true, selectedClinicOrderId: id });
    autoScrolling(false);
    this.props.fetchOrderDetails();
  };

  saveOrderInfo = (handleChildState) => {
    this.setState({ showLoader: true });
    this.props.saveOrderInfo(handleChildState);
  };

  dismissOrderStockPopup = () => {
    this.setState({ showOrderStockPopup: false });
  };

  openStockTransferPopup = (obj) => {
    let available_stock =
      this.state.inventory_type == "services" ? obj.units : obj.available_stock;
    let batchId = obj.batch_id ? obj.batch_id : "N/A";
    let type =
      this.state.inventory_type == "services" ? "injectable" : "others";
    this.setState({
      showLoader: true,
      selectedClinicOrderId: obj.clinic_id,
      batchId: batchId,
      availableStock: available_stock,
      inventory_id: obj.id,
      stockTransferType: type,
    });
    autoScrolling(false);
    this.props.viewStockTransfer(0);
  };

  dismissStockTransferPopup = () => {
    this.setState({ showStockTransferPopup: false });
  };

  transferStock = (childState) => {
    this.setState({ showLoader: true });
    this.props.createStockTransfer(childState);
  };

  loadMoreProductLogs = (childState) => {
    this.setState({ isDownload: false });

    if (!autoScrolling()) {
      let formData = {
        params: {
          pagesize: 20,
          page: this.state.modalPage,
          id: this.state.productId,
          all_clinics: childState.all_clinics,
          fromDate: childState.fromDate,
          toDate: childState.toDate,
          clinic_id: childState.clinic_id,
        },
      };
      autoScrolling(true);
      this.props.showDetailedLogs(formData);
    }
  };

  handleChildFilter = (childState) => {
    this.setState({
      showLoader: true,
      detailedProductLogs: [],
      modalPage: 1,
      isDownload: false,
    });
    let formData = {
      params: {
        pagesize: 20,
        page: 1,
        id: this.state.productId,
        all_clinics: childState.all_clinics,
        fromDate: childState.fromDate,
        toDate: childState.toDate,
        clinic_id: childState.clinic_id,
      },
    };
    this.props.showDetailedLogs(formData);
  };

  handleChildDownload = (childState) => {
    this.setState({ showLoader: true, isDownload: true });

    let formData = {
      params: {
        export: true,
        id: this.state.productId,
        all_clinics: childState.all_clinics,
        fromDate: childState.fromDate,
        toDate: childState.toDate,
        clinic_id: childState.clinic_id,
      },
    };

    this.props.showDetailedLogs(formData);
  };

  getUnitsCountBy = () => {
    if (
      ["chemicalpeels", "microblading", "coolsculpting"].includes(
        this.state.product_type,
      ) &&
      this.state.unit_type === "11"
    ) {
      return ["1"];
    }

    if (
      this.state.product_type === "injectable" ||
      this.state.product_type === "weightloss" ||
      (["iv", "hormonetherapy"].includes(this.state.product_type) &&
        ["12", "10"].includes(this.state.unit_type))
    ) {
      return ["0.001", "0.01", ...this.state.unitsByArr];
    }

    return this.state.unitsByArr;
  };

  shortenFloat = (n) => {
    return String(n).slice(0, 5);
  };

  get productTypeArrSorted() {
    return this.state.productTypeArr.sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });
  }

  get injectableTypeArrSorted() {
    return this.state.injectableTypeArr.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  }

  get aspireProductGroupSorted() {
    return this.state.aspire_product_groups.sort((a, b) => {
      if (a.group_name < b.group_name) return -1;
      if (a.group_name > b.group_name) return 1;
      return 0;
    });
  }

  saveRegularPrices = async ({
    showNotification = true,
    refetch = true,
  } = {}) => {
    try {
      this.setState({ showLoader: true });
      const onSuccess = await this.props.saveRegularPrices.initiate(
        this.state.productId,
        showNotification,
      );
      if (refetch) {
        await this.fetchProduct();
        onSuccess?.();
        return () => {};
      }
      return onSuccess;
    } finally {
      this.setState({
        showLoader: false,
      });
    }
  };

  saveMemberPrices = async ({
    showNotification = true,
    refetch = true,
  } = {}) => {
    try {
      this.setState({ showLoader: true });
      const onSuccess = await this.props.saveMemberPrices.initiate(
        this.state.productId,
        showNotification,
      );
      if (refetch) {
        await this.fetchProduct();
        onSuccess?.();
        return () => {};
      }
      return onSuccess;
    } finally {
      this.setState({
        showLoader: false,
      });
    }
  };

  savePricingTabs = async () => {
    var changed = this.isPricesChanged();
    var reset = () => {};

    if (changed.includes("regular")) {
      const resetRegular = await this.saveRegularPrices({
        showNotification: false,
        refetch: false,
      });
      reset = resetRegular;
    }

    if (changed.includes("member")) {
      const resetMember = await this.saveMemberPrices({
        showNotification: false,
        refetch: false,
      });
      reset = resetMember;
    }

    this.fetchProduct().then(() => reset?.());
  };

  render() {
    const dateFormat =
      this.props?.currentUserQuery.data?.dateFormat || API_DATE_FORMAT;

    const productTypeMapping = {
      injectable: "injectableUnitType",
      coolsculpting: "coolsculptingUnitType",
      chemicalpeels: "chemicalpeelsUnitType",
      microneedling: "microneedlingUnitType",
      threads: "threadsUnitType",
      microblading: "microbladingUnitType",
      cellfina: "cellfinaUnitType",
      laser: "treatmentUnitType",
      massage: "treatmentUnitType",
      surgery: "treatmentUnitType",
      consultation: "treatmentUnitType",
      facial: "treatmentUnitType",
      iv: "treatmentUnitType",
      waxing: "treatmentUnitType",
      weightloss: "weightAndLossUnitType",
      hairgrowth: "treatmentUnitType",
      hormonetherapy: "treatmentUnitType",
    };

    const unitTypeKey =
      productTypeMapping[this.state.product_type] || "defaultUnitType";
    const unitTypeArr = this.state[unitTypeKey];

    let stockUnitType = "";
    if (this.state.unit_type != 0) {
      let unitTypeObj = unitTypeArr.find((x) => x.id == this.state.unit_type);
      if (unitTypeObj !== undefined && unitTypeObj.value !== undefined) {
        stockUnitType = unitTypeObj.value;
      }
    }

    let productSystemId =
      this.state.productDefaultData?.product?.system_product_id || 0;

    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />

          <div className="memberWalletOuter business-section">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                <span className="cursor-pointer">
                  <Link
                    style={{ color: "#667680" }}
                    to="/inventory/products/active"
                  >
                    Inventory
                  </Link>
                </span>{" "}
                <span className="breadCrumb">
                  <i className="fa fa-chevron-right breadcrumbArrow"></i>{" "}
                  <span className="breadCrumb-text">
                    {this.state.product_name
                      ? this.state.product_name
                      : "Product Information"}
                  </span>
                </span>
                <div className="memberRightActions inventoryTopActions">
                  <button
                    className="new-blue-btn pull-right"
                    onClick={() => {
                      if (this.isPricesChanged().length > 0) {
                        this.setState({
                          showConfirmSaveWithUnsaved: true,
                        });
                      } else {
                        this.handleSubmit({ showPricesConfirm: true });
                      }
                    }}
                  >
                    {this.state.cloneId ? "Clone" : "Save"}
                  </button>
                  {this.state.productId > 0 &&
                    checkIfPermissionAllowed("delete-product") === true &&
                    this.state.is_system_product == 0 && (
                      <button
                        className="new-red-btn pull-left"
                        id="resetformasd"
                        onClick={this.showDeleteModal}
                      >
                        {this.state.inventoryLang.inventory_delete}
                      </button>
                    )}
                  {productSystemId > 0 &&
                    checkIfPermissionAllowed("edit-product") === true && (
                      <button
                        className="new-blue-btn pull-left"
                        id="resetformasd"
                        onClick={this.showInactivateModal}
                      >
                        {"Inactivate"}
                      </button>
                    )}
                </div>
                <UsedInPackagesAlert
                  usedInPackages={this.state.usedInPackages}
                />
              </div>
            </div>
            <div className="juvly-section full-width">
              <div className="juvly-container p25 p-t-10">
                {(this.state.productId <= 0 ||
                  (this.state.productId > 0 &&
                    checkIfPermissionAllowed("edit-product") === true)) && (
                  <ul className=" appointment-tabs inventory-tabs">
                    <li>
                      <a
                        className={
                          this.state.activeMode == TABS.info ? "active" : ""
                        }
                        onClick={this.makeActive.bind(this, TABS.info)}
                      >
                        Information
                      </a>
                    </li>
                    <li>
                      <a
                        className={
                          this.state.activeMode == TABS.pricingRegular
                            ? "active"
                            : ""
                        }
                        onClick={this.makeActive.bind(
                          this,
                          TABS.pricingRegular,
                        )}
                      >
                        Regular Pricing
                      </a>
                    </li>
                    {Boolean(this.state.productId) && (
                      <li>
                        <a
                          className={
                            this.state.activeMode == TABS.pricingMember
                              ? "active"
                              : ""
                          }
                          onClick={this.makeActive.bind(
                            this,
                            TABS.pricingMember,
                          )}
                        >
                          Membership Pricing
                        </a>
                      </li>
                    )}
                    <li>
                      <a
                        className={
                          this.state.activeMode == TABS.traceability
                            ? "active"
                            : ""
                        }
                        onClick={this.makeActive.bind(this, TABS.traceability)}
                      >
                        Traceability
                      </a>
                    </li>
                    <li>
                      <a
                        className={
                          this.state.activeMode == TABS.tax ? "active" : ""
                        }
                        onClick={this.makeActive.bind(this, TABS.tax)}
                      >
                        Tax Settings
                      </a>
                    </li>
                    <li>
                      <a
                        className={
                          this.state.activeMode == TABS.purchaseOrder
                            ? "active"
                            : ""
                        }
                        onClick={this.makeActive.bind(this, TABS.purchaseOrder)}
                      >
                        Purchase Order
                      </a>
                    </li>
                  </ul>
                )}
                <div className="row">
                  <div className="col-xs-12">
                    {this.state.activeMode == TABS.info && (
                      <Info
                        loadingSpinner={this.state.loadingSpinner}
                        isEvolusProduct={this.state.is_evolus_product}
                        unitTypeArr={unitTypeArr}
                        productName={{
                          error: this.state.productNameError,
                          onBlur: this.handleNameChange.bind(this),
                          onChange: this.handleInputChange.bind(this),
                          value: this.state.product_name,
                          ref: this.product_name,
                        }}
                        inventoryType={{
                          error: this.state.inventoryTypeError,
                          onChange: this.handleInputChange.bind(this),
                          value: this.state.inventory_type,
                        }}
                        productCategory={{
                          error: this.state.productCategoryError,
                          onChange: this.handleInputChange.bind(this),
                          value: this.state.product_category,
                          ref: this.product_category,
                          options: this.state.categories,
                        }}
                        productType={{
                          error: this.state.productTypeError,
                          onChange: this.handleInputChange.bind(this),
                          value: this.state.product_type,
                          ref: this.product_type,
                          options: this.productTypeArrSorted,
                        }}
                        productSku={{
                          onChange: this.handleInputChange.bind(this),
                          value: this.state.product_sku,
                        }}
                        costToCompany={{
                          error: this.state.costToCompanyError,
                          onChange: this.handleInputChange.bind(this),
                          value: this.state.cost_to_company,
                          ref: this.cost_to_company,
                        }}
                        aspireProduct={{
                          value: this.state.selected_aspire_group,
                          onChange: this.handleInputChange.bind(this),
                          options: this.aspireProductGroupSorted,
                        }}
                        linkToJeuveau={{
                          onChange: this.handleInputChange.bind(this),
                        }}
                        productImage={{
                          value: this.state["product_image_src"],
                          thumb: this.state["product_image_thumbnail"],
                          size: this.state["product_image_size"],
                          onRemove: this.removeUploadedFile.bind(this),
                          onChange: this.handleInputChange.bind(this),
                        }}
                        description={{
                          value: this.state.product_description,
                          onChange: this.handleInputChange.bind(this),
                        }}
                        unitType={{
                          error: this.state.unitTypeError,
                          onChange: this.handleInputChange.bind(this),
                          value: this.state.unit_type,
                        }}
                        injectableType={{
                          error: this.state.injectableTypeError,
                          onChange: this.handleInputChange.bind(this),
                          value: this.state.injectable_type,
                          options: this.injectableTypeArrSorted,
                        }}
                        surgeryType={{
                          error: this.state.surgeryTypeError,
                          onChange: this.handleInputChange.bind(this),
                          value: this.state.surgery_type,
                          options: this.state.surgeryTypeArr,
                        }}
                        unitsCount={{
                          error: this.state.unitsCountError,
                          onChange: this.handleInputChange.bind(this),
                          value: this.state.count_units_by,
                          options: this.getUnitsCountBy(),
                        }}
                        startsAtUnit={{
                          error: this.state.startsAtError,
                          onChange: this.handleInputChange.bind(this),
                          value: this.state.start_at_unit,
                          options: this.state.startsAtUnitArr,
                        }}
                        productRatio={{
                          onChange: this.handleInputChange.bind(this),
                          onCheck: this.handleInputChange.bind(this),
                          checked: this.state.product_ratio_enabled,
                          error: this.state.productRatioError,
                          value: this.state.product_ratio,
                        }}
                        productKeywords={{
                          value: this.state.keywords,
                          onChange: this.handleTagChange.bind(this),
                        }}
                        billForWholeUnit={{
                          checked: this.state.billForWholeUnit,
                          onChange: this.handleInputChange.bind(this),
                        }}
                        accountCostToCompany={{
                          checked: this.state.account_cost_to_company,
                          onChange: this.handleInputChange.bind(this),
                        }}
                        deductInventory={{
                          checked: this.state.deduct_inventory,
                          onChange: this.handleInputChange.bind(this),
                        }}
                      />
                    )}
                    {[TABS.pricingMember, TABS.pricingRegular].includes(
                      this.state.activeMode,
                    ) && (
                      <>
                        {this.state.inventory_type === "medical_supplies" ? (
                          <div className="col-xs-12 text-center m-t-30">
                            <h4>
                              Price input is not applicable for Medical
                              Supplies.
                            </h4>
                          </div>
                        ) : this.state.activeMode === TABS.pricingRegular ? (
                          <PricingRegular
                            productId={this.state.productId}
                            initData={
                              this.state.cloneId
                                ? this.state.cloneInitData
                                : this.state.initData
                            }
                            errors={this.state.priceErrors}
                            onSave={this.saveRegularPrices}
                            isSaving={this.props.saveRegularPrices.isLoading}
                            perUnitError={
                              this.props.saveRegularPrices.perUnitError
                            }
                            setPerUnitError={
                              this.props.saveRegularPrices.setPerUnitError
                            }
                            perClinicErrors={
                              this.props.saveRegularPrices.perClinicErrors
                            }
                            setPerClinicErrors={
                              this.props.saveRegularPrices.setPerClinicErrors
                            }
                          />
                        ) : (
                          <>
                            {this.state.productId ? (
                              <PricingMember
                                productId={this.state.productId}
                                initData={this.state.initData}
                                onSave={this.saveMemberPrices}
                                isSaving={this.props.saveMemberPrices.isLoading}
                                perMemberErrors={
                                  this.props.saveMemberPrices.perMemberErrors
                                }
                                setPerMemberErrors={
                                  this.props.saveMemberPrices.setPerMemberErrors
                                }
                                forAllClinicsError={
                                  this.props.saveMemberPrices.forAllClinicsError
                                }
                                setForAllClinicsError={
                                  this.props.saveMemberPrices
                                    .setForAllClinicsError
                                }
                              />
                            ) : null}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div
                  className={`${
                    this.state.activeMode == TABS.traceability &&
                    this.state.showBatches == false
                      ? ""
                      : "no-display"
                  } ${this.state.isManageStock === 1 ? "p-t-30" : ""}`}
                >
                  {/* SLot number and Expiration -  Switcher Block - START - INJECTABLE/SERVICES */}
                  <div
                    className={
                      this.state.product_type != "others" ? "" : "no-display"
                    }
                  >
                    <div className="newInputFileldOuter m-t-0 m-b-20">
                      <div
                        className="p-r-0 pull-left"
                        id="switch-accordian-product-type-except-other"
                      >
                        {this.state.inventoryLang.inventory_lot_no_expiration}
                        <label className="setting-switch pull-left m-r-10">
                          <input
                            type="checkbox"
                            id="is_inventory_enabled"
                            onChange={this.handleInputChange}
                            name="is_inventory_enabled"
                            checked={
                              this.state.is_inventory_enabled
                                ? "checked"
                                : false
                            }
                            className="setting-custom-switch-input"
                          />
                          <span className="setting-slider green-switch" />
                        </label>
                      </div>
                      <div
                        className={
                          !this.state.is_inventory_enabled
                            ? "no-display"
                            : "p-t-0 pull-right"
                        }
                      >
                        {this.state.productId > 0 && (
                          <div>
                            <a
                              onClick={() =>
                                this.setState({ isInventoryLogModalOpen: true })
                              }
                              className="line-btn m-l-10 m-r-5"
                            >
                              View Logs
                            </a>
                            <a
                              onClick={this.showDetailedProductLogs}
                              className="line-btn m-l-10"
                            >
                              View Detailed Logs
                            </a>
                          </div>
                        )}
                      </div>

                      <div
                        className={
                          !this.state.is_inventory_enabled
                            ? "p-t-0 pull-right"
                            : "no-display"
                        }
                      >
                        {this.state.productId > 0 && (
                          <div>
                            <a
                              onClick={this.showDetailedProductLogs}
                              className="line-btn m-l-10"
                            >
                              View Detailed Logs
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        !this.state.is_inventory_enabled
                          ? "setting-container no-padding p-b-0 no-display"
                          : "setting-container no-padding p-b-0 "
                      }
                      id="Stock-Injectable-Clinic-Waise-form-title"
                    >
                      <div className={"table-responsive m-t-0"}>
                        <table
                          className="table-updated juvly-table no-hover no-td-border no-border table-min-width m-b-25"
                          ref={(node) => {
                            if (node) {
                              node.style.setProperty(
                                "width",
                                "100%",
                                "important",
                              );
                            }
                          }}
                        >
                          <thead className="table-updated-thead">
                            <tr>
                              <th className="table-updated-th col-xs-4">
                                {this.state.inventoryLang.inventory_clinic}
                              </th>
                              <th className="table-updated-th text-left col-xs-3">
                                {this.state.product_type &&
                                this.state.product_type === "coolsculpting"
                                  ? "Cycle"
                                  : this.state.globalLang.label_stock}{" "}
                                {stockUnitType &&
                                this.state.product_type !== "coolsculpting"
                                  ? this.state.globalLang.label_in_small +
                                    " " +
                                    stockUnitType
                                  : ""}
                              </th>
                              <th className="table-updated-th text-left col-xs-3 no-padding">
                                {this.state.product_type &&
                                this.state.product_type === "coolsculpting"
                                  ? "Stock Alert"
                                  : this.state.inventoryLang
                                      .inventory_stock_alert}
                              </th>
                              <th className="table-updated-th col-xs-2 p-l-0 p-r-0">
                                {this.state.globalLang.label_action}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="ajax_body">
                            {this.state.clinic_wise_inventory &&
                              this.state.clinic_wise_inventory.length > 0 &&
                              this.state.clinic_wise_inventory.map(
                                (obj, index) => {
                                  return (
                                    <tr
                                      className="table-updated-tr"
                                      key={"stockList-" + index}
                                    >
                                      <td className="table-updated-td word-break">
                                        {obj.clinic_name}
                                      </td>
                                      <td className="table-updated-td text-left">
                                        {obj.total_units}
                                      </td>
                                      {this.state.productId > 0 ? (
                                        <td className="table-updated-td text-left no-padding">
                                          {showWithoutRounding(obj.stock_alert)}
                                        </td>
                                      ) : (
                                        <td className="table-updated-td text-left no-padding">
                                          <input
                                            type="text"
                                            className="simpleInput"
                                            name={
                                              "service-stock-alert-" + obj.id
                                            }
                                            value={
                                              this.state[
                                                "service-stock-alert-" + obj.id
                                              ]
                                            }
                                            onChange={this.handleInputChange}
                                          />
                                        </td>
                                      )}

                                      <td className="table-updated-td text-center p-l-0 p-r-0">
                                        <div
                                          className="option-dropDown pull-left m-l-5 churnFilterExport"
                                          data-radium="true"
                                        >
                                          <div className="dropdown pull-left">
                                            <button
                                              className="btn btn-default dropdown-toggle"
                                              type="button"
                                              id="dropdownMenu1"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              {`Options`}
                                              <i className="fas fa-angle-down" />
                                            </button>
                                            <ul className="dropdown-menu">
                                              <li>
                                                <a
                                                  onClick={this.viewBatches.bind(
                                                    this,
                                                    obj,
                                                    index,
                                                  )}
                                                >
                                                  View Batches
                                                </a>
                                              </li>

                                              {this.state.productId > 0 && (
                                                <li>
                                                  <a
                                                    onClick={this.showEditStockModal.bind(
                                                      this,
                                                      obj,
                                                      index,
                                                    )}
                                                  >{`Edit Stock Alert`}</a>
                                                </li>
                                              )}

                                              {this.state.productId > 0 && (
                                                <li>
                                                  <a
                                                    onClick={() =>
                                                      this.openOrderStockPopup(
                                                        obj,
                                                      )
                                                    }
                                                  >{`Order Stock`}</a>
                                                </li>
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                },
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* SLot number and Expiration -  Switcher Block - END - INJECTABLE/SERVICES */}
                  {/* Stock and Inventory - Switcher Block - START - OTHERS */}
                  <div
                    className={
                      this.state.product_type == "others" ? "" : "no-display"
                    }
                  >
                    <div className="newInputFileldOuter m-t-0">
                      <div
                        className="switch-accordian-row p-t-20 p-b-0"
                        id="switch-accordian-product-type-other"
                      >
                        {this.state.inventoryLang.inventory_stock_and_inventory}
                        <label className="setting-switch pull-left m-r-10">
                          <input
                            type="checkbox"
                            id="is_stock_inventory_enabled"
                            onChange={this.handleInputChange}
                            name="is_stock_inventory_enabled"
                            checked={
                              this.state.is_stock_inventory_enabled
                                ? "checked"
                                : false
                            }
                            className="setting-custom-switch-input"
                          />
                          <span className="setting-slider green-switch" />
                        </label>
                      </div>
                    </div>

                    {(this.state.is_stock_inventory_enabled == true ||
                      this.state.is_stock_inventory_enabled == 1) && (
                      <React.Fragment>
                        <div className="newInputFileldOuter m-t-0">
                          <div
                            className="switch-accordian-row p-t-20 p-b-0"
                            id="switch-accordian-product-per-clinic"
                          >
                            {"Stock per clinic"}
                            <label className="setting-switch pull-left m-r-10">
                              <input
                                type="checkbox"
                                id="product_per_clinic"
                                onChange={this.handleInputChange}
                                name="product_per_clinic"
                                checked={
                                  this.state.product_per_clinic
                                    ? "checked"
                                    : false
                                }
                                className="setting-custom-switch-input"
                              />
                              <span className="setting-slider green-switch" />
                            </label>

                            {this.state.product_per_clinic == true &&
                              this.state.is_add_inventory_stock_other && (
                                <div className="pull-right">
                                  <div className="check-note">
                                    <input
                                      id="same_for_all_clinics"
                                      type="checkbox"
                                      className="note-check"
                                      name="same_for_all_clinics"
                                      checked={
                                        this.state.same_for_all_clinics
                                          ? "checked"
                                          : false
                                      }
                                      onChange={this.handleInputChange}
                                    />
                                    <div
                                      htmlFor="same_for_all_clinics"
                                      className="check-note-text"
                                    >
                                      Same for all clinics
                                    </div>
                                  </div>
                                </div>
                              )}

                            <div
                              className={
                                !this.state.product_per_clinic
                                  ? "no-display"
                                  : "p-t-0 pull-right"
                              }
                            >
                              {this.state.is_add_inventory_stock_other ===
                                false && (
                                <span>
                                  <a
                                    onClick={() =>
                                      this.setState({
                                        isInventoryLogModalOpen: true,
                                      })
                                    }
                                    className="line-btn m-l-10 m-r-5"
                                  >
                                    View Logs
                                  </a>
                                  <a
                                    onClick={this.showDetailedProductLogs}
                                    className="line-btn m-l-10"
                                  >
                                    View Detailed Logs
                                  </a>
                                </span>
                              )}
                            </div>

                            <div
                              className={
                                !this.state.product_per_clinic
                                  ? "p-t-0 pull-right"
                                  : "no-display"
                              }
                            >
                              <span>
                                <a
                                  onClick={this.showDetailedProductLogs}
                                  className="line-btn m-l-10"
                                >
                                  View Detailed Logs
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>

                        {(this.state.product_per_clinic == true ||
                          this.state.product_per_clinic == 1) && (
                          <div
                            className={"setting-container no-padding p-b-0 "}
                            id="Stock-Others-Clinic-Wise-form-title"
                          >
                            <div
                              className={
                                this.state.stockListOthers &&
                                this.state.stockListOthers.length > 0
                                  ? "table-responsive m-t-0"
                                  : "table-responsive m-t-0 no-display"
                              }
                            >
                              <table
                                className="table-updated juvly-table no-hover no-td-border no-border table-min-width m-b-25"
                                ref={(node) => {
                                  if (node) {
                                    node.style.setProperty(
                                      "width",
                                      "98%",
                                      "important",
                                    );
                                  }
                                }}
                              >
                                <thead className="table-updated-thead">
                                  <tr>
                                    <th className="table-updated-th col-xs-3">
                                      {
                                        this.state.inventoryLang
                                          .inventory_clinic
                                      }
                                    </th>
                                    <th className="table-updated-th text-left col-xs-2">
                                      {"Cost to Company Per Unit"}
                                    </th>
                                    <th className="table-updated-th text-left col-xs-3">
                                      {
                                        this.state.inventoryLang
                                          .inventory_available_stock
                                      }
                                    </th>
                                    <th className="table-updated-th text-left col-xs-3 no-padding">
                                      {
                                        this.state.inventoryLang
                                          .inventory_stock_alert
                                      }
                                    </th>
                                    {this.state.is_add_inventory_stock_other ===
                                      false && (
                                      <th className="table-updated-th col-xs-2 text-left p-r-0 p-l-40">
                                        {this.state.globalLang.label_action}
                                      </th>
                                    )}
                                  </tr>
                                </thead>
                                <tbody className="ajax_body">
                                  {this.state.is_add_inventory_stock_other ===
                                  false
                                    ? this.state.stockListOthers.map(
                                        (obj, index) => {
                                          return (
                                            <tr
                                              className="table-updated-tr"
                                              key={"stockListOthers-" + index}
                                            >
                                              <td className="table-updated-td ">
                                                {obj.clinic_name}
                                              </td>
                                              <td className="table-updated-td text-left">
                                                {numberFormat(
                                                  obj.cost_to_company,
                                                  "currency",
                                                  2,
                                                )}
                                              </td>
                                              <td className="table-updated-td text-left">
                                                {obj.available_stock
                                                  ? showWithoutRounding(
                                                      obj.available_stock,
                                                    )
                                                  : 0}
                                              </td>
                                              <td className="table-updated-td text-left no-padding">
                                                {obj.stock_alert
                                                  ? showWithoutRounding(
                                                      obj.stock_alert,
                                                    )
                                                  : 0}
                                              </td>
                                              <td className="table-updated-td text-left p-r-0 p-l-30">
                                                <div
                                                  className="option-dropDown pull-right m-l-5 churnFilterExport"
                                                  data-radium="true"
                                                >
                                                  <div className="dropdown pull-right">
                                                    <button
                                                      className="btn btn-default dropdown-toggle"
                                                      type="button"
                                                      id="dropdownMenu2"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    >
                                                      {`Options`}
                                                      <i className="fas fa-angle-down" />
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                      {
                                                        <li>
                                                          <a
                                                            onClick={this.showAdjustStockModal.bind(
                                                              this,
                                                              obj,
                                                              index,
                                                            )}
                                                          >
                                                            {
                                                              this.state
                                                                .globalLang
                                                                .label_add
                                                            }
                                                          </a>
                                                        </li>
                                                      }
                                                      {obj.available_stock >
                                                        0 && (
                                                        <li>
                                                          <a
                                                            onClick={this.showRemoveStockModal.bind(
                                                              this,
                                                              obj,
                                                              index,
                                                            )}
                                                          >
                                                            {
                                                              this.state
                                                                .globalLang
                                                                .label_remove
                                                            }
                                                          </a>
                                                        </li>
                                                      )}
                                                      {
                                                        <li>
                                                          <a
                                                            onClick={this.showEditStockModal.bind(
                                                              this,
                                                              obj,
                                                              index,
                                                            )}
                                                          >{`Edit Stock Alert`}</a>
                                                        </li>
                                                      }
                                                      {
                                                        <li>
                                                          <a
                                                            onClick={this.showEditCTCModal.bind(
                                                              this,
                                                              obj,
                                                              index,
                                                            )}
                                                          >{`Edit CTC/Unit`}</a>
                                                        </li>
                                                      }
                                                      {obj.available_stock >
                                                        0 && (
                                                        <li>
                                                          <a
                                                            onClick={this.openStockTransferPopup.bind(
                                                              this,
                                                              obj,
                                                            )}
                                                          >
                                                            Stock Transfer
                                                          </a>
                                                        </li>
                                                      )}

                                                      <li>
                                                        <a
                                                          onClick={() =>
                                                            this.openOrderStockPopup(
                                                              obj,
                                                            )
                                                          }
                                                        >
                                                          Order Stock
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        },
                                      )
                                    : this.state.stockListOthers.map(
                                        (obj, index) => {
                                          return (
                                            <tr
                                              className="table-updated-tr"
                                              key={"stockListOthers-" + index}
                                            >
                                              <td className="table-updated-td ">
                                                {obj.clinic_name}
                                              </td>

                                              <td className="table-updated-td text-left">
                                                <div className="setting-input-outer">
                                                  <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="cost_to_company"
                                                    className={
                                                      obj.cost_to_companyClass
                                                    }
                                                    value={obj.cost_to_company}
                                                    data-index={index}
                                                    onChange={
                                                      this
                                                        .handleInputChangeStockOthers
                                                    }
                                                  />
                                                </div>
                                              </td>

                                              <td className="table-updated-td text-left">
                                                <div className="setting-input-outer">
                                                  <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="available_stock"
                                                    className={
                                                      obj.available_stockClass
                                                    }
                                                    value={obj.available_stock}
                                                    data-index={index}
                                                    onChange={
                                                      this
                                                        .handleInputChangeStockOthers
                                                    }
                                                  />
                                                </div>
                                              </td>
                                              <td className="table-updated-td text-left no-padding">
                                                <div className="setting-input-outer">
                                                  <input
                                                    type="text"
                                                    autoComplete="off"
                                                    name="stock_alert"
                                                    className={
                                                      obj.stock_alertClass
                                                    }
                                                    value={obj.stock_alert}
                                                    data-index={index}
                                                    onChange={
                                                      this
                                                        .handleInputChangeStockOthers
                                                    }
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        },
                                      )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}

                        {(this.state.product_per_clinic == false ||
                          this.state.product_per_clinic == 0) && (
                          <div
                            className={"setting-container no-padding"}
                            id="Appointment_Booking-form-title"
                          >
                            <div className="row relative">
                              <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="newInputFileldOuter">
                                  <div className="newInputLabel">
                                    {
                                      this.state.inventoryLang
                                        .inventory_available_stock
                                    }
                                    <span className="setting-require">*</span>
                                  </div>
                                  <input
                                    className={
                                      this.state.available_stock_error
                                        ? "newInputField field-error"
                                        : "newInputField"
                                    }
                                    type="text"
                                    name="available_stock"
                                    placeholder={
                                      this.state.inventoryLang
                                        .inventory_available_stock
                                    }
                                    autoComplete="off"
                                    onChange={this.handleInputChange}
                                    value={this.state.available_stock}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6 col-xs-12">
                                <div className="newInputFileldOuter">
                                  <div className="newInputLabel">
                                    {
                                      this.state.inventoryLang
                                        .inventory_stock_alert
                                    }
                                    <span className="setting-require">*</span>
                                  </div>
                                  <input
                                    className={
                                      this.state.stock_alert_error
                                        ? "newInputField field-error"
                                        : "newInputField"
                                    }
                                    type="text"
                                    name="stock_alert"
                                    placeholder={
                                      this.state.inventoryLang
                                        .inventory_stock_alert
                                    }
                                    autoComplete="off"
                                    onChange={this.handleInputChange}
                                    value={this.state.stock_alert}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                  {/* Stock and Inventory - Switcher Block - END - OTHERS */}
                </div>

                {/* Popup starts start*/}
                <div
                  className={
                    this.state.activeMode == TABS.traceability &&
                    this.state.product_type != "others" &&
                    this.state.showBatches == true
                      ? ""
                      : "no-display"
                  }
                >
                  <div className="newInputFileldOuter m-t-0">
                    <div
                      className={
                        !this.state.is_inventory_enabled
                          ? "no-display"
                          : "p-t-0 pull-right"
                      }
                    >
                      <a
                        onClick={this.showAddStockModal}
                        className="easy-link no-padding pull-right m-l-10 m-t-5"
                      >
                        {" "}
                        <i className="fa fa-plus m-r-2" />
                        Add
                      </a>
                    </div>
                    <div
                      className={"setting-container no-padding p-b-0 m-t-20"}
                      id="Stock-Injectable-Clinic-Waise-form-title"
                    >
                      <div className="viewBatchHeader row m-b-10">
                        <div className="col-sm-4 col-xs-12">
                          <a
                            className="easy-link fontsize16 p-l-0"
                            onClick={() =>
                              this.setState({ showBatches: false })
                            }
                          >
                            <i className="fa fa-angle-left m-r-5"></i>{" "}
                            {this.state.selectedClinicForBatch
                              ? this.state.selectedClinicForBatch.clinic_name
                              : ""}
                          </a>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                          <span className="search-text full-width fontsize16 text-center m-b-10">
                            Total Available Stock:{" "}
                            <b>{this.state.total_available_stock}</b>
                          </span>
                        </div>
                        <div className="col-sm-4 col-xs-12">
                          {this.state.productId > 0 && (
                            <a
                              name="filter"
                              className="new-line-btn no-width pull-right -m-t-5"
                              onClick={() => this.openOrderStockPopup()}
                            >
                              Order Stock
                            </a>
                          )}
                        </div>
                      </div>

                      <div
                        className={
                          this.state.clinicStockList &&
                          this.state.clinicStockList.length > 0
                            ? "table-responsive m-t-0"
                            : "table-responsive m-t-0 no-display"
                        }
                      >
                        <table
                          className="table-updated juvly-table no-hover no-td-border no-border table-min-width m-b-25"
                          ref={(node) => {
                            if (node) {
                              node.style.setProperty(
                                "width",
                                "98%",
                                "important",
                              );
                            }
                          }}
                        >
                          <thead className="table-updated-thead">
                            <tr>
                              <th className="table-updated-th text-left col-xs-4">
                                {this.state.product_type &&
                                this.state.product_type === "coolsculpting"
                                  ? "Serial No"
                                  : this.state.inventoryLang.inventory_batch_id}
                              </th>
                              <th className="table-updated-th col-xs-3">
                                {
                                  this.state.inventoryLang
                                    .inventory_expiration_date
                                }
                              </th>
                              <th className="table-updated-th col-xs-3">
                                {"Stock"}
                              </th>
                              {/*Comment for inventory part 2*/}
                              <th className="table-updated-th text-left col-xs-3">
                                {"CTC Per Unit"}
                              </th>

                              <th className="table-updated-th text-left col-xs-2 text-left p-l-0 p-r-0">
                                {this.state.globalLang.label_action}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="ajax_body">
                            {this.state.clinicStockList &&
                              this.state.clinicStockList.map((obj, index) => {
                                return (
                                  <tr
                                    className="table-updated-tr"
                                    key={"stockList-" + index}
                                  >
                                    <td className="table-updated-td text-left word-break">
                                      {obj.batch_id}
                                    </td>
                                    <td className="table-updated-td ">
                                      {obj.expiry_date
                                        ? moment(obj.expiry_date).format(
                                            dateFormat,
                                          )
                                        : ""}
                                    </td>
                                    <td className="table-updated-td ">
                                      {obj.units || obj.available_stock}
                                    </td>
                                    {/*Comment for inventory part 2*/}
                                    <td className="table-updated-td text-left">
                                      {numberFormat(
                                        obj.cost_to_company,
                                        "currency",
                                        2,
                                      )}
                                    </td>

                                    <td className="table-updated-td text-center p-l-0 p-r-0">
                                      <div
                                        className="option-dropDown pull-left m-l-5 churnFilterExport"
                                        data-radium="true"
                                      >
                                        <div className="dropdown pull-left">
                                          <button
                                            className="btn btn-default dropdown-toggle"
                                            type="button"
                                            id="dropdownMenu1"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            {`Options`}
                                            <i className="fas fa-angle-down" />
                                          </button>
                                          <ul className="dropdown-menu">
                                            {this.state.productId > 0 && (
                                              <li>
                                                <a
                                                  onClick={this.showAdjustStockModal.bind(
                                                    this,
                                                    obj,
                                                    index,
                                                  )}
                                                >
                                                  {
                                                    this.state.globalLang
                                                      .label_add
                                                  }
                                                </a>
                                              </li>
                                            )}
                                            <li>
                                              <a
                                                onClick={this.showRemoveStockModal.bind(
                                                  this,
                                                  obj,
                                                  index,
                                                )}
                                              >
                                                {
                                                  this.state.globalLang
                                                    .label_remove
                                                }
                                              </a>
                                            </li>

                                            {this.state.productId > 0 && (
                                              <li>
                                                <a
                                                  onClick={this.showEditCTCModal.bind(
                                                    this,
                                                    obj,
                                                    index,
                                                  )}
                                                >{`Edit CTC/Unit`}</a>
                                              </li>
                                            )}

                                            {this.state.productId > 0 &&
                                              obj.units > 0 && (
                                                <li>
                                                  <a
                                                    onClick={this.openStockTransferPopup.bind(
                                                      this,
                                                      obj,
                                                    )}
                                                  >{`Stock Transfer`}</a>
                                                </li>
                                              )}
                                          </ul>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                      {this.state.clinicExpiredStockList &&
                      this.state.clinicExpiredStockList.length > 0 ? (
                        <div className="col-sm-12 col-xs-12">
                          <span className="search-text full-width fontsize16 text-center m-b-10">
                            Expired Stock
                          </span>
                        </div>
                      ) : (
                        ""
                      )}

                      <div
                        className={
                          this.state.clinicExpiredStockList &&
                          this.state.clinicExpiredStockList.length > 0
                            ? "table-responsive m-t-0"
                            : "table-responsive m-t-0 no-display"
                        }
                      >
                        <table
                          className="table-updated juvly-table no-hover no-td-border no-border table-min-width m-b-25"
                          ref={(node) => {
                            if (node) {
                              node.style.setProperty(
                                "width",
                                "98%",
                                "important",
                              );
                            }
                          }}
                        >
                          <thead className="table-updated-thead">
                            <tr>
                              <th className="table-updated-th text-left col-xs-4">
                                {this.state.product_type &&
                                this.state.product_type === "coolsculpting"
                                  ? "Serial No"
                                  : this.state.inventoryLang.inventory_batch_id}
                              </th>
                              <th className="table-updated-th col-xs-3">
                                {
                                  this.state.inventoryLang
                                    .inventory_expiration_date
                                }
                              </th>
                              <th className="table-updated-th col-xs-3">
                                {"Stock"}
                              </th>
                              <th className="table-updated-th text-left col-xs-3">
                                {"CTC Per Unit"}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="ajax_body">
                            {this.state.clinicExpiredStockList &&
                              this.state.clinicExpiredStockList.map(
                                (obj, index) => {
                                  return (
                                    <tr
                                      className="table-updated-tr"
                                      key={"stockList-" + index}
                                    >
                                      <td className="table-updated-td text-left word-break">
                                        {obj.batch_id}
                                      </td>
                                      <td className="table-updated-td ">
                                        {obj.expiry_date
                                          ? moment(obj.expiry_date).format(
                                              dateFormat,
                                            )
                                          : ""}
                                      </td>
                                      <td className="table-updated-td ">
                                        {obj.units
                                          ? roundOffNum(obj.units)
                                          : obj.available_stock
                                          ? roundOffNum(obj.available_stock)
                                          : 0}
                                      </td>
                                      <td className="table-updated-td text-left">
                                        {numberFormat(
                                          obj.cost_to_company,
                                          "currency",
                                          2,
                                        )}
                                      </td>
                                    </tr>
                                  );
                                },
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    this.state.activeMode == TABS.purchaseOrder
                      ? "newInputFileldOuter m-t-0"
                      : "no-display"
                  }
                >
                  {/*Comment for inventory part 2 removed*/}

                  <div
                    className={
                      this.state.inventory_type == "services" ||
                      (this.state.inventory_type != "services" &&
                        this.state.product_per_clinic === true)
                        ? ""
                        : "no-display"
                    }
                  >
                    <div className="settings-subtitle m-b-10 m-t-10">
                      Order Management
                    </div>
                    <div className={"table-responsive fixed-header-table"}>
                      <table
                        className={
                          "table-updated setting-table no-td-border no-hover min-w-850 editProTable table-fixed"
                        }
                      >
                        <thead className="table-updated-thead">
                          <tr>
                            <th className="col-xs-3 table-updated-th">
                              Clinic
                            </th>
                            <th className="col-xs-1 table-updated-th">Stock</th>
                            <th className="col-xs-2 table-updated-th">
                              Supplier
                            </th>
                            <th className="col-xs-2 table-updated-th">
                              Stock Order Threshold
                            </th>
                            <th
                              className="col-xs-4 table-updated-th"
                              colSpan={"2"}
                            >
                              Reorder Automatically
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.clinics.length > 0 &&
                            this.state.clinics.map((obj, idx) => {
                              return (
                                <tr key={"clinics-" + idx}>
                                  <td className="col-xs-3 table-updated-td break-all">
                                    {obj.clinic_name}
                                  </td>
                                  <td className="col-xs-1 table-updated-td">
                                    {showWithoutRounding(obj.units)}
                                  </td>
                                  <td className="col-xs-2 table-updated-td">
                                    {obj.supplier_name}
                                  </td>
                                  <td className="col-xs-2 table-updated-td p-r-0">
                                    <div>
                                      {this.state[
                                        "reorder_automatically_" + obj.id
                                      ] && obj.threshold
                                        ? obj.threshold
                                        : ""}
                                    </div>
                                  </td>
                                  <td className="col-xs-1 table-updated-td">
                                    <div className="setting-custom-switch product-active pull-left m-t-5 ">
                                      <label className="setting-switch no-margin">
                                        <input
                                          type="checkbox"
                                          className="setting-custom-switch-input"
                                          name={
                                            "reorder_automatically_" + obj.id
                                          }
                                          checked={
                                            this.state[
                                              "reorder_automatically_" + obj.id
                                            ]
                                              ? "checked"
                                              : false
                                          }
                                          autoComplete="off"
                                          onChange={this.handleInputChange}
                                        />
                                        <span className="setting-slider green-switch" />
                                      </label>
                                    </div>
                                  </td>
                                  <td className="col-xs-3 table-updated-td">
                                    <button
                                      className={
                                        this.state[
                                          "reorder_automatically_" + obj.id
                                        ]
                                          ? "new-blue-btn m-l-5"
                                          : "no-display"
                                      }
                                      onClick={this.showReorderPopMethod.bind(
                                        this,
                                        obj,
                                      )}
                                    >
                                      Reordering Info
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.inventory_type != "services" &&
                      this.state.product_per_clinic === false
                        ? "col-xs-12"
                        : "no-display"
                    }
                  >
                    <div className="text-center m-t-30">
                      <h4>
                        Order Management is not applicable for other type
                        product.
                      </h4>
                    </div>
                    <div
                      className={
                        "table-responsive fixed-header-table no-display"
                      }
                    >
                      <table
                        className={
                          "table-updated setting-table no-td-border no-hover min-w-850 editProTable table-fixed"
                        }
                      >
                        <thead className="table-updated-thead">
                          <tr>
                            <th className="col-xs-2 table-updated-th">Stock</th>
                            <th className="col-xs-4 table-updated-th">
                              Supplier
                            </th>
                            <th className="col-xs-2 table-updated-th">
                              Stock Order Threshold
                            </th>
                            <th className="col-xs-4 table-updated-th">
                              Reorder Automatically
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="col-xs-2 table-updated-td">
                              {showWithoutRounding(this.state.available_stock)}
                            </td>
                            <td className="col-xs-4 table-updated-td">
                              {this.state.product_reorder_setting != null &&
                              Object.entries(this.state.product_reorder_setting)
                                .length
                                ? this.state.product_reorder_setting.supplier
                                    .supplier_name
                                : ""}
                            </td>
                            <td className="col-xs-2 table-updated-td">
                              {this.state.product_reorder_setting != null &&
                              Object.entries(this.state.product_reorder_setting)
                                .length
                                ? this.state.product_reorder_setting.threshold
                                : ""}
                            </td>
                            <td className="col-xs-4 table-updated-td">
                              <div className="setting-custom-switch product-active pull-left m-t-5 ">
                                <label className="setting-switch no-margin">
                                  <input
                                    type="checkbox"
                                    className="setting-custom-switch-input"
                                    name={"reorder_automatically"}
                                    checked={
                                      this.state["reorder_automatically"]
                                        ? "checked"
                                        : false
                                    }
                                    autoComplete="off"
                                    onChange={this.handleInputChange}
                                  />
                                  <span className="setting-slider green-switch" />
                                </label>
                              </div>
                              {this.state["reorder_automatically"] == 1 && (
                                <button
                                  className={
                                    this.state["reorder_automatically"]
                                      ? "new-blue-btn m-l-5"
                                      : "no-display"
                                  }
                                  onClick={this.showReorderPopMethod.bind(
                                    this,
                                    this.state.product_reorder_setting,
                                  )}
                                >
                                  Reordering Info
                                </button>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.state.activeMode == TABS.tax
                      ? "newInputFileldOuter m-t-0"
                      : "no-display"
                  }
                >
                  {this.state.inventory_type == "medical_supplies" && (
                    <div className="col-xs-12 text-center m-t-30">
                      <h4>Tax input is not applicable for Medical Supplies.</h4>
                    </div>
                  )}
                  {this.state.inventory_type != "medical_supplies" && (
                    <React.Fragment>
                      <div className="switch-accordian-row p-r-0" id="book">
                        {this.state.inventoryLang.inventory_custom_tax}
                        <label className="setting-switch pull-left m-r-10 customTaxRuleswitch">
                          <input
                            type="checkbox"
                            id="app_booking"
                            name="is_custom_tax_rule_enabled"
                            onChange={this.handleInputChange}
                            checked={
                              this.state.is_custom_tax_rule_enabled
                                ? "checked"
                                : false
                            }
                            className="setting-custom-switch-input"
                          />
                          <span className="setting-slider green-switch" />
                        </label>
                      </div>
                      <div
                        className={
                          !this.state.is_custom_tax_rule_enabled
                            ? "setting-container no-display"
                            : "setting-container no-padding p-t-20"
                        }
                        id="Appointment_Booking-form-title"
                      >
                        <div className="row">
                          {this.state.clinics.length > 0 &&
                            this.state.clinics.map((obj, idx) => {
                              return (
                                <div
                                  className="col-md-4 col-sm-6 col-xs-12"
                                  key={"clinicTax-" + idx}
                                >
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">
                                      {obj.clinic_name}
                                      <span className="setting-require">*</span>
                                    </div>
                                    {this.state["customTaxError-" + obj.id]}
                                    <input
                                      className={
                                        this.state["customTaxError-" + obj.id]
                                          ? "newInputField field-error"
                                          : "newInputField"
                                      }
                                      type="number"
                                      min={0}
                                      name={"customTax-" + obj.id}
                                      placeholder="0.0000"
                                      autoComplete="off"
                                      onChange={this.handleInputChange}
                                      onBlur={this.handleTaxOnBlur}
                                      value={this.state["customTax-" + obj.id]}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.addCategoryPop
                ? "modalOverlay"
                : "modalOverlay no-display"
            }
          >
            <div className="small-popup-outer appointment-detail-main">
              <div className="small-popup-header">
                <div className="popup-name">
                  {this.state.inventoryLang.inventory_ADD_CATEGORY}
                </div>
                <a
                  onClick={() => {
                    this.setState(
                      { addCategoryPop: !this.state.addCategoryPop },
                      () => {
                        toggleBodyScroll(false);
                      },
                    );
                  }}
                  className="small-cross"
                >
                  ×
                </a>
              </div>
              <div className="small-popup-content">
                <div className="juvly-container no-padding-bottom">
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          {this.state.inventoryLang.inventory_category_name}{" "}
                          <span className="setting-require">*</span>
                        </div>
                        <input
                          className={
                            this.state.addCatCategoryNameError
                              ? "newInputField field-error"
                              : "newInputField"
                          }
                          type="text"
                          name="addCatCategoryName"
                          placeholder={
                            this.state.inventoryLang.inventory_category_name
                          }
                          autoComplete="off"
                          onChange={this.handleInputChange}
                          value={this.state.addCatCategoryName}
                        />
                      </div>
                      <div className="newInputFileldOuter">
                        <div
                          className="setting-custom-switch product-active pull-right"
                          id="book"
                        >
                          <span id="membership_lable">
                            {this.state.add_category_status
                              ? "Status Active"
                              : "Status In-Active"}
                          </span>
                          <label className="setting-switch pull-right m-l-5">
                            <input
                              type="checkbox"
                              id="app_booking"
                              name="add_category_status"
                              onChange={this.handleInputChange}
                              checked={
                                this.state.add_category_status
                                  ? "checked"
                                  : false
                              }
                              className="setting-custom-switch-input"
                            />
                            <span className="setting-slider green-switch" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"switch-accordian-outer"}>
                  <div className="switch-accordian-row p-r-0" id="book">
                    {this.state.inventoryLang.inventory_custom_tax_rule}
                    <label className="setting-switch pull-right">
                      <input
                        type="checkbox"
                        id="app_booking"
                        name="is_custom_tax_rule_enabled_category"
                        onChange={this.handleInputChange}
                        checked={
                          this.state.is_custom_tax_rule_enabled_category
                            ? "checked"
                            : false
                        }
                        className="setting-custom-switch-input"
                      />
                      <span className="setting-slider green-switch" />
                    </label>
                  </div>
                  <div
                    className={
                      !this.state.is_custom_tax_rule_enabled_category
                        ? "setting-container no-display"
                        : "setting-container p-r-0"
                    }
                    id="Appointment_Booking-form-title"
                  >
                    <div className="row">
                      {this.state.clinics.length > 0 &&
                        this.state.clinics.map((obj, idx) => {
                          return (
                            <div
                              className="col-sm-6 col-xs-12"
                              key={"addCatClinic-" + idx}
                            >
                              <div className="newInputFileldOuter">
                                <div className="newInputLabel">
                                  {obj.clinic_name}
                                  <span className="setting-require">*</span>
                                </div>
                                <input
                                  className={
                                    this.state["addCatClinicError-" + obj.id]
                                      ? "newInputField field-error"
                                      : "newInputField"
                                  }
                                  type="text"
                                  name={"addCatClinic-" + obj.id}
                                  autoComplete="off"
                                  placeholder="0.0000"
                                  onChange={this.handleInputChange}
                                  value={
                                    this.state["addCatClinic-" + obj.id] ===
                                    undefined
                                      ? "0"
                                      : this.state["addCatClinic-" + obj.id]
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-static">
                <a
                  className="new-blue-btn pull-right"
                  onClick={this.addCategory}
                >
                  {this.state.inventoryLang.inventory_save}
                </a>
              </div>
            </div>
          </div>
          <div className={this.state.showModal ? "overlay" : ""}></div>
          <div
            id="filterModal"
            role="dialog"
            className={
              this.state.showModal ? "modal fade in displayBlock" : "modal fade"
            }
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.dismissModal}
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="model_title">
                    {this.state.inventoryLang.inventory_delete_product}
                  </h4>
                </div>
                <div
                  id="errorwindow"
                  className="modal-body add-patient-form filter-patient"
                >
                  {this.state.inventoryLang.inventory_r_u_sure_want_del}
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button
                      type="button"
                      className="btn  logout pull-right"
                      data-dismiss="modal"
                      onClick={this.dismissModal}
                    >
                      {this.state.inventoryLang.inventory_Cancel}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success pull-right m-r-10"
                      data-dismiss="modal"
                      onClick={this.deleteProduct}
                    >
                      {this.state.inventoryLang.inventory_Yes}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={this.state.showInactivateModal ? "overlay" : ""}
          ></div>
          <div
            id="filterModal"
            role="dialog"
            className={
              this.state.showInactivateModal
                ? "modal fade in displayBlock"
                : "modal fade"
            }
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.dismissModal}
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="model_title">
                    Inactivate Product
                  </h4>
                </div>
                <div
                  id="errorwindow"
                  className="modal-body add-patient-form filter-patient"
                >
                  Are you sure want to inactivate this product?
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button
                      type="button"
                      className="btn  logout pull-right"
                      data-dismiss="modal"
                      onClick={this.dismissInactivateModal}
                    >
                      {this.state.inventoryLang.inventory_Cancel}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success pull-right m-r-10"
                      data-dismiss="modal"
                      onClick={this.inactivateProduct}
                    >
                      {this.state.inventoryLang.inventory_Yes}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Modal - Add Lot number and Expiration - START */}
          <div
            className={
              this.state.isShowAddStockModal
                ? "modalOverlay"
                : "modalOverlay no-display"
            }
          >
            <div className="small-popup-outer createStockInventory">
              <div className="small-popup-header">
                <div className="popup-name">{"Create Stock Inventory"}</div>
                <a onClick={this.dismissStockModal} className="small-cross">
                  ×
                </a>
              </div>
              <div className="small-popup-content no-scroll createInvStock">
                <div className="juvly-container">
                  <div className="row">
                    <div className="col-sm-4 col-xs-12">
                      <div className="simpleField">
                        <div className="simpleLabel">
                          {"Expiration Date"}
                          <span className="required">*</span>
                        </div>
                        <div className="setting-input-outer simpleDatePicker">
                          <DatePicker
                            value={
                              this.state.addStockFields.expiry_date
                                ? new Date(
                                    this.state.addStockFields.expiry_date,
                                  )
                                : null
                            }
                            onChange={this.handleDatePickerAddStockModal}
                            className={
                              this.state.addStockFields.expiry_dateClass
                            }
                            dateFormat={dateFormatPicker()}
                            placeholderText={dateFormatPicker().toLowerCase()}
                            name={"expiry_date"}
                            selected={
                              this.state.addStockFields.expiry_date
                                ? this.state.addStockFields.expiry_date
                                : null
                            }
                            autoComplete="off"
                            minDate={moment().toDate()}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            ref={(refDatePickerStockModal) =>
                              (this.refDatePickerStockModal =
                                refDatePickerStockModal)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                      <div className="simpleField">
                        <div className="simpleLabel">
                          {this.state.product_type &&
                          this.state.product_type === "coolsculpting"
                            ? "Serial No"
                            : this.state.inventoryLang.inventory_batch_id}
                          <span className="required">*</span>
                        </div>
                        <div className="setting-input-outer">
                          <input
                            className={this.state.addStockFields.batch_idClass}
                            type="text"
                            name="batch_id"
                            value={this.state.addStockFields.batch_id}
                            autoComplete="off"
                            onChange={this.handleInputChangeAddStockModal}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 col-xs-12">
                      <div className="simpleField">
                        <div className="simpleLabel">
                          {this.state.product_type &&
                          this.state.product_type === "coolsculpting"
                            ? "Cycle"
                            : this.state.globalLang.label_stock}{" "}
                          {stockUnitType &&
                          this.state.product_type !== "coolsculpting"
                            ? this.state.globalLang.label_in_small +
                              " " +
                              stockUnitType
                            : ""}
                          <span className="required">*</span>
                        </div>
                        <div className="setting-input-outer">
                          <input
                            className={
                              this.state.addStockFields.available_stockClass
                            }
                            type="text"
                            name="available_stock"
                            value={this.state.addStockFields.available_stock}
                            autoComplete="off"
                            onChange={this.handleInputChangeAddStockModal}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/*Comment for inventory part 2 removed*/}
                    <div className="col-sm-6 col-xs-12">
                      <div className="simpleField">
                        <div className="simpleLabel">
                          Cost to Company Per Unit
                          <span className="required">*</span>
                        </div>
                        <div className="setting-input-outer">
                          <input
                            className={
                              this.state.addStockFields.cost_to_companyClass
                            }
                            type="text"
                            name="cost_to_company"
                            value={this.state.addStockFields.cost_to_company}
                            autoComplete="off"
                            onChange={this.handleInputChangeAddStockModal}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-static">
                <a
                  className={"new-blue-btn pull-right"}
                  onClick={this.handleSubmitAddStockModal}
                >
                  {this.state.globalLang.label_save}
                </a>
                <a
                  className="new-white-btn pull-right"
                  onClick={this.dismissStockModal}
                >
                  {this.state.globalLang.label_cancel}
                </a>
              </div>
            </div>
          </div>
          {/* Modal - Add Lot number and Expiration - END */}

          {/* Modal - Adjust Lot number and Expiration - START */}
          <div
            className={
              this.state.isShowAdjustStockModal
                ? "modalOverlay"
                : "modalOverlay no-display"
            }
          >
            <div className="small-popup-outer createStockInventory">
              <div className="small-popup-header">
                <div className="popup-name">{"Adjust Stock Inventory"}</div>
                <a onClick={this.dismissStockModal} className="small-cross">
                  ×
                </a>
              </div>
              <div className="small-popup-content no-scroll">
                <div className="juvly-container">
                  <div className="row">
                    <div className="col-sm-6 col-xs-12">
                      <div className="simpleField">
                        <div className="simpleLabel">
                          Add Units<span className="required">*</span>
                        </div>
                        <div className="setting-input-outer">
                          <input
                            className={
                              this.state.adjustStockFelds.available_stockClass
                            }
                            type="text"
                            name="available_stock"
                            value={this.state.adjustStockFelds.available_stock}
                            autoComplete="off"
                            onChange={this.handleInputChangeAdjustStockModal}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-xs-12">
                      <div className="simpleField">
                        <div className="simpleLabel">
                          Cost to Company Per Unit
                          <span className="required">*</span>
                        </div>
                        <div className="setting-input-outer">
                          <input
                            className={
                              this.state.adjustStockFelds.cost_to_companyClass
                            }
                            type="text"
                            name="cost_to_company"
                            value={this.state.adjustStockFelds.cost_to_company}
                            autoComplete="off"
                            onChange={this.handleInputChangeAdjustStockModal}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-static">
                <a
                  className={"new-blue-btn pull-right"}
                  onClick={this.handleSubmitAdjustStockModal}
                >
                  {this.state.globalLang.label_add}
                </a>
                <a
                  className="new-white-btn pull-right"
                  onClick={this.dismissStockModal}
                >
                  {this.state.globalLang.label_cancel}
                </a>
              </div>
            </div>
          </div>
          {/* Modal - Adjust Lot number and Expiration - END */}

          {/* Modal - Remove Lot number and Expiration - START */}
          <div
            className={
              this.state.isShowRemoveStockModal
                ? "modalOverlay"
                : "modalOverlay no-display"
            }
          >
            <div className="small-popup-outer createStockInventory">
              <div className="small-popup-header">
                <div className="popup-name">{"Remove Stock Inventory"}</div>
                <a onClick={this.dismissStockModal} className="small-cross">
                  ×
                </a>
              </div>
              <div className="small-popup-content no-scroll">
                <div className="juvly-container">
                  <div className="row">
                    <div className="col-sm-6 col-xs-12">
                      <div className="simpleField">
                        <div className="simpleLabel">
                          {this.state.product_type &&
                          this.state.product_type === "coolsculpting"
                            ? "Cycle"
                            : this.state.globalLang.label_stock}{" "}
                          {stockUnitType &&
                          this.state.product_type !== "coolsculpting"
                            ? this.state.globalLang.label_in_small +
                              " " +
                              stockUnitType
                            : ""}
                          <span className="required">*</span>
                        </div>
                        <div className="setting-input-outer">
                          <input
                            className={
                              this.state.removeStockFelds.available_stockClass
                            }
                            type="text"
                            name="available_stock"
                            value={this.state.removeStockFelds.available_stock}
                            autoComplete="off"
                            onChange={this.handleInputChangeRemoveStockModal}
                          />
                          <span>
                            Max: {this.state.removeStockFelds.stock_limit}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                      <div className="simpleField">
                        <div className="simpleLabel">
                          {"Reason"}
                          <span className="required">*</span>
                        </div>
                        <div className="setting-input-outer">
                          <select
                            className={this.state.removeStockFelds.reasonClass}
                            onChange={this.handleInputChangeRemoveStockModal}
                            name={"reason"}
                            value={this.state.removeStockFelds.reason}
                          >
                            <option value={0}>
                              {this.state.inventoryLang.inventory_select}
                            </option>
                            <option value="damage">
                              {
                                this.state.globalLang
                                  .inventory_reconciliation_damage
                              }
                            </option>
                            <option value="shrinkage">
                              {
                                this.state.globalLang
                                  .inventory_reconciliation_shrinkage
                              }
                            </option>
                            <option value="sample">
                              {
                                this.state.globalLang
                                  .inventory_reconciliation_sample
                              }
                            </option>
                            {this.state.inventory_type === "services" && (
                              <option value="expired">
                                {this.state.inventoryLang.inventory_expired}
                              </option>
                            )}
                            <option value="others">
                              {this.state.inventoryLang.inventory_others}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {this.state.removeStockFelds.reason === "others" && (
                      <div className="col-sm-6 col-xs-12">
                        <div className="simpleField">
                          <div className="simpleLabel">
                            {"Other Reason"}
                            <span className="required">*</span>
                          </div>
                          <div className="setting-input-outer">
                            <input
                              className={
                                this.state.removeStockFelds.reason_otherClass
                              }
                              type="text"
                              name="reason_other"
                              value={this.state.removeStockFelds.reason_other}
                              autoComplete="off"
                              onChange={this.handleInputChangeRemoveStockModal}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="footer-static">
                <a
                  className={"new-red-btn pull-left"}
                  onClick={this.handleSubmitRemoveStockModal}
                >
                  {this.state.globalLang.label_remove}
                </a>
                <a
                  className="new-white-btn pull-right"
                  onClick={this.dismissStockModal}
                >
                  {this.state.globalLang.label_cancel}
                </a>
              </div>
            </div>
          </div>
          {/* Modal - Remove Lot number and Expiration - END */}

          {/* View logs modal ends*/}

          {/* View detailed log modal starts */}
          <div
            className={
              this.state.showDetailedLogs
                ? "blackOverlay"
                : "blackOverlay no-display"
            }
          >
            <div className="vert-middle">
              <div className="loyaltyMembership treatmentPlan e-Prescribe detailLog">
                <div className="white-popup-wrapper p-b-0">
                  <div className="membershipTypeTitle">
                    Detailed Logs
                    <a
                      onClick={() => this.setState({ showDetailedLogs: false })}
                      className="popupClose"
                    >
                      <img alt="" src="/images/popupClose.png" />
                    </a>
                  </div>
                </div>
                <div className="table-responsive fixed-header-table">
                  {this.state.showDetailedLogs && (
                    <DetailedLogs
                      clinics={this.state.clinics}
                      loadMoreProductLogs={this.loadMoreProductLogs}
                      nextPageUrl={this.state.modal_next_page_url}
                      currentPage={this.state.modalPage}
                      logsData={this.state.detailedProductLogs}
                      handleChildFilter={this.handleChildFilter}
                      handleChildDownload={this.handleChildDownload}
                    >
                      {" "}
                    </DetailedLogs>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Modal - Stock Reorder - START */}
          <div
            className={
              this.state.showReorderPop
                ? "blackOverlay"
                : "blackOverlay no-display"
            }
          >
            <div className="vert-middle vert-middle-report-option">
              <div className="white-popup large-popup">
                <div className="white-popup-wrapper p-b-0">
                  <div className="membershipTypeTitle">
                    {this.state.product_name}
                    <a onClick={this.hideReorderPop} className="popupClose">
                      <img alt="" src="/images/popupClose.png" />
                    </a>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          Supplier<span className="setting-require">*</span>
                        </div>
                        <select
                          className={this.state.reorderSupplierClass}
                          name="reorderSupplier"
                          onChange={this.handleInputChange}
                          value={this.state.reorderSupplier}
                          ref={this.product_category}
                        >
                          <option value="">{"Select Supplier"}</option>
                          {this.state.suppliers.length > 0 &&
                            this.state.suppliers.map((obj, idx) => {
                              return (
                                <option value={obj.id} key={"sup-" + idx}>
                                  {obj.supplier_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div
                      className={
                        this.state.inventory_type == "services" ||
                        (this.state.inventory_type != "services" &&
                          this.state.product_per_clinic === true)
                          ? "col-lg-6 col-sm-6 col-xs-12"
                          : "no-display"
                      }
                    >
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          Clinic<span className="setting-require">*</span>
                        </div>
                        <div className="newInputField">
                          {" "}
                          {this.state.reorderObj &&
                            this.state.reorderObj.clinic_name}{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          Medical Director
                          <span className="setting-require">*</span>
                        </div>
                        <select
                          className={this.state.md_idClass}
                          name="md_id"
                          onChange={this.handleInputChange}
                          value={this.state.md_id}
                        >
                          <option value="">Select</option>
                          {this.state.mdList &&
                            this.state.mdList.map((obj, id) => {
                              return (
                                <option key={"mdList-" + id} value={obj.id}>
                                  {displayName(obj)}
                                </option>
                              );
                            })}
                          <option value="custom">Custom</option>
                        </select>
                      </div>
                    </div>

                    {this.state.md_id && this.state.md_id === "custom" ? (
                      <div className="col-lg-6 col-sm-6 col-xs-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">
                            Medical Director Name
                            <span className="setting-require">*</span>
                          </div>
                          <input
                            type="text"
                            name={"custom_md"}
                            value={this.state.custom_md}
                            autoComplete="off"
                            onChange={this.handleInputChange}
                            className={this.state.custom_mdClass}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          Order Threshold
                          <span className="setting-require">*</span>
                        </div>
                        <input
                          className={this.state.reorderThresholdClass}
                          type="text"
                          name="reorderThreshold"
                          placeholder={"Order Threshold"}
                          autoComplete="off"
                          onChange={this.handleInputChange}
                          value={this.state.reorderThreshold}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          Quantity<span className="setting-require">*</span>
                        </div>
                        <input
                          className={this.state.reorderQtyClass}
                          type="text"
                          name="reorderQty"
                          placeholder={"Quantity"}
                          autoComplete="off"
                          onChange={this.handleInputChange}
                          value={this.state.reorderQty}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          Rate<span className="setting-require">*</span>
                        </div>
                        <input
                          className={this.state.reorderRateClass}
                          type="text"
                          name="reorderRate"
                          placeholder={"Rate"}
                          autoComplete="off"
                          onChange={this.handleInputChange}
                          value={this.state.reorderRate}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          Tax<span className="setting-require">*</span>
                        </div>
                        <input
                          className={this.state.reorderTaxClass}
                          type="text"
                          name="reorderTax"
                          placeholder={"Tax"}
                          autoComplete="off"
                          onChange={this.handleInputChange}
                          value={this.state.reorderTax}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12">
                      <div className="newInputFileldOuter m-t-20">
                        <div className="newInputLabel">Note</div>
                        <textarea
                          className="newtextareaField textarea-height-50"
                          name="note"
                          placeholder={this.state.inventoryLang.note}
                          value={this.state.note}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          Payment Terms Type
                          <span className="setting-require">*</span>
                        </div>
                        <select
                          className={this.state.payment_term_typeClass}
                          name="payment_term_type"
                          onChange={this.handleInputChange}
                          value={this.state.payment_term_type}
                        >
                          <option value="last_4_digits_of_cc">
                            Last 4 digits of CC
                          </option>
                          <option value="payment_term_text">
                            Payment terms
                          </option>
                          <option value="both">Both</option>
                        </select>
                      </div>
                    </div>
                    {this.state.payment_term_type === "last_4_digits_of_cc" ||
                    this.state.payment_term_type === "both" ? (
                      <div className="col-lg-6 col-sm-6 col-xs-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">
                            Last 4 digits Of CC
                            <span className="setting-require">*</span>
                          </div>
                          <input
                            type="text"
                            name={"last_4_digits_of_cc"}
                            value={this.state.last_4_digits_of_cc}
                            autoComplete="off"
                            onChange={this.handleInputChange}
                            className={this.state.last_four_valueClass}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {(this.state.payment_term_type === "payment_term_text" ||
                      this.state.payment_term_type === "both") && (
                      <div className="col-xs-12">
                        <div className="newInputFileldOuter m-t-20">
                          <div className="newInputLabel">Payment Terms</div>
                          <textarea
                            name="payment_terms"
                            className={this.state.paymentTermsClass}
                            value={this.state.payment_terms}
                            onChange={this.handleInputChange}
                          ></textarea>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="footer-static">
                  <a
                    className="new-blue-btn pull-right"
                    onClick={this.saveReorderInfo}
                  >
                    {"Save"}
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* Modal - Stock Reorder - END */}

          {/* Modal - Edit Stock - END */}
          <div
            className={
              this.state.showEditStockModal
                ? "modalOverlay"
                : "modalOverlay no-display"
            }
          >
            <div className="small-popup-outer createStockInventory xs-popup">
              <div className="small-popup-header">
                <div className="popup-name">{"Edit Stock Alert"}</div>
                <a onClick={this.dismissEditStockModal} className="small-cross">
                  ×
                </a>
              </div>
              <div className="small-popup-content no-scroll">
                <div className="juvly-container">
                  <div className="row">
                    <div className="col-sm-12 col-xs-12">
                      <div className="simpleField">
                        <div className="simpleLabel">
                          Stock Alert<span className="required">*</span>
                        </div>
                        <div className="setting-input-outer">
                          <input
                            className={this.state.editStockAlertClass}
                            type="text"
                            name="editStockAlert"
                            value={this.state.editStockAlert}
                            autoComplete="off"
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-static">
                <a
                  className={"new-blue-btn pull-right"}
                  onClick={this.handleSubmitStockAlertUpdate}
                >{`Update`}</a>
                <a
                  className="new-white-btn pull-left"
                  onClick={this.dismissEditStockModal}
                >
                  {this.state.globalLang.label_cancel}
                </a>
              </div>
            </div>
          </div>
          {/* Modal - Edit Stock - END */}

          {/* Modal - Edit CTC - END */}
          <div
            className={
              this.state.showEditCTCModal
                ? "modalOverlay"
                : "modalOverlay no-display"
            }
          >
            <div className="small-popup-outer createStockInventory xs-popup">
              <div className="small-popup-header">
                <div className="popup-name">{"Edit Cost To Company"}</div>
                <a onClick={this.dismissEditCTCModal} className="small-cross">
                  ×
                </a>
              </div>
              <div className="small-popup-content no-scroll">
                <div className="juvly-container">
                  <div className="row">
                    <div className="col-sm-12 col-xs-12">
                      <div className="simpleField">
                        <div className="simpleLabel">
                          Cost to Company Per Unit
                          <span className="required">*</span>
                        </div>
                        <div className="setting-input-outer">
                          <input
                            className={this.state.editCTCClass}
                            type="text"
                            name="editCTCVal"
                            value={this.state.editCTCVal}
                            autoComplete="off"
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-static">
                <a
                  className={"new-blue-btn pull-right"}
                  onClick={this.handleSubmitCTCUpdate}
                >{`Update`}</a>
                <a
                  className="new-white-btn pull-left"
                  onClick={this.dismissEditCTCModal}
                >
                  {this.state.globalLang.label_cancel}
                </a>
              </div>
            </div>
          </div>
          {/* Modal - Edit CTC - END */}

          {/* Order stock popup starts */}
          <div
            className={
              this.state.showOrderStockPopup
                ? "modalOverlay"
                : "modalOverlay no-display"
            }
          >
            <div className="small-popup-outer createStockInventory large-popup">
              <div className="small-popup-header">
                <div className="popup-name">Order Stock</div>
                <a
                  onClick={this.dismissOrderStockPopup}
                  className="small-cross"
                >
                  ×
                </a>
              </div>

              <OrderStockPopup
                stockOrderData={this.state.stockOrderData}
                stockOrderDataTime={this.state.stockOrderDataTime}
                productId={this.state.productId}
                productName={this.state.product_name}
                saveOrderInfo={this.saveOrderInfo}
                selectedClinicOrderId={this.state.selectedClinicOrderId}
                dismissOrderStockPopup={this.dismissOrderStockPopup}
                countUnitsBy={this.state.count_units_by}
              ></OrderStockPopup>
            </div>
          </div>
          {/* Order stock popup ends */}

          {/* Stock Transfer popup starts */}
          <div
            className={
              this.state.showStockTransferPopup
                ? "modalOverlay"
                : "modalOverlay no-display"
            }
          >
            <div className="small-popup-outer createStockInventory large-popup">
              <div className="small-popup-header">
                <div className="popup-name">Stock Transfer</div>
                <a
                  onClick={this.dismissStockTransferPopup}
                  className="small-cross"
                >
                  ×
                </a>
              </div>

              <StockTransferPopup
                stockTransferData={this.state.stockTransferData}
                stockTransferDataTime={this.state.stockTransferDataTime}
                productId={this.state.productId}
                productName={this.state.product_name}
                inventory_id={this.state.inventory_id}
                batchId={this.state.batchId}
                availableStock={this.state.availableStock}
                type={this.state.stockTransferType}
                transferStock={this.transferStock}
                selectedClinicOrderId={this.state.selectedClinicOrderId}
                dismissStockTransferPopup={this.dismissStockTransferPopup}
              ></StockTransferPopup>
            </div>
          </div>

          {/* Stock transfer popup ends */}

          <div
            className={
              this.state.showLoader
                ? "new-loader text-left displayBlock positionFixed"
                : "new-loader text-left"
            }
          >
            <div className="loader-outer">
              <img
                alt=""
                id="loader-outer"
                src="/images/Eclipse.gif"
                className="loader-img"
              />
              <div id="modal-confirm-text" className="popup-subtitle">
                {this.state.globalLang.loading_please_wait_text}
              </div>
            </div>
          </div>
        </div>
        {this.state.isInventoryLogModalOpen && (
          <ModalInventoryLogs
            isOpen
            productId={this.state.productId}
            onClose={() =>
              this.setState({
                isInventoryLogModalOpen: false,
              })
            }
          />
        )}
        {Boolean(this.state.redirectProductId) && (
          <ConfirmModal
            isOpen
            onClose={() => {
              history.goBack();
              this.setState({
                redirectProductId: undefined,
                showLoader: false,
              });
            }}
            onCancel={() => {
              history.goBack();
              this.setState({
                redirectProductId: undefined,
                showLoader: false,
              });
            }}
            onConfirm={() => {
              history.push(
                `/inventory/product/edit/${this.state.redirectProductId}?tab=pricing`,
              );
              this.setState({
                redirectProductId: undefined,
                showLoader: false,
              });
            }}
          >
            Now you can add prices for each membership. Do you want to go and
            set them?
          </ConfirmModal>
        )}
        {Boolean(this.state.showPricesConfirm) && (
          <ConfirmModal
            isOpen
            onClose={() => {
              this.setState({
                showPricesConfirm: false,
                showLoader: false,
              });
            }}
            onCancel={() => {
              this.setState({
                showPricesConfirm: false,
                showLoader: false,
              });
            }}
            onConfirm={() => {
              this.setState({ showPricesConfirm: false });
              this.handleSubmit({ showPricesConfirm: false });
            }}
          >
            Are you sure you want to set the inventory price at $0.00?
          </ConfirmModal>
        )}
        {this.state.showConfirmSaveWithUnsaved && (
          <ConfirmModal
            isOpen
            size="small"
            title="Warning"
            confirmTitle="Save"
            onClose={() => this.setState({ showConfirmSaveWithUnsaved: false })}
            onConfirm={async () => {
              this.setState({ showConfirmSaveWithUnsaved: false });
              await this.savePricingTabs();
              this.handleSubmit({ showPricesConfirm: true });
            }}
            onCancel={() => {
              this.setState({ showConfirmSaveWithUnsaved: false });
              this.handleSubmit({ showPricesConfirm: true });
            }}
          >
            <UnsavedChangesMessage />
          </ConfirmModal>
        )}
        <RouterPrompt
          size="small"
          whenFn={() => this.isPricesChanged().length > 0}
          customConfirm={(nextPath) =>
            this.savePricingTabs().then(() => (window.location.href = nextPath))
          }
          confirmTitle="Save"
          title="Warning"
          text={<UnsavedChangesMessage />}
          handleCancel={(nextPath) => {
            this.props.history.push(nextPath);
          }}
        />
        {!!this.state.cloneId && !!this.state.cloneInitData && (
          <InitRegular initData={this.state.cloneInitData} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {
    pricesForAllClinics:
      productPricingRegular.selectors.selectForAllClinics(state),

    getPricesForRequest:
      productPricingRegular.selectors.selectChangedPricesForRequest(state),

    getChangedRegularPrices:
      productPricingRegular.selectors.selectChangedPrices(state),

    getOriginalRegularPrices:
      productPricingRegular.selectors.selectOriginalPrices(state),

    forAllClinicsRegular:
      productPricingRegular.selectors.selectForAllClinics(state),

    getChangedMemberPrices:
      productPricingMember.selectors.selectChangedPrices(state),

    getOriginalMemberPrices:
      productPricingMember.selectors.selectOriginalPrices(state),

    forAllClinicsMember:
      productPricingMember.selectors.selectForAllClinics(state),
  };

  const languageData = JSON.parse(localStorage.getItem("languageData"));
  localStorage.setItem("showLoader", false);

  if (state.InventoryReducer.action === "IS_PRODUCT_AVAILABLE") {
    if (state.InventoryReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.productNameAvailability = state.InventoryReducer.data;
      returnState.message =
        languageData.global[state.InventoryReducer.data.message];
    }
  }

  if (state.InventoryReducer.action === "PRODUCT_UPDATE") {
    if (
      state.InventoryReducer.data.status != 201 &&
      state.InventoryReducer.data.status != 200
    ) {
      uiNotification.clear();
      returnState.showLoader = false;
    } else {
      returnState.message =
        languageData.global[state.InventoryReducer.data.message];
    }
  }

  if (state.InventoryReducer.action === "CREATE_CATEGORY") {
    if (state.InventoryReducer.data.status != 201) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.categoryData = state.InventoryReducer.data;
    }
  }
  if (state.InventoryReducer.action === "ADD_PRODUCT_INVENTORY") {
    if (state.InventoryReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.updatedStockList = state.InventoryReducer.data.data;
      returnState.productAdded = true;
      uiNotification.success(
        languageData.global[state.InventoryReducer.data.message],
      );
    }
  }

  if (state.InventoryReducer.action === "ADJUST_PRODUCT_INVENTORY") {
    if (state.InventoryReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.updatedStockList = state.InventoryReducer.data.data;
      returnState.productAdded = true;
      uiNotification.success(
        languageData.global[state.InventoryReducer.data.message],
      );
    }
  }
  if (state.InventoryReducer.action === "SAVE_REORDER_SETTING") {
    if (state.InventoryReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.productReorderSetting = state.InventoryReducer.data.data;
    }
  }
  if (state.InventoryReducer.action === "MAKE_PRODUCT_INACTIVATE") {
    if (state.InventoryReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.isProductInactive = true;
      uiNotification.success(
        languageData.global[state.InventoryReducer.data.message],
      );
    }
  }

  if (state.InventoryReducer.action === "UPDATE_STOCK_ALERT") {
    if (state.InventoryReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.updatedStockList = state.InventoryReducer.data.data;
      uiNotification.success(
        languageData.global[state.InventoryReducer.data.message],
      );
    }
  }

  if (state.InventoryReducer.action === "UPDATE_CTC") {
    if (state.InventoryReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.updatedStockList = state.InventoryReducer.data.data;
      returnState.productAdded = true;
      uiNotification.success(
        languageData.global[state.InventoryReducer.data.message],
      );
    }
  }

  if (state.InventoryReducer.action === "GET_ORDER_DETAILS") {
    if (state.InventoryReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.stockOrderData = state.InventoryReducer.data.data;
      returnState.stockOrderDataTime = new Date();
    }
  }

  if (state.InventoryReducer.action === "SAVE_PURCHASE_ORDER") {
    if (state.InventoryReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      uiNotification.success(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.stockAdded = state.InventoryReducer.data.data;
      returnState.stockAddedDataTime = new Date();
    }
  }

  if (state.InventoryReducer.action === "VIEW_STOCK_TRANSFER") {
    if (state.InventoryReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.stockTransferData = state.InventoryReducer.data.data;
      returnState.stockTransferDataTime = new Date();
    }
  }

  if (state.InventoryReducer.action === "CREATE_STOCK_TRANSFER") {
    if (state.InventoryReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      uiNotification.success(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.updatedStockList = state.InventoryReducer.data.data;
      returnState.productAdded = true;
    }
  }

  if (state.InventoryReducer.action === "GET_DETAILED_PRODUCT_LOGS") {
    if (state.InventoryReducer.data.status != 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.InventoryReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.detailedProductLogs = state.InventoryReducer.data.data;
      returnState.detailedProductLogsTime = new Date();
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInventoryData,
      getProductDefaultData,
      isProductNameAvailable,
      addProduct,
      updateProduct,
      createCategory,
      deleteProduct,
      emptyInventoryReducer,
      addProductStock,
      adjustProductStock,
      makeProductInactivate,
      saveReorderSettings,
      updateStockAlert,
      updateCTC,
      fetchOrderDetails,
      saveOrderInfo,
      viewStockTransfer,
      createStockTransfer,
      updateStockAlertForOthers,
      showDetailedLogs,
    },
    dispatch,
  );
}

export default pipe(
  EditInventory,
  withCurrentUserQuery,
  withRouter,
  withRegularPricesSave,
  withMemberPricesSave,
  connect(mapStateToProps, mapDispatchToProps),
);
