/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  useGetWaitlistQuery,
  useDeleteWaitlistQuery,
  GeServicesList,
} from "../../../_legacy/Queries";
import { Routes } from "../../../_legacy/Constants";
import AddEditWaitlistAppointment from "../../Waitlist/AddEditWaitlistAppointment";
import WaitlistSearchModal from "./SearchModal";
import ConfirmationModal from "../Calendar/ConfirmationModal/ConfirmationModal";
import ConvertToAppointmentModal from "../../../pages/Event/components/waitList/ConvertToAppointmentModal";
import { uiNotification } from "../../../services/UINotificationService";
import { clearSetupIntent } from "../../../Actions/Stripe/StripeActions";
import { CircularProgress } from "../../../shared/CircularProgress/CircularProgress";
import { useDownloadWaitListExcel } from "./hooks/useDownloadWaitListExcel";
import { Table } from "../../Common";

const List = ({ history, clearSetupIntent }) => {
  const [pageFilters, setPageFilters] = useState();
  const [editWaitlistItem, setEditWaitlistItem] = useState({
    open: false,
    editEnabled: false,
    id: null,
    data: null,
  });
  const [searchParams, setSearchParams] = useState({ open: false });

  const [openConvertWarningModal, setConverWarningModal] = useState(false);
  const [openDeleteWarningModal, setDeleteWarningModal] = useState({
    open: false,
  });

  const [waitlistTableData, setWaitlistData] = useState({
    listData: [],
    paginationInformation: {},
    twilio_config: 0,
  });

  const { listData, paginationInformation } = waitlistTableData;

  const {
    data: waitlistData,
    isFetching,
    dataUpdatedAt,
  } = useGetWaitlistQuery(Object.assign({}, pageFilters, searchParams));

  const { mutate: deleteWaitlistItem } = useDeleteWaitlistQuery();
  const downloadExcel = useDownloadWaitListExcel({ pageFilters, searchParams });

  const totalWaitlistAmount = waitlistData?.data?.data?.amount || 0;

  useEffect(() => {
    if (dataUpdatedAt) {
      const { current_page, per_page, last_page } =
        waitlistData?.data?.data?.data || {};
      const twilio_config = waitlistData?.data?.global_settings?.twilio_config;

      const pageDetails = {
        page: current_page,
        per_page: per_page,
        canFetchMore: Boolean(current_page < last_page),
      };

      setWaitlistData((currentListData) => {
        return {
          listData:
            current_page === 1
              ? waitlistData?.data?.data?.data?.data
              : [
                  ...currentListData.listData,
                  ...waitlistData.data.data.data.data,
                ],
          paginationInformation: pageDetails,
          twilio_config,
        };
      });
    }
  }, [dataUpdatedAt]);

  const breadCrumbs = [
    { name: "Appointment", link: Routes.APPOINTMENT.url },
    { name: `Waitlist: ${totalWaitlistAmount}` },
  ];

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "patient.firstname",
        id: "firstname",
        width: "120px",
      },
      {
        Header: "Last Name",
        accessor: "patient.lastname",
        id: "lastname",
        width: "120px",
      },
      {
        Header: "Clinic",
        accessor: (rowData) => {
          const { clinics, id } = rowData;

          if (clinics.length > 1) {
            const clinic = searchParams?.clinic_id
              ? clinics.find((c) => c.id === searchParams.clinic_id)
                  ?.clinic_name
              : clinics[0]?.clinic_name;

            return (
              <span
                onClick={() => {
                  setEditWaitlistItem({
                    open: true,
                    id,
                    editEnabled: false,
                    data: rowData,
                  });
                }}
              >
                {clinic}&nbsp;
                <span className="table-more-button">
                  +&nbsp;{clinics.length - 1}
                </span>
              </span>
            );
          } else {
            return clinics?.[0]?.clinic_name || "";
          }
        },
        id: "clinic",
        width: "120px",
        disableSortBy: true,
      },
      {
        Header: "Email",
        accessor: "patient.email",
        id: "email",
        width: "200px",
      },
      {
        Header: "Phone Number",
        accessor: "patient.phoneNumber",
        type: "phone",
        id: "phone",
        disableSortBy: true,
        width: "120px",
      },
      {
        Header: "Provider",
        accessor: (rowData) => {
          const { providers, id } = rowData;
          if (providers.length > 1) {
            return (
              <span
                onClick={() => {
                  setEditWaitlistItem({
                    open: true,
                    id,
                    editEnabled: false,
                    data: rowData,
                  });
                }}
              >
                {providers[0].full_name}{" "}
                <span className="table-more-button">
                  +&nbsp;{providers.length - 1}
                </span>
              </span>
            );
          } else {
            return providers?.[0]?.full_name || "";
          }
        },
        id: "provider",
        width: "120px",
        disableSortBy: true,
      },
      {
        Header: <>Date&nbsp;Added</>,
        accessor: "created_at",
        width: "90px",
        type: "date",
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        type: "actions",
        bookMessage: "Book Appointment",
        editMessage: "Edit Appointment",
        handleBook: (rowData) => {
          const hasMultipleClinicsProviders = Boolean(
            rowData.providers.length > 1 || rowData.clinics.length > 1,
          );

          const waitlistConvertDetails = {
            ...rowData,
            selectedClinics: rowData.clinics.map((selectedClinicDetails) => ({
              id: selectedClinicDetails.id,
              name: selectedClinicDetails.clinic_name,
            })),
            selectedProviders: rowData.providers.map(
              (selectedProviderDetails) => ({
                id: selectedProviderDetails.id,
                name: selectedProviderDetails.full_name,
              }),
            ),
          };

          setEditWaitlistItem({
            open: false,
            id: rowData.id,
            editEnabled: Boolean(hasMultipleClinicsProviders),
            data: waitlistConvertDetails,
          });

          if (hasMultipleClinicsProviders) {
            toggleConvertWarning();
          } else {
            updateConvertWaitlist(waitlistConvertDetails);
          }
        },
        handleDelte: (rowData) => {
          setDeleteWarningModal({
            open: true,
            id: rowData.id,
          });
        },
        handleChat: (rowData) => {
          if (waitlistTableData.twilio_config) {
            history.push(`/inbox/sms/create/${rowData.patient.id}`);
          } else {
            uiNotification.error("Turn on inbox in order to text patients");
          }
        },
        handleEdit: (rowData) => {
          setEditWaitlistItem({
            open: true,
            id: rowData.id,
            editEnabled: false,
            data: rowData,
          });
        },
      },
    ],
    [waitlistTableData],
  );

  const toggleSearchModal = useCallback(() => {
    setSearchParams(({ open, ...appliedSearchParams }) => ({
      open: !open,
      ...appliedSearchParams,
    }));
  }, [setSearchParams]);

  const toolbarButtons = (
    <div className="waitlist-toolbar-buttons">
      <button
        className="btn btn-primary d-flex align-center gap-8"
        onClick={downloadExcel.initiate}
        disabled={downloadExcel.isLoading}
      >
        {downloadExcel.isLoading ? (
          <CircularProgress size="tiny" color="white" />
        ) : (
          <div>
            <i className="fa fa-download"></i>
          </div>
        )}
        Download Excel
      </button>
      <button className="btn btn-primary" onClick={toggleSearchModal}>
        Run query
      </button>
      <button
        className="btn btn-light reset-button"
        onClick={() => {
          setSearchParams({ open: false });
        }}
      >
        Reset
      </button>
    </div>
  );

  const handleCloseEditWaitlist = useCallback(() => {
    clearSetupIntent();
    setEditWaitlistItem(false);
  }, [setEditWaitlistItem]);

  const toggleConvertWarning = useCallback(() => {
    setConverWarningModal(
      (openConvertWarningModal) => !openConvertWarningModal,
    );
  }, [setConverWarningModal]);

  const updateConvertWaitlist = useCallback(
    (selectedWaitlistDetails) => {
      const convertToAppoitnment = async () => {
        const { id, clinics, providers, services, patient, appointment_notes } =
          selectedWaitlistDetails;
        const formData = {
          clinic_id: clinics[0]?.id,
          provider_id: providers[0]?.id,
        };

        const servicesOffered = await GeServicesList({
          providerIds: [formData.provider_id],
          clinicIds: [formData.clinic_id],
        });

        if (servicesOffered) {
          const serviceDictonary = Object.assign(
            {},
            ...servicesOffered?.data?.data?.services?.map((serviceDetails) => ({
              [serviceDetails.id]: serviceDetails,
            })),
          );

          history.push(`/appointment/create/${formData.provider_id}`, {
            id,
            patient,
            appointment_notes,
            appointment_wait_list_clinic: formData.clinic_id,
            appointment_wait_list_services: services
              .filter(
                (waitlistService) => waitlistService.id in serviceDictonary,
              )
              .map((selectedService) => ({
                id: selectedService.id,
                name: selectedService.name,
                duration: serviceDictonary[selectedService.id].duration,
                isFree: Boolean(
                  serviceDictonary[selectedService.id].is_service_free,
                ),
                price: parseFloat(serviceDictonary[selectedService.id].price),
                gfeRequired: Boolean(
                  serviceDictonary[selectedService.id].is_gfe_required,
                ),
                serviceType: serviceDictonary[selectedService.id].service_type,
              })),
          });
        }
      };

      convertToAppoitnment();
    },
    [setEditWaitlistItem, editWaitlistItem],
  );

  const toggleDeleteWaitlist = useCallback(() => {
    setDeleteWarningModal((deleteModalStatus) => ({
      ...deleteModalStatus,
      open: !deleteModalStatus.open,
    }));
  }, [setDeleteWarningModal]);

  const deleteWaitlist = useCallback(() => {
    deleteWaitlistItem(openDeleteWarningModal.id, {
      onSettled: () => {
        setWaitlistData((currentListData) => {
          return {
            ...currentListData,
            listData: currentListData.listData.filter(
              (waitlistDetails) =>
                waitlistDetails.id !== openDeleteWarningModal.id,
            ),
          };
        });
        toggleDeleteWaitlist();
      },
    });
  }, [setDeleteWarningModal, openDeleteWarningModal]);

  return (
    <>
      {openConvertWarningModal && (
        <ConvertToAppointmentModal
          isOpen={openConvertWarningModal}
          onCancel={toggleConvertWarning}
          data={editWaitlistItem.data}
          history={history}
        />
      )}
      {openDeleteWarningModal.open && (
        <ConfirmationModal
          title="Delete Waitlist?"
          isOpen={openDeleteWarningModal.open}
          confirmFn={deleteWaitlist}
          cancelFn={toggleDeleteWaitlist}
          message={`Are you sure you want to delete this waitlist?`}
          confirmMessage="Delete"
          cancelMessage="Cancel"
        />
      )}
      {editWaitlistItem.open && (
        <AddEditWaitlistAppointment
          onClose={handleCloseEditWaitlist}
          id={editWaitlistItem.id}
          data={editWaitlistItem.data}
          editEnabled={editWaitlistItem.editEnabled}
          history={history}
        />
      )}
      {searchParams.open && (
        <WaitlistSearchModal
          onClose={toggleSearchModal}
          searchParams={searchParams}
          setSearchParams={(next) => {
            setPageFilters((prev) => ({
              ...prev,
              page: 1,
            }));
            setSearchParams(next);
          }}
        />
      )}
      <>
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <div>
              <Table
                isFetching={isFetching}
                columns={columns}
                data={listData}
                paginationInformation={paginationInformation}
                updateFilters={setPageFilters}
                toolbarButtons={toolbarButtons}
                breadCrumbs={breadCrumbs}
              />
            </div>
          </div>
        </div>
      </>
    </>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSetupIntent,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(List));
