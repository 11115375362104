import React from "react";
import styles from "./RemainingBalanceDue.module.scss";
import { tCommon } from "../../../../../i18n/useAppTranslation";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { formatCurrency } from "../../../../../utilities/general";

const RemainingBalanceDue = ({ amount }) => {
  const { amountToPay, currency } = useInvoice();
  return (
    <div className={styles.root}>
      {tCommon("label.remainingBalanceDue")}:{" "}
      <b>{formatCurrency(amountToPay - amount, currency)}</b>
    </div>
  );
};

export default RemainingBalanceDue;
