import React from "react";
import { Table as SharedTable } from "../../../../../../../shared/Table/Table";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { Skeleton } from "../../../../../../../shared/Skeleton/Skeleton";
import { Empty } from "../../../../../../../shared/Empty/Empty";
import { SortAdornment } from "../../../../../../../shared/SortAdornment/SortAdornment";
import { ContentLoader } from "../../../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { SHORT_TERM_LIABILITY_REPORT_ORDER_BY } from "../../../../../../../api/queries/useShortTermLiabilityReportQuery";
import styles from "./styles.module.scss";

const createKeyFromReport = (report, index) => {
  return `${index}-${Object.values(report).join("-")}`;
};

export function Table({ reports, order, handleRowClick }) {
  const { tBi } = useAppTranslation.BusinessInsights();
  const { tCommon } = useAppTranslation.Common();

  const isEmpty = reports.value.length === 0 && !reports.isLoading;
  const isVisible = !reports.isLoading && !isEmpty;

  const data = reports.value.map((r, i) => ({
    key: createKeyFromReport(r, i),
    date: r.date,
    patient: r.client || tCommon("symbol.longDash"),
    productName: r.product_name,
    units: r.units,
    dollarValue: r.dollar_value,
    balanceUnits: r.balance_units,
    balanceDollarValue: r.balance_dollar_value,
    invoiceId: r.invoice_id,
    packageId: r.package_id,
    patientId: r.patient_id,
    pppId: r.ppp_id,
  }));

  const renderSortWrap = (children, field) => (
    <SortAdornment
      value={order.value.by === field ? order.value.direction : undefined}
      onToggle={() => order.update(field)}
    >
      {children}
    </SortAdornment>
  );

  return (
    <div className={styles.root}>
      {reports.isLoading && !reports.isSoftLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={reports.isSoftLoading}>
          <SharedTable
            bgColor="white"
            data={data}
            onRowClick={(row) => handleRowClick(row)}
            cols={[
              {
                data: renderSortWrap(
                  tBi("shortTermLiabilityReport.table.date"),
                  SHORT_TERM_LIABILITY_REPORT_ORDER_BY.date,
                ),
                accessor: "date",
                headColClassName: styles.smallestCol,
              },
              {
                data: renderSortWrap(
                  tBi("shortTermLiabilityReport.table.patient"),
                  SHORT_TERM_LIABILITY_REPORT_ORDER_BY.patient,
                ),
                accessor: "patient",
                headColClassName: styles.largestCol,
              },
              {
                data: renderSortWrap(
                  tBi("shortTermLiabilityReport.table.product"),
                  SHORT_TERM_LIABILITY_REPORT_ORDER_BY.product,
                ),
                accessor: "productName",
                headColClassName: styles.largestCol,
              },
              {
                data: renderSortWrap(
                  tBi("shortTermLiabilityReport.table.units"),
                  SHORT_TERM_LIABILITY_REPORT_ORDER_BY.units,
                ),
                accessor: "units",
                headColClassName: styles.smallestCol,
              },
              {
                data: renderSortWrap(
                  tBi("shortTermLiabilityReport.table.dollarValue"),
                  SHORT_TERM_LIABILITY_REPORT_ORDER_BY.dollarValue,
                ),
                accessor: "dollarValue",
                headColClassName: styles.largestCol,
              },
              {
                data: renderSortWrap(
                  tBi("shortTermLiabilityReport.table.balanceUnits"),
                  SHORT_TERM_LIABILITY_REPORT_ORDER_BY.balanceUnits,
                ),
                accessor: "balanceUnits",
                headColClassName: styles.largestCol,
              },
              {
                data: renderSortWrap(
                  tBi("shortTermLiabilityReport.table.balanceDollarValue"),
                  SHORT_TERM_LIABILITY_REPORT_ORDER_BY.balanceDollarValue,
                ),
                accessor: "balanceDollarValue",
                headColClassName: styles.largestCol,
              },
            ]}
          />
          {reports.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={reports.fetchMore}
              isLoading={reports.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </div>
  );
}
