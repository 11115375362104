import React, { useState } from "react";
import { useUpdateGfeStatus } from "../hooks/useUpdateGfeStatus";
import styles from "../sass/HealthTimeline.module.scss";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../../consts/api";

const MarkGfeButton = ({ procedureId, isMarkedAsGfe, changeMarkedAsGfe }) => {
  const { tClients } = useAppTranslation.Clients();
  const { mutate, isLoading } = useUpdateGfeStatus(procedureId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const queryClient = useQueryClient();

  return (
    <>
      <button
        className={styles.markGfeButton}
        onClick={() => setIsModalOpen(true)}
      >
        {Boolean(isMarkedAsGfe)
          ? tClients("profile.gfe.unmarkButtonLabel")
          : tClients("profile.gfe.markButtonLabel")}
      </button>
      {isModalOpen && (
        <ConfirmModal
          isOpen
          onCancel={() => setIsModalOpen(false)}
          onClose={() => setIsModalOpen(false)}
          onConfirm={() =>
            mutate(
              { gfe_status: Number(!isMarkedAsGfe) },
              {
                onSuccess: () => {
                  changeMarkedAsGfe();
                  queryClient.invalidateQueries(QUERY_KEYS.patientProcedures);
                  setIsModalOpen(false);
                  uiNotification.success(
                    Boolean(isMarkedAsGfe)
                      ? tClients("profile.gfe.unmarkedSuccessfully")
                      : tClients("profile.gfe.markedSuccessfully"),
                  );
                },
                onError: () => {
                  uiNotification.error();
                },
              },
            )
          }
          isConfirming={isLoading}
        >
          {Boolean(isMarkedAsGfe)
            ? tClients("profile.gfe.confirmRemove")
            : tClients("profile.gfe.confirmAdd")}
        </ConfirmModal>
      )}
    </>
  );
};

export default MarkGfeButton;
