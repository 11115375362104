import moment from "moment/moment";
import { map, pipe } from "../../../utilities/fp";

export function prepareEvents(events = []) {
  var filterInvalidBreakTimes = (events = []) =>
    events.filter(
      (e) =>
        !(e.appointmentType === "break_time" && moment(e.start).isSame(e.end)),
    );

  var isNotAvailabilityTime = (x) => !x.availabilityGrid;
  var isAvailabilityTime = (x) => x.availabilityGrid;

  var groupByResource = (events = []) =>
    Object.values(
      events.reduce((acc, event) => {
        if (!acc[event.resourceId]) {
          return {
            ...acc,
            [event.resourceId]: [event],
          };
        } else {
          return {
            ...acc,
            [event.resourceId]: [...acc[event.resourceId], event],
          };
        }
      }, {}),
    );

  var groupIntersected = (events = []) => {
    var withoutAvailabilityTime = events.filter(isNotAvailabilityTime);
    var availabilityTimes = events.filter(isAvailabilityTime);

    withoutAvailabilityTime.sort((x, y) => x.start - y.start);

    var groupedEvents = [availabilityTimes];
    var group = [];
    var lastEvent = null;

    withoutAvailabilityTime.forEach((event) => {
      if (lastEvent && lastEvent.end > event.start) {
        group.push(event);
      } else {
        if (group.length) {
          groupedEvents.push(group);
        }
        group = [event];
      }

      lastEvent = event;
    });

    if (group.length) {
      groupedEvents.push(group);
    }

    return groupedEvents;
  };

  var correctZIndexesForGroups =
    (baseZIndex) =>
    (groupedEvents = []) => {
      return groupedEvents.map((group) => {
        const withoutAvailabilityTimes = group.filter(isNotAvailabilityTime);
        const availabilityTimes = group.filter(isAvailabilityTime);

        withoutAvailabilityTimes.sort((x, y) => y.duration - x.duration);

        return [
          ...withoutAvailabilityTimes.map((event, index) => ({
            ...event,
            zIndex: baseZIndex + index,
          })),
          ...availabilityTimes,
        ];
      });
    };

  return pipe(
    events,
    filterInvalidBreakTimes,
    groupByResource,
    map(groupIntersected),
    map(correctZIndexesForGroups(3)),
    (x) => x.flat().flat(),
  );
}
